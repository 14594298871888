import * as Sentry from '@sentry/react'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
import { Form, Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'

import { Button } from '@/components/Button'

import { FormError } from './FormError'
import { TextField } from './TextField'

export interface PasswordResetFormValues {
  email: string
}

interface PasswordResetFormProps {
  email?: string
  onSubmit: () => void
}

export const emailValidationSchema = yup
  .string()
  .email('Please enter a valid email address')
  .required('Please enter a valid email address')

const validationSchema = yup.object({
  email: emailValidationSchema,
})

export const PasswordResetForm = observer(function PasswordResetForm({
  email,
  onSubmit,
}: PasswordResetFormProps): React.ReactElement {
  const [formError, setFormError] = useState<string | undefined>()
  const history = useHistory()

  const handleClickBack = () => {
    history.push('/login')
  }

  const handleSubmit = async ({ email }: PasswordResetFormValues) => {
    try {
      const auth = getAuth()
      await sendPasswordResetEmail(auth, email)

      onSubmit()
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)

      setFormError('Sorry, something went wrong')
    }
  }

  return (
    <Formik
      initialValues={{
        email: email ?? '',
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, isValid }) => (
        <Form className='w-full flex flex-col gap-8'>
          <div className='flex flex-col gap-6'>
            <TextField label='Email' name='email' type='email' />
          </div>

          <Button
            disabled={isSubmitting || !isValid}
            text='Reset Password'
            type='submit'
          />

          {formError && <FormError error={formError} />}

          <Button
            className='mt-6'
            text='Back'
            variant='anchor'
            onClick={handleClickBack}
          />
        </Form>
      )}
    </Formik>
  )
})
