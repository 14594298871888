import { Container, Panel } from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { AppFooter } from '@/components/AppFooter'
import { AppHeader } from '@/components/AppHeader'
import { Button } from '@/components/Button'
import { Main } from '@/components/Main'
import { Page } from '@/components/Page'
import { PageHeading } from '@/components/PageHeading'
import { TermsOfService } from '@/components/TermsOfService'
import { PlaybackSettings } from '@/pages/settings/components/PlaybackSettings'
import { SpotifyAuthButton } from '@/store/integrations/spotify/components/SpotifyAuthButton'
import { useSpotify } from '@/store/integrations/spotify/useSpotify'

const PAGE_TITLE = 'Playback Device Setup'

export const SetupDevicesRoute = observer(
  function SetupDevicesRoute(): React.ReactElement {
    return <SetupDevices />
  }
)

export const SetupDevices = observer(
  function SetupDevices(): React.ReactElement {
    const { auth } = useSpotify()
    const { isAuthenticated } = auth

    const history = useHistory()

    const handleContinueClick = () => {
      history.push(`/game/setup/gameplay`)
    }

    if (!isAuthenticated) {
      return (
        <Page title={PAGE_TITLE}>
          <AppHeader header={<PageHeading>{PAGE_TITLE}</PageHeading>} />
          <Main>
            <Container size='small'>
              <Panel className='flex flex-col gap-8'>
                <p className='text-center'>
                  Connect a Spotify Premium account to use playback.
                </p>

                <SpotifyAuthButton />

                <Button
                  text='Cancel'
                  variant='anchor'
                  onClick={() => history.goBack()}
                />
              </Panel>
            </Container>

            <TermsOfService />
          </Main>
        </Page>
      )
    }

    return (
      <Page title={PAGE_TITLE}>
        <AppHeader header={<PageHeading>{PAGE_TITLE}</PageHeading>} />

        <Main>
          <Container size='medium'>
            <PlaybackSettings
              buttonText='Continue'
              onSubmit={handleContinueClick}
            />
          </Container>

          <TermsOfService />
        </Main>

        <AppFooter />
      </Page>
    )
  }
)
