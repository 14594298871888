import { Badge, Label } from '@matthewlongpre/music-bingo-common'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import React from 'react'

import { useGameContext } from '../pages/game/context/game-context'

interface DisplayGameCodeProps {
  size?: 'small' | 'large'
}

export const DisplayGameCode = observer(function DisplayGameCode({
  size = 'large',
}: DisplayGameCodeProps): React.ReactElement {
  const { gameData } = useGameContext()

  return (
    <div
      className={classNames('flex flex-col items-center justify-center', {
        'gap-1': size === 'small',
        'gap-2': size === 'large',
      })}
    >
      <Label
        className={classNames('text-center', { 'text-xs': size === 'small' })}
      >
        Game Code
      </Label>
      <Badge className={classNames('px-2 font-mono pt-0.5 pb-0')} size={size}>
        {gameData.gameCode}
      </Badge>
    </div>
  )
})
