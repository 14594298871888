import { Badge, Playlist } from '@matthewlongpre/music-bingo-common'
import { ScrollContainer } from '@matthewlongpre/music-bingo-common/client'
import { observer } from 'mobx-react-lite'

import { List } from '@/components/List'
import { SongMetadata } from '@/pages/playlists/playlist-editor/SongMetadata'
import { shuffle } from '@/utils/shuffle'

interface PlaylistPreviewProps {
  playlist: Playlist
}

export const PlaylistPreview = observer(function PlaylistPreview({
  playlist,
}: PlaylistPreviewProps) {
  const displayedPlaylist = playlist.disableShuffle
    ? playlist.songs
    : shuffle([...playlist.songs])

  return (
    <div className='flex flex-col overflow-hidden'>
      <h4 className='flex items-center mt-0 mb-4'>
        <span className='text-muted mr-2'>Possible Songs:</span>{' '}
        <Badge size='small'>{playlist.songs.length}</Badge>
      </h4>

      <ScrollContainer className='rounded-lg bg-shade-1 border border-shade-3'>
        <List className='border-0' tabIndex={0}>
          {displayedPlaylist.map((song) => (
            <List.Item key={song.id} disableBorder>
              <SongMetadata song={song} />
            </List.Item>
          ))}
        </List>
      </ScrollContainer>
    </div>
  )
})
