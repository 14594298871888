import {
  Container,
  Description,
  Heading,
  Logo,
  Panel,
  Spinner,
} from '@matthewlongpre/music-bingo-common'
import { Dialog } from '@matthewlongpre/music-bingo-common/client'
import * as Sentry from '@sentry/react'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { Redirect, Route, useHistory, useRouteMatch } from 'react-router-dom'

import { AppHeader } from '@/components/AppHeader'
import { AppHeaderLogo } from '@/components/AppHeaderLogo'
import { BetaBadge } from '@/components/BetaBadge'
import { Button } from '@/components/Button'
import { SettingsButton } from '@/components/icon-buttons/IconButtons'
import { Image } from '@/components/Image'
import { Main } from '@/components/Main'
import { NavigationLink } from '@/components/NavigationLink'
import { Page } from '@/components/Page'
import { PageTitle } from '@/components/PageTitle'
import { PlanRestrictions } from '@/components/PlanRestrictions'
import { PlanTypeBadge } from '@/components/PlanTypeBadge'
import { SubscriptionActionRequired } from '@/components/SubscriptionActionRequired'
import { TermsOfService } from '@/components/TermsOfService'
import { useQuerySpotifyPlaylists } from '@/store/integrations/spotify/queries/useQuerySpotifyPlaylists'
import StoreService from '@/store/StoreService'
import { useSearchParams } from '@/utils/use-search-params'

import { CreateGameError } from './components/CreateGameError'
import { MixpanelService } from '../../mixpanel'
import { useQuerySystemPlaylists } from '../playlists/playlist-queries/system-playlists/useQuerySystemPlaylists'
import { useQueryUserPlaylists } from '../playlists/playlist-queries/user-playlists/useQueryUserPlaylists'
import { HomeScreenSettingsRoute } from '../settings/pages/global-settings'

export const HomeRoute = observer(function HomeRoute(): React.ReactElement {
  const { path } = useRouteMatch()
  const { currentGame, isLoadingGame } = StoreService.getStore()

  if (isLoadingGame) {
    return <Spinner label='Loading...' />
  }

  if (currentGame) {
    return <Redirect to='/game/setup/gameplay' />
  } else {
    return (
      <>
        <Route path='/settings'>
          <HomeScreenSettingsRoute />
        </Route>

        <Route path={path} exact>
          <Home />
        </Route>
      </>
    )
  }
})

const Home = observer(() => {
  const store = StoreService.getStore()
  const { user } = store

  const query = useSearchParams()
  const [showWelcomeModal, setShowWelcomeModal] = useState(
    !!query.get('welcome')
  )

  // Prefetch playlists
  useQuerySystemPlaylists()
  useQueryUserPlaylists()
  useQuerySpotifyPlaylists()

  const history = useHistory()

  const [isCreatingGame, setIsCreatingGame] = useState(false)
  const [showCreateGameError, setShowCreateGameError] = useState(false)

  const handleClickCreateGame = async () => {
    setIsCreatingGame(true)

    try {
      const { gameId } = await store.createGame({ type: 'numbers' })
      MixpanelService.createGame(gameId, { type: 'numbers' })
    } catch (error) {
      setShowCreateGameError(true)
      setIsCreatingGame(false)
      Sentry.captureException(error)
    }
  }

  if (showCreateGameError) {
    return <CreateGameError />
  }

  return (
    <Page title='Choose a Game Type'>
      <AppHeader
        actionsRight={<SettingsButton url='/settings' />}
        header={<AppHeaderLogo />}
      />

      <Main>
        {user.isSubscriptionActionRequired && (
          <Container className='flex flex-col gap-12 mb-12' size='medium'>
            <SubscriptionActionRequired />
          </Container>
        )}

        <Container className='mb-8 flex flex-col gap-2 sm:gap-4' size='medium'>
          <PageTitle className='text-center mb-0' size='extra-large'>
            Welcome!
          </PageTitle>
          <Description className='sm:text-lg text-center mx-auto'>
            Choose a game type to continue
          </Description>
        </Container>

        <Container className='flex flex-col xl:flex-row gap-8' size='large'>
          <MusicBingoCard />
          <NumbersBingoCard
            isCreatingGame={isCreatingGame}
            onClick={handleClickCreateGame}
          />
        </Container>

        <TermsOfService />
      </Main>

      <Dialog isVisible={showWelcomeModal}>
        <Dialog.Body>
          <>
            <div className='flex justify-center text-center mt-4 mb-8'>
              <Logo size='small' />
            </div>

            <div className='justify-center text-center mb-6'>
              <Heading className='text-center' size='large'>
                Welcome to Rockstar Bingo!
              </Heading>

              <p className='max-w-[33ch] m-auto mt-4 text-muted'>
                You{"'"}re all set to choose a playlist, start a game and invite
                some guests.
              </p>

              <div className='mt-8 text-center flex flex-col'>
                <div className='flex gap-2 mx-auto'>
                  Your plan: <PlanTypeBadge />
                </div>
                <p className='mt-2'>
                  <PlanRestrictions />
                </p>
              </div>
            </div>
          </>
        </Dialog.Body>
        <Dialog.Footer>
          <Button
            text='Get Started'
            onClick={() => {
              history.push('/')
              setShowWelcomeModal(false)
            }}
          />
        </Dialog.Footer>
      </Dialog>
    </Page>
  )
})

function MusicBingoCard() {
  const path = '/music'

  return (
    <Panel className='flex flex-col gap-8 w-full'>
      <NavigationLink to={path}>
        <div className='flex items-center justify-center overflow-hidden bg-shade-2 dark:bg-shade-0 -m-4 md:-m-6 lg:-m-8 -mb-4 md:-mb-4 lg:mb-0 rounded-t-lg min-h-48 max-h-80 xl:aspect-video'>
          <Image alt='Music Bingo' src='bingo-music.webp' />
        </div>
      </NavigationLink>
      <div>
        <div>
          <Heading size='large'>Music Bingo</Heading>
        </div>
        <Description className='sm:text-base mt-2 max-w-[56ch]'>
          Create music bingo games using your favorite playlists. Connect to
          music streaming services and have a blast.
        </Description>
      </div>

      <Button text='Host Music Bingo' to={path} />
    </Panel>
  )
}

interface NumbersBingoCardProps {
  isCreatingGame: boolean
  onClick: () => void
}

function NumbersBingoCard({ onClick, isCreatingGame }: NumbersBingoCardProps) {
  return (
    <Panel className='flex flex-col gap-8 w-full relative'>
      <div
        className='flex hover:cursor-pointer items-center justify-center overflow-hidden bg-shade-2 dark:bg-shade-0 -m-4 md:-m-6 lg:-m-8 -mb-4 md:-mb-4 lg:mb-0 rounded-t-lg min-h-48 max-h-80 xl:aspect-video'
        onClick={onClick}
      >
        <Image alt='Numbers Bingo' src='bingo-numbers.webp' />
      </div>
      <div>
        <div className='flex gap-4'>
          <Heading size='large'>Numbers Bingo</Heading>
          <BetaBadge />
        </div>
        <Description className='sm:text-base mt-2 max-w-[56ch]'>
          The time-honored original! Host games of classic bingo where guests
          use traditional number-based cards.
        </Description>
      </div>

      <Button
        isLoading={isCreatingGame}
        text='Host Numbers Bingo'
        onClick={onClick}
      />
    </Panel>
  )
}
