import { Playlist } from '@matthewlongpre/music-bingo-common'

import { apiClient } from '@/api/api-client'
import StoreService from '@/store/StoreService'

import { useUpdatePlaylist } from '../useUpdatePlaylist'

export function useUpdateUserPlaylist({ playlistId }: { playlistId: string }) {
  const { playlistService } = StoreService.getStore()

  const { queryKey: itemQueryKey, queryFn: itemQueryFn } =
    playlistService.queries.userPlaylist

  const { queryKey: listQueryKey, queryFn: listQueryFn } =
    playlistService.queries.userPlaylists

  const mutationFn = (playlist: Playlist) =>
    apiClient.userUpdatePlaylist(playlist)

  return useUpdatePlaylist(
    mutationFn,
    itemQueryKey(playlistId),
    () => itemQueryFn(playlistId),
    listQueryKey,
    listQueryFn
  )
}
