import {
  Device,
  ISpotifyFeatures,
  Playlist,
  Song,
} from '@matthewlongpre/music-bingo-common'
import * as Sentry from '@sentry/react'

import { SpotifyAuthClient } from '@/store/integrations/spotify/auth/auth-client/SpotifyAuthClient'
import {
  ISpotifyDevice,
  ISpotifyDeviceResponse,
  ISpotifyPlaybackState,
  ISpotifyPlaylist,
  ISpotifyPlaylistItem,
  ISpotifyPlaylistResponse,
  ISpotifyPlaylistTracksResponse,
  ISpotifyProfile,
  ISpotifySearchTracksResponse,
  ISpotifyTrack,
} from '@/store/integrations/spotify/types'
import { MINIMUM_ITEM_COUNT } from '@/utils/constants'

import convertDevice from './converters/convertDevice'
import {
  PlaybackState,
  convertPlaybackState,
} from './converters/convertPlayback'
import convertPlaylist from './converters/convertPlaylist'
import { convertProfile } from './converters/convertProfile'
import { convertSongs } from './converters/convertSongs'
import { IntegrationClient, IntegrationProfile } from '..'

export const MINIMUM_SONG_ERROR = `Playlist must have at least ${MINIMUM_ITEM_COUNT} songs.`

enum FetchMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
}

export class SpotifyClient implements IntegrationClient {
  private authClient: SpotifyAuthClient

  public constructor(authClient: SpotifyAuthClient) {
    this.authClient = authClient
  }

  private async getPlaylistWithTracks(
    playlistId: string
  ): Promise<ISpotifyPlaylist> {
    const allPlaylistTracks: ISpotifyPlaylistItem[][] = []

    const getPlaylist = async (
      playlistId: string
    ): Promise<ISpotifyPlaylist> => {
      const playlistResponse =
        await this.authClient.apiRequest<ISpotifyPlaylist>(
          `/playlists/${playlistId}`
        )

      const getPlaylistTracks = async (
        playlistTracksUrl: string
      ): Promise<void> => {
        const playlistTracksResponse =
          await this.authClient.apiRequest<ISpotifyPlaylistTracksResponse>(
            `${playlistTracksUrl}`
          )

        allPlaylistTracks.push(playlistTracksResponse.items)

        if (playlistTracksResponse.next) {
          const endPointParamsOnly = playlistTracksResponse.next.split('?')
          return getPlaylistTracks(
            `/playlists/${playlistId}/tracks?${endPointParamsOnly[1]}`
          )
        }
      }

      allPlaylistTracks.push(playlistResponse.tracks.items)

      if (playlistResponse.tracks.next) {
        const endPointParamsOnly = playlistResponse.tracks.next.split('?')
        await getPlaylistTracks(
          `/playlists/${playlistId}/tracks?${endPointParamsOnly[1]}`
        )
      }

      const flattenedTracks = allPlaylistTracks.flat()
      const validTracks = flattenedTracks.filter((item) => item.track.id)

      if (validTracks.length < MINIMUM_ITEM_COUNT) {
        throw new Error(MINIMUM_SONG_ERROR)
      }

      playlistResponse.tracks.items = [...validTracks]

      return playlistResponse
    }

    return getPlaylist(playlistId)
  }

  private async getPlaylistWithTracksAndFeatures(
    playlistId: string
  ): Promise<ISpotifyPlaylist> {
    const playlist = await this.getPlaylistWithTracks(playlistId)

    const trackIds = playlist.tracks.items.map((item) => item.track.id)
    const trackFeatures = (await this.getAllTrackFeatures(trackIds)).filter(
      Boolean
    )

    const playlistTracksWithFeatures = playlist.tracks.items.map((item) => {
      const features = trackFeatures.find(
        (featureItem) => featureItem.id === item.track.id
      )
      return {
        ...item,
        track: {
          ...item.track,
          features,
        },
      }
    })

    playlist.tracks.items = playlistTracksWithFeatures

    return playlist
  }

  public async getTrack(trackId: string): Promise<Song> {
    const track = await this.authClient.apiRequest<ISpotifyTrack>(
      `/tracks/${trackId}`
    )

    return convertSongs([track])[0]
  }

  public getTrackFeatures = async (
    songId: string
  ): Promise<ISpotifyFeatures> => {
    const features = await this.authClient.apiRequest<ISpotifyFeatures>(
      `/audio-features/${songId}`
    )

    return features
  }

  private getTrackFeaturesMultiple = async (
    songIds: string[]
  ): Promise<ISpotifyFeatures[]> => {
    const response = await this.authClient.apiRequest<{
      audio_features: ISpotifyFeatures[]
    }>(`/audio-features?ids=${songIds.join(',')}`)

    return response.audio_features
  }

  private async getAllTrackFeatures(
    trackIds: string[]
  ): Promise<ISpotifyFeatures[]> {
    function spliceIntoChunks(array: string[], chunkSize: number) {
      const result = []

      while (array.length > 0) {
        const chunk = array.splice(0, chunkSize)
        result.push(chunk)
      }

      return result
    }

    const trackIdsChunked = spliceIntoChunks(trackIds, 100)

    const trackFeatures: ISpotifyFeatures[] = []

    for (const trackIdsChunk of trackIdsChunked) {
      const trackFeaturesChunk = await this.getTrackFeaturesMultiple(
        trackIdsChunk
      )
      trackFeaturesChunk.forEach((track) => trackFeatures.push(track))
    }

    return trackFeatures
  }

  public transferPlayback(deviceId: string, play = false): Promise<void> {
    const body = JSON.stringify({
      device_ids: [deviceId],
      play,
    })

    return this.authClient
      .apiRequest<void>(`/me/player`, FetchMethod.PUT, body)
      .catch((error) => {
        Sentry.captureException(error)
        throw error
      })
  }

  public async getDevices(): Promise<Device[]> {
    const data = await this.authClient.apiRequest<ISpotifyDeviceResponse>(
      '/me/player/devices'
    )

    if (data?.devices?.length !== 0) {
      const spotifyDevices: ISpotifyDevice[] = data.devices
      return spotifyDevices.map(convertDevice)
    } else {
      return []
    }
  }

  public async getPlaylists(): Promise<Playlist[]> {
    const map = new Map<string, ISpotifyPlaylist>()

    const getPlaylist = async (url: string): Promise<void> => {
      const playlistResponse =
        await this.authClient.apiRequest<ISpotifyPlaylistResponse>(url)

      playlistResponse.items.forEach((playlist) => {
        if (playlist.tracks.total >= MINIMUM_ITEM_COUNT) {
          map.set(playlist.id, playlist)
        }
      })

      if (playlistResponse.next) {
        const endPointParamsOnly = playlistResponse.next.split('?')
        return getPlaylist(`/me/playlists?${endPointParamsOnly[1]}`)
      }
    }

    await getPlaylist(`/me/playlists`)

    return Array.from(map.values()).map(convertPlaylist)
  }

  public async getPlaylistById(playlistId: string): Promise<Playlist> {
    const spotifyPlaylist = await this.getPlaylistWithTracksAndFeatures(
      playlistId
    )
    return convertPlaylist(spotifyPlaylist)
  }

  public async getPlaylistByUrl(url: string): Promise<Playlist> {
    const regExp =
      /^(https:\/\/open.spotify.com\/playlist\/|spotify:user:spotify:playlist:)([a-zA-Z0-9]+)(.*)$/

    const results = regExp.exec(url)

    if (!results) {
      throw new Error('Could not parse the URL')
    }

    const playlistId = results[2]
    const spotifyPlaylist = await this.getPlaylistWithTracksAndFeatures(
      playlistId
    )

    return convertPlaylist(spotifyPlaylist)
  }

  public async getPlaybackState(): Promise<PlaybackState> {
    const playbackState =
      await this.authClient.apiRequest<ISpotifyPlaybackState>(`/me/player`)

    return convertPlaybackState(playbackState)
  }

  public async startPlayback(
    deviceId: string,
    selectedTrack: Song
  ): Promise<void> {
    const body = JSON.stringify({
      uris: [selectedTrack?.spotifyMeta?.track.uri],
      position_ms: selectedTrack?.spotifyMeta?.startPosition,
    })

    return this.authClient
      .apiRequest<void>(
        `/me/player/play?device_id=${deviceId}`,
        FetchMethod.PUT,
        body
      )
      .catch((error) => {
        console.error(error)
        Sentry.captureException(error)

        throw error
      })
  }

  public async resumePlayback(): Promise<void> {
    return this.authClient
      .apiRequest<void>(`/me/player/play`, FetchMethod.PUT)
      .catch((error) => {
        console.error(error)
        Sentry.captureException(error)
      })
  }

  public async pausePlayback(): Promise<void> {
    return this.authClient
      .apiRequest<void>(`/me/player/pause`, FetchMethod.PUT)
      .catch((error) => {
        console.error(error)
        Sentry.captureException(error)
      })
  }

  public async searchTracks(query: string, limit = 5): Promise<Song[]> {
    const spotifyTracks =
      await this.authClient.apiRequest<ISpotifySearchTracksResponse>(
        `/search?q=${query}&type=track&limit=${limit}`
      )

    return convertSongs(spotifyTracks.tracks.items)
  }

  public async getProfile(): Promise<IntegrationProfile> {
    const response = await this.authClient.apiRequest<ISpotifyProfile>('/me')
    return convertProfile(response)
  }
}
