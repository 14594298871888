import {
  Badge,
  IconButton,
  Song,
  TrashIcon,
  YoutubeIcon,
} from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React from 'react'

import {
  AudioInfoBadge,
  KeyBadge,
  SpotifyTrackLink,
  TempoBadge,
} from '@/components/RowAudioFeatures'

import { NotesBadge } from './NotesBadge'
import { SongMetadata } from './SongMetadata'

interface IPlaylistTrackProps {
  canReorder?: boolean
  index?: number
  isCompact?: boolean
  onClick?: (id: number) => void
  onClickDelete: (id: number) => void
  song: Song
}

export const PlaylistTrack = observer(function PlaylistTrack({
  canReorder = false,
  index,
  onClick,
  onClickDelete,
  song,
}: IPlaylistTrackProps): React.ReactElement {
  const { id } = song
  const displayBadge = canReorder && index !== undefined

  return (
    <div
      className='flex gap-3 w-full overflow-hidden'
      onClick={() => onClick?.(id)}
    >
      {displayBadge && (
        <div className='flex items-center'>
          <Badge className='w-8 pb-1' size='small'>
            {index + 1}
          </Badge>
        </div>
      )}

      <SongMetadata song={song} />

      <div className='hidden sm:flex gap-4'>
        <div className='flex items-center justify-end gap-2 xs:hidden md:flex'>
          {song.userMeta?.notes && <NotesBadge notes={song.userMeta.notes} />}

          {song.video && (
            <Badge className='w-8 p-0' colour='red' size='small'>
              <YoutubeIcon className='w-4 h-4' />
            </Badge>
          )}

          <TempoBadge song={song} />

          <KeyBadge song={song} />

          <AudioInfoBadge song={song} />

          {song.spotifyMeta?.track.id && (
            <SpotifyTrackLink trackId={song.spotifyMeta.track.id} />
          )}
        </div>

        <div className='flex items-center'>
          <IconButton
            className='md:opacity-0 group-hover:opacity-100 transition-opacity'
            size='small'
            title='Remove Track'
            onClick={() => onClickDelete(id)}
          >
            <TrashIcon />
          </IconButton>
        </div>
      </div>
    </div>
  )
})
