import {
  Collections,
  Game,
  Spinner,
  Container,
} from '@matthewlongpre/music-bingo-common'
import { doc, getDoc } from 'firebase/firestore'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { databaseRef } from '@/firebase/firebase'

import { ActivePlayers } from '../components/ActivePlayers'
import { AppHeader } from '../components/AppHeader'
import { GameLobby } from '../components/GameLobby'
import { BackButton } from '../components/icon-buttons/IconButtons'
import { Main } from '../components/Main'
import { Page } from '../components/Page'
import { PageHeading } from '../components/PageHeading'
import { PlayersLeftGame } from '../components/PlayersLeftGame'
import {
  GameContextProvider,
  useGameContext,
} from '../pages/game/context/game-context'
import StoreService from '../store/StoreService'

export const AdminBrowseGameRoute = observer(
  function AdminBrowseGameRoute(): React.ReactElement {
    const [isLoading, setIsLoading] = useState(true)
    const store = StoreService.getStore()
    const { gameId } = useParams<{ gameId: string }>()

    useEffect(() => {
      const fetch = async () => {
        const docRef = doc(databaseRef, Collections.GAMES, gameId)
        const gameData = (await getDoc(docRef)).data() as Game

        store.setCurrentGame(gameData)
        setIsLoading(false)
      }

      fetch().catch(console.error)
    }, [gameId, store])

    if (isLoading) return <Spinner />

    return (
      <GameContextProvider>
        <Players />
      </GameContextProvider>
    )
  }
)

const Players = observer(function Players(): React.ReactElement {
  const { gameData } = useGameContext()

  if (!gameData) return <Spinner />

  return (
    <Page title='Game Details'>
      <AppHeader
        actionsLeft={<BackButton url='/admin/games' />}
        header={<PageHeading>Game Details</PageHeading>}
      />

      <Main>
        <div className='flex flex-col gap-8'>
          <Container size='large'>
            <GameLobby />
          </Container>
          <Container size='large'>
            <ActivePlayers />
          </Container>
          <Container size='large'>
            <PlayersLeftGame />
          </Container>
        </div>
      </Main>
    </Page>
  )
})
