import { ColourPalette } from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React from 'react'

import { PlayerList } from '@/components/PlayerList'

import { useGameContext } from '../../game/context/game-context'

export const GameLobbyHistory = observer(
  function GameLobbyHistory(): React.ReactElement {
    const { playersInLobby } = useGameContext()

    return (
      <>
        <PlayerList
          badgeColor={ColourPalette.ORANGE}
          description='Guests who were waiting for you to let them in to the game.'
          noItemsText='No guests were left in the lobby.'
          players={playersInLobby}
          title='Lobby'
        />
      </>
    )
  }
)
