import { merge } from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React from 'react'

interface YoutubeEmbedProps {
  url: string
}

export const YoutubeEmbed = observer(function YoutubeEmbed({
  url,
}: YoutubeEmbedProps): React.ReactElement | null {
  const getVideoId = (url: string): string => {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
    const match = regExp.exec(url)

    if (match && match[2]) {
      return match[2]
    } else {
      return ''
    }
  }

  const videoId = getVideoId(url)

  if (!videoId) return null

  return (
    <>
      <iframe
        src={`https://www.youtube.com/embed/${videoId}?&autoplay=1&mute=1&loop=1&playlist=${videoId}`}
      />
      <Overlay className='top-0' />
      <Overlay className='bottom-0' />
    </>
  )
})

function Overlay({ className }: { className?: string }) {
  return (
    <div
      className={merge('fixed inset-auto w-full h-16 bg-[#000]', className)}
    />
  )
}
