import { useQuery } from 'react-query'

import StoreService from '@/store/StoreService'

export function useQueryUserPlaylists() {
  const { playlistService } = StoreService.getStore()

  const { queries } = playlistService

  const { queryKey, queryFn } = queries.userPlaylists

  return useQuery(queryKey, queryFn, {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24, // 24 hours
    cacheTime: 1000 * 60 * 60 * 24 * 7, // 7 days
  })
}
