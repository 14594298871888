import { Container } from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { AppHeader } from '@/components/AppHeader'
import { BackButton, CloseButton } from '@/components/icon-buttons/IconButtons'
import { Main } from '@/components/Main'
import { Page } from '@/components/Page'
import { PageHeading } from '@/components/PageHeading'
import { useDialogContext } from '@/dialogs/DialogContext'
import { PlaybackSettings } from '@/pages/settings/components/PlaybackSettings'

export const PlaybackSettingsRoute = observer(
  function PlaybackSettingsRoute(): React.ReactElement {
    return <PlaybackSettingsPage />
  }
)

const PlaybackSettingsPage = observer(
  function PlaybackSettingsPage(): React.ReactElement {
    const history = useHistory()
    const { setErrorDialog } = useDialogContext()

    const close = () => history.push('/game')

    const handleClickBack = () => {
      history.goBack()
    }

    const handleClickClose = () => {
      close()
    }

    const handleSubmit = () => {
      close()
    }

    return (
      <Page title='Playback Settings'>
        <AppHeader
          actionsLeft={<BackButton onClick={handleClickBack} />}
          actionsRight={<CloseButton onClick={handleClickClose} />}
          header={<PageHeading>Playback Settings</PageHeading>}
        />

        <Main>
          <Container size='medium'>
            <PlaybackSettings
              onError={(error) =>
                setErrorDialog({
                  error: error.message,
                })
              }
              onSubmit={handleSubmit}
            />
          </Container>
        </Main>
      </Page>
    )
  }
)
