import { Badge } from '@matthewlongpre/music-bingo-common'

export function WarningBadge() {
  return (
    <Badge
      className='w-auto text-muted text-2xs sm:text-2xs mr-auto'
      size='small'
    >
      Warning
    </Badge>
  )
}
