import { Panel, Container } from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React from 'react'

import { LogoHeader } from '@/components/LogoHeader'
import { Main } from '@/components/Main'
import { Page } from '@/components/Page'
import { TermsOfService } from '@/components/TermsOfService'
import { SpotifyAuthButton } from '@/store/integrations/spotify/components/SpotifyAuthButton'

export const SpotifyConnectTextPanel = observer(
  function SpotifyConnectTextPanel(): React.ReactElement {
    return (
      <Page title='Connect to Spotify'>
        <Main>
          <Container className='text-center ' size='small'>
            <LogoHeader />

            <Panel>
              <p className='mb-4'>
                Connect a Spotify Premium account to import playlists.
              </p>
              <SpotifyAuthButton />
            </Panel>
          </Container>

          <TermsOfService />
        </Main>
      </Page>
    )
  }
)
