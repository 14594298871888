import { Playlist } from '@matthewlongpre/music-bingo-common'

import { apiClient } from '@/api/api-client'
import StoreService from '@/store/StoreService'

import { useUpdatePlaylist } from '../useUpdatePlaylist'

export function useUpdateSystemPlaylist({
  playlistId,
}: {
  playlistId: string
}) {
  const { playlistService } = StoreService.getStore()

  const { queryKey: itemQueryKey, queryFn: itemQueryFn } =
    playlistService.queries.systemPlaylist

  const { queryKey: listQueryKey, queryFn: listQueryFn } =
    playlistService.queries.systemPlaylists

  const mutationFn = (playlist: Playlist) =>
    apiClient.adminUpdateSystemPlaylist(playlist)

  return useUpdatePlaylist(
    mutationFn,
    itemQueryKey(playlistId),
    () => itemQueryFn(playlistId),
    listQueryKey,
    listQueryFn
  )
}
