import {
  Container,
  Heading,
  Panel,
  Playlist,
} from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'

import { AppHeader } from '@/components/AppHeader'
import { BackButton, CloseButton } from '@/components/icon-buttons/IconButtons'
import { Main } from '@/components/Main'
import { Page } from '@/components/Page'
import { PageHeading } from '@/components/PageHeading'
import { useUpdateUserPlaylist } from '@/pages/playlists/playlist-queries/user-playlists/useUpdateUserPlaylist'
import { updateGameSetting } from '@/store/game/updateGameDocument'
import StoreService from '@/store/StoreService'

import {
  JumbotronDefaultVideoFormValues,
  JumbotronDefaultVideoForm,
} from './JumbotronDefaultVideoForm'

const getPlaylistId = () => {
  const { currentGame } = StoreService.getStore()

  if (!currentGame || currentGame.gameData.type !== 'music') {
    throw new Error('Invalid game type')
  }

  return currentGame.gameData.sourcePlaylistMeta.id
}

export const JumbotronDefaultVideoRoute = observer(() => {
  const { currentGame } = StoreService.getStore()

  const playlistId = getPlaylistId()

  const { mutate: updateUserPlaylist } = useUpdateUserPlaylist({ playlistId })

  if (!currentGame || currentGame.gameData.type !== 'music') return null

  const currentValues = {
    video: currentGame.gameData.sourcePlaylistMeta.video?.url,
    videoPreGame: currentGame.gameData.sourcePlaylistMeta.videoPreGame?.url,
  }

  const history = useHistory()

  const handleClickBack = () => {
    history.goBack()
  }

  const handleClickClose = () => {
    history.push('/game')
  }

  const handleSubmit = async (values: JumbotronDefaultVideoFormValues) => {
    if (!currentGame || currentGame.gameData.type !== 'music') return

    const currentValues = {
      video: currentGame.gameData.sourcePlaylistMeta.video?.url,
      videoPreGame: currentGame.gameData.sourcePlaylistMeta.videoPreGame?.url,
    }

    const updates = {
      ...currentValues,
      ...values,
    }

    const update = {
      ['sourcePlaylistMeta.video.url']: updates.video,
      ['sourcePlaylistMeta.videoPreGame.url']: updates.videoPreGame,
    }

    await updateGameSetting(currentGame.gameId, update)

    const updatedPlaylist: Playlist = {
      ...currentGame.gameData.sourcePlaylistMeta,
      video: {
        url: updates.video,
      },
      videoPreGame: {
        url: updates.videoPreGame,
      },
    }

    updateUserPlaylist(updatedPlaylist)
  }

  const handleSuccess = () => {
    history.push('/game')
  }

  return (
    <Page title='Jumbotron Videos'>
      <AppHeader
        actionsLeft={<BackButton onClick={handleClickBack} />}
        actionsRight={<CloseButton onClick={handleClickClose} />}
        header={<PageHeading>Jumbotron Settings</PageHeading>}
      />

      <Main>
        <Container size='medium'>
          <Panel className='flex flex-col gap-6'>
            <Heading>Videos</Heading>

            <JumbotronDefaultVideoForm
              onSubmit={handleSubmit}
              onSuccess={handleSuccess}
              {...currentValues}
            />
          </Panel>
        </Container>
      </Main>
    </Page>
  )
})
