import {
  Panel,
  Heading,
  Container,
  Description,
} from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'

import { Button } from '@/components/Button'
import { LogoHeader } from '@/components/LogoHeader'
import { Main } from '@/components/Main'
import { Page } from '@/components/Page'

export const CreateGameError = observer(() => (
  <Page title='Error Creating Game'>
    <Main>
      <Container size='small'>
        <LogoHeader />

        <Panel className='flex flex-col gap-6'>
          <div>
            <Heading>Sorry, something went wrong</Heading>
            <Description className='mt-2'>
              There was a problem connecting to the server.
            </Description>
          </div>

          <Button text='Reload' onClick={() => window.location.reload()} />
        </Panel>
      </Container>
    </Main>
  </Page>
))
