import { ButtonGroup } from '@matthewlongpre/music-bingo-common'
import { action, when } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'

import { Button } from '@/components/Button'
import { useDialogContext } from '@/dialogs/DialogContext'
import {
  getNextIndex,
  getPreviousIndex,
  updateGameCurrentDrawnItem,
} from '@/store/game/updateGameDocument'
import StoreService from '@/store/StoreService'
import { FINAL_ITEM_INDEX } from '@/utils/constants'

export const SongControlsNoPlayback = observer(
  function SongControlsNoPlayback() {
    const { currentGame } = StoreService.getStore()

    const [isLoadingNext, setIsLoadingNext] = useState(false)
    const [isLoadingPrevious, setIsLoadingPrevious] = useState(false)

    const { setConfirmPrompt } = useDialogContext()

    if (!currentGame) return

    const handleClickNext = action(async () => {
      try {
        setIsLoadingNext(true)

        const nextItemIndex = getNextIndex(currentGame.currentItemIndex)

        if (nextItemIndex === null) {
          throw new Error('No next item index')
        }

        await updateGameCurrentDrawnItem(currentGame.gameId, nextItemIndex)
        await when(() => currentGame.currentItemIndex === nextItemIndex)

        currentGame.tracker.nextSong()
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoadingNext(false)
      }
    })

    const previousSong = action(async () => {
      try {
        setIsLoadingPrevious(true)

        const previousItemIndex = getPreviousIndex(currentGame.currentItemIndex)

        if (previousItemIndex === null) {
          throw new Error('No previous item index')
        }

        await updateGameCurrentDrawnItem(currentGame.gameId, previousItemIndex)
        await when(() => currentGame.currentItemIndex === previousItemIndex)

        currentGame.tracker.prevSong()
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoadingPrevious(false)
      }
    })

    const handleClickPrevious = action(async () => {
      setConfirmPrompt({
        heading: 'Are you sure you want to go back to the previous song?',
        onConfirm: () => previousSong(),
      })
    })

    return (
      <ButtonGroup>
        <Button
          disabled={
            !currentGame.isStartedGame ||
            !currentGame.currentItemIndex ||
            isLoadingPrevious
          }
          text='Previous Song'
          variant='outlined'
          onClick={handleClickPrevious}
        />

        <Button
          disabled={
            !currentGame.isStartedGame ||
            currentGame.currentItemIndex === FINAL_ITEM_INDEX ||
            isLoadingNext
          }
          text='Next Song'
          onClick={handleClickNext}
        />
      </ButtonGroup>
    )
  }
)
