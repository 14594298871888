import { Collections } from '@matthewlongpre/music-bingo-common'
import { doc, DocumentSnapshot, getDoc, onSnapshot } from 'firebase/firestore'
import { action } from 'mobx'

import { databaseRef } from '@/firebase/firebase'
import SnapshotListener from '@/store/game/snapshot-listeners/AbstractSnapshotListener'
import StoreService from '@/store/StoreService'

function getIntegrationDoc(userId: string, integrationId: string) {
  return doc(
    databaseRef,
    Collections.USERS,
    userId,
    'integrations',
    integrationId
  )
}

function applyIntegrationSettings(snapshot: DocumentSnapshot) {
  const { spotify } = StoreService.getStore()

  const settings = snapshot.data()

  if (!settings) {
    return
  }

  if (settings.playback.device) {
    spotify.devices.apply({
      storedDeviceIdRemote: settings.playback.device.id,
      storedDeviceIdRemoteTimestamp: settings.playback?.timestamp
        ? settings.playback.timestamp.toMillis()
        : undefined,
    })
  }

  if (settings.playback.enableExternalDevices !== undefined) {
    spotify.devices.apply({
      enableExternalDevices: settings.playback.enableExternalDevices,
    })
  }
}

export const fetchIntegrationSettings = action(async () => {
  const { user, spotify } = StoreService.getStore()
  const { id } = spotify

  const integrationDoc = getIntegrationDoc(user.userId, id)

  const snapshot = await getDoc(integrationDoc)

  applyIntegrationSettings(snapshot)
})

export class SpotifySettingsListener extends SnapshotListener {
  public subscribe(userId: string, integrationId: string): void {
    const integrationDoc = getIntegrationDoc(userId, integrationId)

    this._unsubscribe = onSnapshot(
      integrationDoc,
      async (snapshot) => {
        if (!snapshot.exists()) return

        applyIntegrationSettings(snapshot)
      },
      (error) => {
        console.error(
          `[SpotifySettingsListener] Encountered error: ${error.message}`
        )
      }
    )
  }
}
