import {
  ScoreboardDisplay,
  type Scoreboard,
  ScoreboardItem,
} from '@matthewlongpre/music-bingo-common'
import {
  formatScores,
  useAnimatedScores,
} from '@matthewlongpre/music-bingo-common/client'
import { motion } from 'framer-motion'

export function AnimatedScoreboard({ scores }: { scores: Scoreboard }) {
  const { animatedScores } = useAnimatedScores(scores)

  return (
    <ScoreboardDisplay>
      {animatedScores.map((score) => (
        <motion.li
          key={score.playerId}
          transition={{
            damping: 25,
            stiffness: 120,
            type: 'spring',
          }}
          layout
        >
          <ScoreboardItem {...score} />
        </motion.li>
      ))}
    </ScoreboardDisplay>
  )
}

export function StaticScoreboard({ scores }: { scores: Scoreboard }) {
  const { current } = formatScores(scores)

  return (
    <ScoreboardDisplay>
      {current.map((score) => (
        <li key={score.playerId}>
          <ScoreboardItem {...score} />
        </li>
      ))}
    </ScoreboardDisplay>
  )
}
