import {
  CheckIcon,
  Collections,
  Container,
  Description,
  Heading,
  Panel,
} from '@matthewlongpre/music-bingo-common'
import { useSuccessButton } from '@matthewlongpre/music-bingo-common/client'
import * as Sentry from '@sentry/react'
import classNames from 'classnames'
import { doc, getDoc } from 'firebase/firestore'
import { Form, Formik, useFormikContext } from 'formik'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'

import { AppHeader } from '@/components/AppHeader'
import { Button } from '@/components/Button'
import { FormError } from '@/components/forms/FormError'
import { TextField } from '@/components/forms/TextField'
import { BackButton, CloseButton } from '@/components/icon-buttons/IconButtons'
import { Main } from '@/components/Main'
import { Page } from '@/components/Page'
import { PageHeading } from '@/components/PageHeading'
import { databaseRef } from '@/firebase/firebase'
import StoreService from '@/store/StoreService'

import { MixpanelService } from '../../../mixpanel'

const URL_MIN_CHARACTERS = 3
const URL_MAX_CHARACTERS = 28
const URL_SLUG_REGEX = /^[a-z0-9]+(?:-[a-z0-9]+)*$/g

const VALIDATION_SCHEMA = Yup.object({
  url: Yup.string()
    .trim()
    .matches(URL_SLUG_REGEX, 'URL is not valid')
    .min(URL_MIN_CHARACTERS, `Must be ${URL_MIN_CHARACTERS} characters or more`)
    .max(URL_MAX_CHARACTERS, `Must be ${URL_MAX_CHARACTERS} characters or less`)
    .required('URL is required'),
})

const checkUrlAvailability = async (url: string) => {
  if (!url) return false

  const isValid = VALIDATION_SCHEMA.isValidSync({ url })

  if (!isValid) return false

  const docRef = await getDoc(
    doc(databaseRef, Collections.CUSTOM_JOIN_SCREEN_URLS, url)
  )

  if (docRef.exists()) {
    return false
  } else {
    return true
  }
}

export const JoinScreenSettingsRoute = observer(
  function JoinScreenSettingsRoute(): React.ReactElement {
    return <JoinScreenSettings />
  }
)

const JoinScreenSettings = observer(
  function JoinScreenSettings(): React.ReactElement {
    const [formError, setFormError] = useState<string | undefined>()

    const { user } = StoreService.getStore()
    const [isLoading, setIsLoading] = useState(false)
    const { showSuccess, showSuccessButtonState } = useSuccessButton()

    const history = useHistory()

    const handleSubmit = async (values: { url: string }) => {
      setIsLoading(true)

      try {
        await user.updateJoinScreenUrl(values.url)
        MixpanelService.changeJoinScreenUrl(values.url)

        setIsLoading(false)

        await showSuccessButtonState()

        history.goBack()
      } catch (error) {
        console.log(error)
        Sentry.captureException(error)

        setIsLoading(false)
        setFormError('Sorry, something went wrong')
      }
    }

    const handleBackClick = () => {
      history.goBack()
    }

    return (
      <Page title='Join Screen Settings'>
        <AppHeader
          actionsLeft={<BackButton onClick={handleBackClick} />}
          actionsRight={<CloseButton onClick={handleBackClick} />}
          header={<PageHeading>Join Screen Settings</PageHeading>}
        />

        <Main>
          <Container className='flex flex-col gap-8' size='medium'>
            <Panel>
              <Formik
                initialValues={{ url: user.settings?.joinScreen?.url ?? '' }}
                validationSchema={VALIDATION_SCHEMA}
                enableReinitialize
                onSubmit={handleSubmit}
              >
                <>
                  <JoinScreenUrlForm
                    isLoading={isLoading}
                    showSuccess={showSuccess}
                  />

                  {formError && <FormError error={formError} />}
                </>
              </Formik>
            </Panel>
          </Container>
        </Main>
      </Page>
    )
  }
)

interface JoinScreenFormProps {
  isDisabled?: boolean
  isLoading: boolean
  showSuccess: boolean
}

const JoinScreenUrlForm = observer(function JoinScreenUrlForm({
  isDisabled = false,
  isLoading,
  showSuccess,
}: JoinScreenFormProps): React.ReactElement {
  const {
    isSubmitting,
    isValid,
    values,
    initialValues,
    errors,
    setFieldValue,
    validateField,
  } = useFormikContext<{ url: string }>()
  const [urlIsAvailable, setUrlIsAvailable] = useState(false)

  useEffect(() => {
    void (async function () {
      const isUrlAvailable = await checkUrlAvailability(values.url)
      setUrlIsAvailable(isUrlAvailable)
    })()
  }, [values.url])

  useEffect(() => {
    setFieldValue('url', values.url.toLowerCase())
    validateField('url')
  }, [values.url, setFieldValue, validateField])

  const renderAvailability = () => {
    if (values.url === initialValues.url) {
      return (
        <AvailabilityMessage>
          <CheckIcon className='w-4 h-4 mr-2' /> Currently active
        </AvailabilityMessage>
      )
    } else if (!urlIsAvailable) {
      return (
        <AvailabilityMessage isAvailable={false}>
          Not Available. Please try again.
        </AvailabilityMessage>
      )
    } else if (urlIsAvailable) {
      return (
        <AvailabilityMessage>
          <CheckIcon className='w-4 h-4 mr-2' /> Available
        </AvailabilityMessage>
      )
    } else {
      ;<>Checking availability...</>
    }
  }

  const hasErrors = Boolean(Object.values(errors).length)

  return (
    <Form>
      <div className='flex flex-col gap-2 mb-8'>
        <Heading>Join Screen URL</Heading>
        <Description>
          Customize the URL for the join screen. This will enable guests to see
          your custom branding and pre-populate your current game code.
        </Description>
      </div>

      <div className='flex flex-col gap-4'>
        <TextField label='Custom URL' name='url' />

        <p className='text-muted'>
          {`${import.meta.env.VITE_PRIMARY_URL}/join` || '/join'}/
          <span style={{ color: 'var(--text)' }}>{values.url}</span>
        </p>

        {!hasErrors && renderAvailability()}
      </div>

      <Button
        className='mt-8'
        disabled={
          isDisabled ||
          isSubmitting ||
          !isValid ||
          !urlIsAvailable ||
          values.url === initialValues.url
        }
        isLoading={isLoading}
        loadingText='Saving...'
        showSuccess={showSuccess}
        text='Save'
        type='submit'
      />
    </Form>
  )
})

interface AvailabilityMessageProps {
  children: React.ReactNode
  isAvailable?: boolean
}

const AvailabilityMessage = observer(function AvailabilityMessage({
  children,
  isAvailable = true,
}: AvailabilityMessageProps) {
  return (
    <div
      className={classNames(
        'flex items-center p-3 font-semibold rounded text-white',
        {
          'bg-green-500': isAvailable,
          'bg-red-500': !isAvailable,
        }
      )}
    >
      {children}
    </div>
  )
})
