import {
  Description,
  Heading,
  Panel,
  PlusIcon,
  Spinner,
} from '@matthewlongpre/music-bingo-common'
import { useHistory } from 'react-router-dom'

import { Button } from '@/components/Button'
import { CardGridCategory } from '@/components/CardGridCategory'
import { PlaylistCard } from '@/components/PlaylistCard'
import { useQueryUserPlaylists } from '@/pages/playlists/playlist-queries/user-playlists/useQueryUserPlaylists'
import StoreService from '@/store/StoreService'

interface UserPlaylistsProps {
  onPlaylistClick: (playlistId: string) => void
}

export function UserPlaylists({ onPlaylistClick }: UserPlaylistsProps) {
  const { user } = StoreService.getStore()
  const history = useHistory()

  const { isLoading, data = [], isError } = useQueryUserPlaylists()

  if (isLoading) return <Spinner label='Loading your custom playlists...' />

  if (isError) {
    return (
      <Panel className='flex flex-col gap-6'>
        <div className='flex flex-col gap-2'>
          <Heading>Sorry, something went wrong</Heading>
          <Description>
            There was an error loading your custom playlists.
          </Description>
        </div>
        <Button text='Try Again' onClick={() => window.location.reload()} />
      </Panel>
    )
  }

  return (
    <CardGridCategory
      createCard={
        <>
          {user.canEditPlaylists && (
            <PlaylistCard
              className='border-4 border-dashed border-purple-500 text-text'
              icon={<PlusIcon />}
              title='Create a Custom Playlist'
              onClick={() => {
                history.push(`/playlists/create`)
              }}
            />
          )}
        </>
      }
      description={
        <Description>
          Playlists you{`'`}ve imported and fine-tuned to perfection.
        </Description>
      }
      handleClick={onPlaylistClick}
      heading='Your Custom Playlists'
      headingAction={
        <>
          {user.canEditPlaylists && (
            <Button
              className='min-w-[fit-content]'
              text='Edit'
              to='/playlists'
              variant='anchor'
            />
          )}
        </>
      }
      items={data}
    />
  )
}
