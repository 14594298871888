import {
  Badge,
  Container,
  Heading,
  Panel,
  Spinner,
} from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { List } from '@/components/List'
import {
  KeyBadge,
  SpotifyTrackLink,
  TempoBadge,
} from '@/components/RowAudioFeatures'
import { FormControlStyled } from '@/components/StyledComponents'
import { MINIMUM_ITEM_COUNT } from '@/utils/constants'

import { useQueryUserPlaylistPublic } from '../playlist-queries/user-playlists/useQueryUserPlaylistPublic'

interface AnonymousGameRouteParams {
  hostIdOrCustomUrl: string
  playlistId: string
}

export const PlaylistPublic = observer(
  function PlaylistPublic(): React.ReactElement {
    const { hostIdOrCustomUrl, playlistId } =
      useParams<AnonymousGameRouteParams>()

    const {
      data: playlist,
      isLoading,
      isError,
    } = useQueryUserPlaylistPublic(hostIdOrCustomUrl, playlistId)

    if (isLoading) {
      return <Spinner label='Loading playlist...' />
    }

    if (isError) {
      return <Panel>Sorry, something went wrong.</Panel>
    }

    if (!playlist) {
      return <Panel>Playlist not found.</Panel>
    }

    return (
      <PlaylistPublicStyled>
        <Container className='mt-6 mb-8' size='large'>
          <Heading className='text-center mb-6' size='large'>
            {playlist.title}
          </Heading>

          <List>
            {playlist.songs.slice(0, MINIMUM_ITEM_COUNT).map((song, index) => (
              <List.Item key={song.id}>
                <PlaylistTrackStyled>
                  <IconStyled>
                    <Badge>{index + 1}</Badge>
                  </IconStyled>

                  <PlaylistTrackGridStyled>
                    <FormControlStyled>
                      <label>Title</label>
                      <DisplayFieldStyled>{song.title}</DisplayFieldStyled>
                    </FormControlStyled>

                    <FormControlStyled>
                      <label>Artist</label>
                      <DisplayFieldStyled>{song.artist}</DisplayFieldStyled>
                    </FormControlStyled>

                    <AudioFeaturesStyled>
                      <FormControlStyled>
                        <label>Tempo</label>
                        <BadgeContainerStyled>
                          <TempoBadge song={song} />
                        </BadgeContainerStyled>
                      </FormControlStyled>

                      <FormControlStyled>
                        <label>Key</label>
                        <BadgeContainerStyled>
                          <KeyBadge song={song} />
                        </BadgeContainerStyled>
                      </FormControlStyled>

                      {song.spotifyMeta && (
                        <FormControlStyled>
                          <label>Preview</label>
                          <BadgeContainerStyled>
                            <SpotifyTrackLink
                              trackId={song.spotifyMeta.track.id}
                            />
                          </BadgeContainerStyled>
                        </FormControlStyled>
                      )}
                    </AudioFeaturesStyled>
                  </PlaylistTrackGridStyled>

                  <NotesRowStyled>
                    {song.userMeta?.notes && (
                      <FormControlStyled>
                        <label>Notes</label>
                        <NotesStyled>{song.userMeta?.notes}</NotesStyled>
                      </FormControlStyled>
                    )}
                  </NotesRowStyled>
                </PlaylistTrackStyled>
              </List.Item>
            ))}
          </List>
        </Container>
      </PlaylistPublicStyled>
    )
  }
)

export const PlaylistTrackStyled = styled.div`
  display: grid;
  grid-template-columns: 12px repeat(auto-fit, minmax(220px, 1fr));
  grid-gap: 20px;
  padding: 10px;
  border-radius: 8px;
  width: 100%;

  &.can-reorder {
    grid-template-columns: 48px repeat(auto-fit, minmax(220px, 1fr));
  }
`

export const DisplayFieldStyled = styled.div`
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  max-width: calc(100% - 20px);
`

export const IconStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const PlaylistTrackGridStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 20px;
`

const PlaylistPublicStyled = styled.div`
  ${PlaylistTrackStyled} {
    grid-template-columns: 48px repeat(auto-fit, minmax(220px, 1fr));

    grid-template-areas:
      'number details details'
      'number notes notes';
  }

  ${IconStyled} {
    grid-area: number;
  }

  ${PlaylistTrackGridStyled} {
    grid-area: details;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  ${DisplayFieldStyled} {
    font-size: 1.25em;

    span {
      min-width: 4em;
      text-align: center;
    }
  }

  @media print {
    font-size: 0.75rem;
    color: #000;

    ${PlaylistTrackGridStyled} {
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    }
  }
`

const AudioFeaturesStyled = styled.div`
  display: flex;

  ${FormControlStyled} {
    width: 100%;
  }
`

const NotesRowStyled = styled.div`
  grid-area: notes;
`

const NotesStyled = styled.div``

const BadgeContainerStyled = styled.div`
  width: 4em;
`
