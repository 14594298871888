import type {
  CalledBingo,
  DrawnSong,
  Game,
  MessageEvent,
  Player,
  PlayerWithCorrectCount,
  Scoreboard,
} from '@matthewlongpre/music-bingo-common'
import {
  Column,
  GameStatus,
  markPlayerData,
} from '@matthewlongpre/music-bingo-common'
import cloneDeep from 'lodash/cloneDeep'
import { makeAutoObservable } from 'mobx'

import { GameTracker } from '@/mixpanel/GameTracker'
import {} from '@/utils/mark-player-data'

import StoreService from '../StoreService'
import { applyProperties } from '../utils'

export function transformGameData(gameData: Game): Game {
  const transformedData = cloneDeep(gameData)

  if ('currentSong' in transformedData) {
    transformedData.currentItemIndex = transformedData.currentSong as
      | number
      | null
    delete transformedData.currentSong
  }

  if ('drawnSongs' in transformedData) {
    transformedData.drawnItems = transformedData.drawnSongs as DrawnSong[]
    delete transformedData.drawnSongs
  }

  return transformedData
}

export class GameStore {
  public calledBingosList: CalledBingo[] = []
  public currentMessage: MessageEvent | null = null
  public gameData: Game
  public gameId: string
  public players: Player[] = []
  public scores: Scoreboard | null = null

  public tracker: GameTracker = new GameTracker(this)

  public constructor(gameId: string, gameData: Game) {
    this.gameId = gameId
    this.gameData = transformGameData(gameData)

    makeAutoObservable(this)
  }

  get integration() {
    return this.store.spotify
  }

  public get activePlayers(): Player[] {
    return this.players.filter(
      ({ inLobby, hasLeftGame, isRemoved }) =>
        !inLobby && !hasLeftGame && !isRemoved
    )
  }

  public get markedPlayers(): PlayerWithCorrectCount[] {
    return this.activePlayers.map((playerData) =>
      markPlayerData(this.gameData, playerData)
    )
  }

  public get playersSortedByCorrect(): PlayerWithCorrectCount[] {
    return this.markedPlayers.sort((a, b) => b.correctCount - a.correctCount)
  }

  public get playersInLobby(): Player[] {
    return this.players.filter(({ inLobby }) => inLobby)
  }

  public get playersLeftGame(): Player[] {
    return this.players.filter(
      ({ hasLeftGame, isRemoved }) => hasLeftGame && !isRemoved
    )
  }

  public get removedPlayers(): Player[] {
    return this.players.filter(({ isRemoved }) => isRemoved)
  }

  public get remainingPlayerSlots(): number {
    const { maxPlayers } = this.store.user

    const remainingPlayerSlots = maxPlayers - this.activePlayers.length

    return remainingPlayerSlots >= 0 ? remainingPlayerSlots : 0
  }

  public get hasCalledBingo(): boolean {
    return Boolean(this.calledBingosList.length)
  }

  public get sortedCalledBingos(): CalledBingo[] {
    return [...this.calledBingosList].sort(
      (a, b) =>
        a.timestamp?.toDate()?.getTime() - b.timestamp?.toDate()?.getTime()
    )
  }

  public get hostUrlSlug(): string {
    return this.gameData.userSettings?.joinScreen?.url ?? this.gameData.hostId
  }

  public get isCompletedGame(): boolean {
    return this.gameData.gameStatus === GameStatus.GAME_COMPLETED
  }

  public get isCreatedGame(): boolean {
    return this.gameData.gameStatus === GameStatus.GAME_CREATED
  }

  public get isStartedGame(): boolean {
    return this.gameData.gameStatus === GameStatus.GAME_STARTED
  }

  public get customImageUrl(): string | undefined {
    return this.gameData.userSettings?.branding?.image?.url
  }

  public get hasCustomImage(): boolean {
    return Boolean(this.customImageUrl)
  }

  public get enableCoHostView(): boolean {
    return Boolean(this.gameData?.gameRestrictions?.enableCoHostView)
  }

  public get currentDrawnItem() {
    if (!this.isStartedGame) return

    return this.gameData.drawnItems[this.currentItemIndex]
  }

  public get currentItemIndex(): number {
    return this.gameData.currentItemIndex ?? 0
  }

  public get currentColumnName() {
    if (!this.currentDrawnItem) return

    const currentColumnIndex = this.currentDrawnItem.column
    return Column[currentColumnIndex]
  }

  public get currentColumnIndex() {
    return this.currentDrawnItem?.column
  }

  public get drawnItemsCalled() {
    if (!this.isStartedGame) return []

    return [...this.gameData.drawnItems].splice(0, this.currentItemIndex + 1)
  }

  public get previousDrawnItemsCalled() {
    return this.drawnItemsCalled.filter(
      (drawnItem) => drawnItem.id !== this.currentDrawnItem?.id
    )
  }

  private get store() {
    return StoreService.getStore()
  }

  public apply(updates: Partial<this>): void {
    applyProperties(this, updates)
  }
}
