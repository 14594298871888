/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable mobx/missing-observer */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ref,
  StorageReference,
  uploadBytesResumable,
  UploadTask,
} from 'firebase/storage'
import prettyBytes from 'pretty-bytes'
import React, { Component } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { resizeAndCropImage } from './utils/image'

const generateRandomFilename = (): string => uuidv4()

function extractExtension(filename: string): string {
  const ext = /(?:\.([^.]+))?$/.exec(filename)
  if (ext != null && ext[0] != null) {
    return ext[0]
  } else {
    return ''
  }
}

type Props = {
  accept?: string
  disabled?: boolean
  filename?: string | ((file: File) => string)
  form?: string
  formNoValidate?: boolean
  hidden?: boolean
  // default input props
  id?: string
  maxFilesize?: number
  maxHeight?: number
  maxWidth?: number
  metadata?: Record<string, unknown>
  multiple?: boolean
  name?: string
  onProgress?: (progress: number, task: UploadTask) => void
  onUploadError?: (error: Error, task?: UploadTask) => void
  onUploadStart?: (file: File, task: UploadTask) => void
  onUploadSuccess?: (filename: string, task: UploadTask) => void
  randomizeFilename?: boolean
  readOnly?: boolean
  required?: boolean
  setRef: (ref: HTMLInputElement | null) => void
  storageRef: StorageReference
  value?: string
}

export class FirebaseFileUploader extends Component<Props> {
  private uploadTasks: UploadTask[] = []

  // Cancel all running uploads before unmount
  public componentWillUnmount(): void {
    this.cancelRunningUploads()
  }

  public cancelRunningUploads(): void {
    while (this.uploadTasks.length > 0) {
      const task = this.uploadTasks.pop()
      if (!task) return

      if (task.snapshot.state === 'running') {
        task.cancel()
      }
    }
  }

  // Remove a specific task from the uploadTasks
  private removeTask(task: UploadTask) {
    for (let i = 0; i < this.uploadTasks.length; i++) {
      if (this.uploadTasks[i] === task) {
        this.uploadTasks.splice(i, 1)
        return
      }
    }
  }

  private startUpload(file: File) {
    const {
      onUploadStart,
      onUploadError,
      storageRef,
      metadata,
      randomizeFilename,
      filename,
      maxFilesize,
    } = this.props

    if (maxFilesize && file.size > maxFilesize) {
      onUploadError &&
        onUploadError(
          new Error(
            `File is too large. Please ensure your file does not exceed ${prettyBytes(
              maxFilesize
            )}`
          )
        )
      return
    }

    let filenameToUse: string
    if (filename) {
      filenameToUse = typeof filename === 'function' ? filename(file) : filename
    } else {
      filenameToUse = randomizeFilename ? generateRandomFilename() : file.name
    }

    // Ensure there is an extension in the filename
    if (!extractExtension(filenameToUse)) {
      filenameToUse += extractExtension(file.name)
    }

    void Promise.resolve()
      .then(() => {
        const shouldResize =
          // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
          file.type.match(/image.*/) &&
          (this.props.maxWidth || this.props.maxHeight)

        if (shouldResize) {
          return resizeAndCropImage(
            file,
            this.props.maxWidth,
            this.props.maxHeight
          )
        }

        return file
      })
      .then((file: File | null) => {
        if (!file) return

        const imageRef = ref(storageRef, filenameToUse)
        const task = uploadBytesResumable(imageRef, file, metadata)

        onUploadStart && onUploadStart(file, task)

        task.on(
          'state_changed',
          (snapshot) =>
            this.props.onProgress &&
            this.props.onProgress(
              Math.round(
                (100 * snapshot.bytesTransferred) / snapshot.totalBytes
              ),
              task
            ),
          (error) =>
            this.props.onUploadError && this.props.onUploadError(error, task),
          () => {
            this.removeTask(task)
            return (
              this.props.onUploadSuccess &&
              this.props.onUploadSuccess(task.snapshot.metadata.name, task)
            )
          }
        )
        this.uploadTasks.push(task)
      })
  }

  private handleFileSelection = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const {
      target: { files },
    } = event
    if (!files) return

    for (let i = 0; i < files.length; i++) {
      this.startUpload(files[i])
    }
  }

  public render(): React.ReactElement {
    const {
      setRef,
      storageRef,
      onUploadStart,
      onProgress,
      onUploadSuccess,
      onUploadError,
      randomizeFilename,
      metadata,
      filename,
      maxWidth,
      maxHeight,
      maxFilesize,
      hidden,
      ...props
    } = this.props

    return (
      <input
        ref={(ref) => setRef(ref)}
        type='file'
        onChange={this.handleFileSelection}
        {...props}
        style={{
          width: '0.1px',
          height: '0.1px',
          opacity: 0,
          overflow: 'hidden',
          position: 'absolute',
          zIndex: -1,
        }}
      />
    )
  }
}
