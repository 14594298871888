import { Collections } from '@matthewlongpre/music-bingo-common'
import { useQuery } from 'react-query'

import StoreService from '@/store/StoreService'

export const SYSTEM_PLAYLISTS_KEY = [Collections.SYSTEM_PLAYLISTS]

export function useQuerySystemPlaylists() {
  const { playlistService } = StoreService.getStore()

  const { queryKey, queryFn } = playlistService.queries.systemPlaylists

  return useQuery(queryKey, queryFn, {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24, // 24 hours
    cacheTime: 1000 * 60 * 60 * 24 * 7, // 7 days
  })
}
