import {
  Collections,
  Game,
  ToggleSwitch,
} from '@matthewlongpre/music-bingo-common'
import { doc, updateDoc } from 'firebase/firestore'
import { observer } from 'mobx-react-lite'
import React, { SyntheticEvent } from 'react'

import { Timestamp, databaseRef, setServerTimestamp } from '@/firebase/firebase'
import StoreService from '@/store/StoreService'

import { SettingsRow } from './SettingsRow'
import { useGameContext } from '../../game/context/game-context'

export const RequestContactDetailsSettings = observer(
  function RequestContactDetailsSettings(): React.ReactElement {
    const { user } = StoreService.getStore()
    const { gameId, gameData, tracker } = useGameContext()

    const handleChange = (event: SyntheticEvent) => {
      const { checked } = event.target as HTMLInputElement

      const update: Pick<Game, 'requestContactDetails' | 'lastActivity'> = {
        requestContactDetails: checked,
        lastActivity: setServerTimestamp() as Timestamp,
      }

      void updateDoc(doc(databaseRef, Collections.GAMES, gameId), update)
      tracker.changeToggle('Request Winner Contact Details', checked)
    }

    return (
      <SettingsRow
        description='Collect contact information from winning guests.'
        heading='Winner Contact Details'
      >
        <ToggleSwitch
          id='requestContactDetails'
          isChecked={gameData.requestContactDetails}
          onChange={handleChange}
        />
      </SettingsRow>
    )
  }
)
