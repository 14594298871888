import { ColourPalette } from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'

import { PlayerList } from './PlayerList'
import { useGameContext } from '../pages/game/context/game-context'
import { useGameControlsContext } from '../pages/game/context/game-controls-context'

export const RemovedPlayers = observer(
  function RemovedPlayers(): React.ReactElement {
    const { removedPlayers } = useGameContext()

    const { handleReadmitPlayerClick } = useGameControlsContext()
    const [isReadmittingPlayer, setIsReadmittingPlayer] = useState(false)

    const handleClick = async (playerId: string) => {
      setIsReadmittingPlayer(true)
      await handleReadmitPlayerClick(playerId)
      setIsReadmittingPlayer(false)
    }

    return (
      <PlayerList
        badgeColor={ColourPalette.RED}
        buttonProps={{
          disabled: isReadmittingPlayer,
          loading: isReadmittingPlayer,
          loadingText: 'Readmitting...',
          onClick: (playerId: string) => void handleClick(playerId),
          text: 'Readmit',
        }}
        description='Guests you have removed from the game.'
        noItemsText='No guests have left the game.'
        players={removedPlayers}
        title='Removed'
      />
    )
  }
)
