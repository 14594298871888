import { Heading, merge } from '@matthewlongpre/music-bingo-common'

import { Avatar } from './Avatar'
import { useQuerySpotifyProfile } from '../queries/useQuerySpotifyProfile'

export function SpotifyProfileBadge({
  className,
  size = 'large',
}: {
  className?: string
  size?: 'small' | 'large'
}) {
  const { data: profile, isLoading, isError } = useQuerySpotifyProfile()

  if (isLoading || isError || !profile) return null

  if (size === 'small') {
    return (
      <div
        className={merge(
          'flex flex-col gap-1 justify-center overflow-hidden',
          className
        )}
      >
        <div className='flex items-center'>
          <div className='text-muted uppercase text-2xs tracking-wider'>
            Spotify account:
          </div>
        </div>

        <div className='flex items-center gap-2 text-sm overflow-hidden max-w-full'>
          <Avatar imageUrl={profile.imageUrl} />
          <Heading size='extra-small'>{profile.displayName}</Heading>
        </div>
      </div>
    )
  }

  return (
    <div className='flex flex-col justify-center gap-3'>
      <div className='flex items-center'>
        <div className='text-muted uppercase text-2xs tracking-wider'>
          Spotify account:
        </div>
      </div>

      <div className='flex gap-3'>
        <Avatar className='w-12 h-12' imageUrl={profile.imageUrl} />

        <div className='flex flex-col'>
          <div>{profile.displayName}</div>
          <div className='text-muted'>{profile.email}</div>
        </div>
      </div>
    </div>
  )
}
