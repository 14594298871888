import { observer } from 'mobx-react-lite'
import React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'

import { PopoverArrowPlacement, PopoverPlacement } from '.'

const ARROW_OFFSET = 17

interface PopoverProps {
  arrowPlacement?: PopoverArrowPlacement
  attributes: {
    [key: string]: { [key: string]: string } | undefined
  }
  children: React.ReactNode
  className?: string
  enableArrow?: boolean
  enableBackdrop?: boolean
  handleBackdropClick: () => void
  placement?: PopoverPlacement
  setArrowElement: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>
  setPopperElement: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>
  showPopover: boolean
  styles: {
    [key: string]: React.CSSProperties
  }
}

export const Popover = observer(function Popover({
  arrowPlacement = 'auto',
  attributes,
  children,
  className,
  enableArrow = true,
  enableBackdrop = true,
  handleBackdropClick,
  placement,
  setArrowElement,
  setPopperElement,
  showPopover,
  styles,
}: PopoverProps): React.ReactElement {
  const getArrowStyle = () => {
    if (placement === 'auto') return styles['arrow']
    if (arrowPlacement === 'start') return { left: ARROW_OFFSET }
    if (arrowPlacement === 'end') return { right: ARROW_OFFSET }
    return styles['arrow']
  }

  return (
    <>
      {showPopover && (
        <>
          {ReactDOM.createPortal(
            <>
              {enableBackdrop && (
                <PopoverBackdropStyled
                  className='popover-backdrop'
                  onClick={handleBackdropClick}
                />
              )}

              <PopoverStyled
                {...attributes['popper']}
                className={className}
                ref={setPopperElement}
                style={styles['popper']}
              >
                {enableArrow && (
                  <div
                    className='arrow'
                    ref={setArrowElement}
                    style={getArrowStyle()}
                  />
                )}
                <div className='popover-container'>{children}</div>
              </PopoverStyled>
            </>,
            document.querySelector('#portal') as Element
          )}
        </>
      )}
    </>
  )
})

const PopoverStyled = styled.div`
  --popover-background: var(--bg-02);
  --popover-border: var(--bg-04);
  --popover-text: var(--text);
  --popover-border-width: 1px;
  --popover-arrow-size: 6px;

  z-index: 1200;
  background: var(--popover-background);
  color: var(--popover-text);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.14;
  border: 1px solid var(--popover-border);
  border-radius: 6px;
  box-shadow: 0 6px 10px 0 rgba(72, 75, 91, 0.15);

  .arrow,
  .arrow::before {
    position: absolute;
    width: var(--popover-arrow-size);
    height: var(--popover-arrow-size);
    background: inherit;
  }

  .arrow {
    visibility: hidden;
  }

  .arrow::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
  }

  &[data-popper-placement^='top'] > .arrow {
    bottom: calc(var(--popover-arrow-size) / 2 * -1);

    &:before {
      border-bottom: var(--popover-border-width) solid var(--popover-border);
      border-right: var(--popover-border-width) solid var(--popover-border);
    }
  }

  &[data-popper-placement^='bottom'] > .arrow {
    top: calc(var(--popover-arrow-size) * 0.625 * -1);

    &:before {
      border-top: var(--popover-border-width) solid var(--popover-border);
      border-left: var(--popover-border-width) solid var(--popover-border);
    }
  }

  &[data-popper-placement^='left'] > .arrow {
    right: calc(var(--popover-arrow-size) / 2 * -1);

    &:before {
      border-top: var(--popover-border-width) solid var(--popover-border);
      border-right: var(--popover-border-width) solid var(--popover-border);
    }
  }

  &[data-popper-placement^='right'] > .arrow {
    left: calc(var(--popover-arrow-size) * 0.625 * -1);

    &:before {
      border-bottom: var(--popover-border-width) solid var(--popover-border);
      border-left: var(--popover-border-width) solid var(--popover-border);
    }
  }

  .popover-container {
    position: relative;
  }
`

const PopoverBackdropStyled = styled.div`
  -webkit-app-region: no-drag;
  position: fixed;
  width: 100%;
  height: 100%;
  inset: 0;
`
