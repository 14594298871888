import { Collections } from '@matthewlongpre/music-bingo-common'
import { collection, onSnapshot, query, where } from 'firebase/firestore'

import { databaseRef } from '@/firebase/firebase'

import SnapshotListener from '../game/snapshot-listeners/AbstractSnapshotListener'
import StoreService from '../StoreService'

class HostListener extends SnapshotListener {
  public subscribe(hostId: string): void {
    const usersCollection = collection(databaseRef, Collections.USERS)
    const usersQuery = query(usersCollection, where('userId', '==', hostId))

    this._unsubscribe = onSnapshot(
      usersQuery,
      (snapshot) => {
        const store = StoreService.getStore()

        if (snapshot.empty) {
          store.setHost(undefined)
          return
        }

        const userDocs = snapshot.docs

        const [hostDoc] = userDocs.map((gameDoc) => gameDoc.data())

        store.setHost({ ...hostDoc, hostId: hostDoc.userId })
      },
      (error) => {
        console.error(`Encountered error: ${error.message}`)
      }
    )
  }
}

export const hostListener = new HostListener()
