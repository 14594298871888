import { Description } from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import Balancer from 'react-wrap-balancer'

import { Button } from '@/components/Button'

export const PlayingInAnotherTabWarning = observer(
  function PlayingInAnotherTabWarning() {
    return (
      <div className='flex flex-col gap-6 text-center '>
        <Description className='text-center mx-auto'>
          <Balancer>
            The Rockstar Bingo Player is managing Spotify playback from a
            different tab.
          </Balancer>
        </Description>
        <Button
          className='mx-auto'
          text='Playback Settings'
          to='/game/settings/playback'
          variant='anchor'
        />
      </div>
    )
  }
)
