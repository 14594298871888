import { Game, ToggleSwitch } from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React from 'react'

import { useGameContext } from '@/pages/game/context/game-context'
import { updateGameSetting } from '@/store/game/updateGameDocument'

import { SettingsRow } from './SettingsRow'

const SETTING_NAME = 'Scoreboard'
const SETTING_ID = 'enableScoreboard' as const

export const EnableScoreboard = observer(
  function EnableScoreboard(): React.ReactElement {
    const { gameId, gameData, tracker } = useGameContext()

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target

      const update: Pick<Game, typeof SETTING_ID> = {
        [SETTING_ID]: checked,
      }

      void updateGameSetting(gameId, update)
      tracker.changeToggle(SETTING_NAME, checked)
    }
    return (
      <SettingsRow
        description='Show a scoreboard after each confirmed bingo.'
        heading={SETTING_NAME}
      >
        <ToggleSwitch
          id={SETTING_ID}
          isChecked={gameData.enableScoreboard}
          isDisabled={!gameData}
          onChange={handleChange}
        />
      </SettingsRow>
    )
  }
)
