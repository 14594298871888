import {
  BingoCard,
  ButtonGroup,
  Card,
  Description,
  Game,
  Heading,
  Player,
  Square,
} from '@matthewlongpre/music-bingo-common'
import { Dialog } from '@matthewlongpre/music-bingo-common/client'
import { observer } from 'mobx-react-lite'
import React, { useMemo, useState } from 'react'

import { Button } from '@/components/Button'

import { useGameContext } from '../pages/game/context/game-context'

interface ManualBingoVerificationModalProps {
  gameData: Game
  isVisible: boolean
  onConfirm: (playerData: Player) => Promise<void>
  onDeny: (isManual: boolean) => Promise<void>
  playerId: string
}

interface UpdatedCard extends Card {
  [key: number]: Square[]
}

export const ManualBingoVerificationModal = observer(
  function ManualBingoVerificationModal({
    gameData,
    onConfirm,
    onDeny,
    playerId,
    isVisible,
  }: ManualBingoVerificationModalProps): React.ReactElement {
    const { players } = useGameContext()
    const [playerData] = players.filter(
      (player) => player.playerId === playerId
    )

    const playerCardSnapshot = useMemo(
      () => JSON.parse(JSON.stringify(playerData.playerCard)) as UpdatedCard,
      [playerData.playerCard]
    )

    const markFreeCorrect = (playerCard: Card) => {
      const card = { ...playerCard }
      card[2][2].correct = true
      return card
    }

    const [markedPlayerCard, setMarkedPlayerCard] = useState(
      markFreeCorrect(playerData.playerCard)
    )

    const handleConfirmBingoClick = async () => {
      await onConfirm({
        ...playerData,
        playerCard: markedPlayerCard,
      })
    }

    const handleSquareClick = (columnIndex: number, squareIndex: number) => {
      const squareSnapshot = playerCardSnapshot[columnIndex][squareIndex]

      if (!squareSnapshot.daubed || squareSnapshot.correct) return

      const updatedPlayerCard: UpdatedCard = { ...markedPlayerCard }
      const square = updatedPlayerCard[columnIndex][squareIndex]

      square.correct = !square.correct

      setMarkedPlayerCard(updatedPlayerCard)
    }

    return (
      <Dialog className='max-h-full' isVisible={isVisible}>
        <Dialog.Body className='pt-0'>
          <Heading className='text-center' size='large'>
            {playerData.playerName}
          </Heading>

          <Description className='w-full text-center text-muted mx-auto mt-2 mb-8'>
            Tap any <span className='text-blue-500'>selected</span> squares to
            mark them <span className='text-green-500'>correct</span>
          </Description>

          <div className='bg-shade-3 rounded-xl'>
            <BingoCard
              currentPlayerHasCalledBingo={false}
              gameData={gameData}
              playerData={{ ...playerData, playerCard: markedPlayerCard }}
              onSquareClick={handleSquareClick}
            />
          </div>
        </Dialog.Body>
        <Dialog.Footer>
          <ButtonGroup direction='row'>
            <Button text='Confirm' onClick={handleConfirmBingoClick} />
            <Button
              text='Deny'
              variant='outlined'
              onClick={() => onDeny(true)}
            />
          </ButtonGroup>
        </Dialog.Footer>
      </Dialog>
    )
  }
)
