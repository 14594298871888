import { ColourPalette , Label } from '@matthewlongpre/music-bingo-common'
import { useField } from 'formik'
import { observer } from 'mobx-react-lite'


interface ColorPaletteSelectorProps {
  onChange: (value: ColourPalette) => void
  selectedValue?: ColourPalette
}

export const ColorPaletteSelector = observer(function ColorPaletteSelector({
  selectedValue,
  onChange,
}: ColorPaletteSelectorProps) {
  return (
    <fieldset className='flex gap-2'>
      {getColourOptions().map((option: string) => (
        <div key={option}>
          <input
            checked={selectedValue === option}
            className='hidden peer'
            id={option}
            name='colour'
            type='radio'
            value={option}
            onChange={(event) => onChange(event.target.value as ColourPalette)}
          />

          <Label
            className='flex w-12 h-12 rounded peer-checked:border-4 border-white'
            htmlFor={option}
            style={{ backgroundColor: `var(--${option.toLowerCase()}-500)` }}
          />
        </div>
      ))}
    </fieldset>
  )
})

function getColourOptions(): string[] {
  const colourOptions: string[] = []

  for (const colour in ColourPalette) {
    const colourOption: ColourPalette =
      ColourPalette[colour as keyof typeof ColourPalette]
    colourOptions.push(colourOption)
  }
  return colourOptions
}

export const ColourPaletteField = observer(function ColourPaletteField() {
  const [field] = useField('colour')

  return (
    <ColorPaletteSelector
      selectedValue={field.value as ColourPalette}
      onChange={field.onChange}
    />
  )
})
