import {
  Container,
  Description,
  merge,
  Panel,
} from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { AppHeader } from '@/components/AppHeader'
import { Button } from '@/components/Button'
import { List } from '@/components/List'
import { Main } from '@/components/Main'
import { Nav } from '@/components/Nav'
import { Page } from '@/components/Page'
import { PageHeading } from '@/components/PageHeading'
import { TipBadge } from '@/components/TipBadge'
import { Title } from '@/components/Title'
import { useGameContext } from '@/pages/game/context/game-context'
import { BingoTargetSettings } from '@/pages/settings/components/BingoTargetSettings'
import { DisableBingoVerification } from '@/pages/settings/components/DisableBingoVerification'
import { EnableScoreboard } from '@/pages/settings/components/EnableScoreboard'
import { HideArtist } from '@/pages/settings/components/HideArtist'
import { PlayerCardHighlighting } from '@/pages/settings/components/PlayerCardHighlighting'
import { RequestContactDetailsSettings } from '@/pages/settings/components/RequestContactDetailsSettings'

export const SetupGameRoute = observer(
  function SetupGameplayRoute(): React.ReactElement {
    return <SetupGame />
  }
)

const SetupGame = observer(function SetupGame(): React.ReactElement {
  const [showMore, setShowMore] = useState(false)

  const { gameData, isStartedGame } = useGameContext()

  const history = useHistory()

  const handleContinueClick = () => {
    history.push(`/game/lobby/setup`)
  }

  return (
    <Page title='Game Setup'>
      <AppHeader header={<PageHeading>Game Setup</PageHeading>} />

      <Main>
        <Container className='mb-8' size='medium'>
          <Panel>
            <TipBadge />
            <Description className='mt-2'>
              Settings take effect in real-time and you can change them
              throughout the game.
            </Description>
          </Panel>
        </Container>

        <Container size='medium'>
          <Panel
            className={merge({ 'overflow-hidden': showMore })}
            disablePadding
          >
            {!gameData.disableSystemBingoVerification && (
              <BingoTargetSettings
                disableConfirmPrompt={!isStartedGame}
                disableMessageQueue={!isStartedGame}
              />
            )}

            <Button
              text={showMore ? 'Show Less' : 'Show More'}
              variant='flat'
              onClick={() => setShowMore(!showMore)}
            />

            {showMore && (
              <>
                <DisableBingoVerification />

                <PlayerCardHighlighting />

                {gameData.type === 'music' && <HideArtist />}

                <EnableScoreboard />

                <RequestContactDetailsSettings />

                <Nav>
                  <List className='rounded-none border-none'>
                    <>
                      <List.Item
                        to='/game/settings/jumbotron'
                        disableBorder
                        disableRadiusTop
                        showChevron
                      >
                        <Title
                          description='Customize a page intended as a large video screen display.'
                          heading='Jumbotron'
                        />
                      </List.Item>

                      <List.Item
                        to='/game/settings/branding'
                        disableBorder
                        showChevron
                      >
                        <Title
                          description='Customize branding elements for this specific game.'
                          heading='Branding'
                        />
                      </List.Item>

                      <List.Item
                        to='/game/settings/venue'
                        disableBorder
                        showChevron
                      >
                        <Title
                          description="Add information about the location you're hosting this game at."
                          heading='Venue'
                        />
                      </List.Item>
                    </>
                  </List>
                </Nav>
              </>
            )}
          </Panel>
        </Container>

        <Container className='mt-8' size='medium'>
          <Button text='Continue' onClick={handleContinueClick} />
        </Container>
      </Main>
    </Page>
  )
})
