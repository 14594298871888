import { Container } from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import {
  DragDropContext,
  Draggable,
  DraggableLocation,
  DraggableProvided,
  DropResult,
  Droppable,
  DroppableProvided,
  Placeholder,
} from 'react-beautiful-dnd'

import { List } from '@/components/List'
import { DraggableListRowStyled } from '@/components/StyledComponents'
import { Title } from '@/components/Title'
import { MINIMUM_ITEM_COUNT } from '@/utils/constants'


enum DropZone {
  DROPZONE_ONE = 'dropzone_one',
  DROPZONE_TWO = 'dropzone_two',
}

interface DraggableProvidedWithPlaceholder extends DraggableProvided {
  placeholder?: Placeholder
}

interface DraggableListItem {
  content: React.ReactNode
  id: number
}

interface State {
  [key: string]: DraggableListItem[]
  extra: DraggableListItem[]
  items: DraggableListItem[]
}

interface IMoveResult {
  [DropZone.DROPZONE_ONE]: DraggableListItem[]
  [DropZone.DROPZONE_TWO]: DraggableListItem[]
}

interface DraggableListProps {
  onClick: (id: number) => void
  onUpdate: (items: { id: number }[]) => void
  sourceItems: DraggableListItem[]
}

function splitItems(sourceItems: DraggableListItem[]) {
  const items = sourceItems.slice(0, MINIMUM_ITEM_COUNT)
  const extra = sourceItems.slice(MINIMUM_ITEM_COUNT, sourceItems.length)

  return {
    items,
    extra,
  }
}

export const DraggableList = observer(function DraggableList({
  onClick,
  onUpdate,
  sourceItems,
}: DraggableListProps): React.ReactElement {
  const [isMounted, setIsMounted] = useState(false)

  const localState: State = splitItems(sourceItems)

  const mapIDToListType: { [key: string]: string } = {
    [DropZone.DROPZONE_ONE]: 'items',
    [DropZone.DROPZONE_TWO]: 'extra',
  }

  const reorder = (
    list: DraggableListItem[],
    startIndex: number,
    endIndex: number
  ): DraggableListItem[] => {
    const result = [...list]
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const move = (
    source: DraggableListItem[],
    destination: DraggableListItem[],
    droppableSource: DraggableLocation,
    droppableDestination: DraggableLocation
  ): IMoveResult => {
    const sourceClone = [...source]
    const destClone = [...destination]
    const [removed] = sourceClone.splice(droppableSource.index, 1)

    destClone.splice(droppableDestination.index, 0, removed)

    const result = {} as {
      [DropZone.DROPZONE_ONE]: DraggableListItem[]
      [DropZone.DROPZONE_TWO]: DraggableListItem[]
      [key: string]: DraggableListItem[]
    }
    result[droppableSource.droppableId] = sourceClone
    result[droppableDestination.droppableId] = destClone

    return result
  }

  const getList = (id: string): DraggableListItem[] => {
    return localState[mapIDToListType[id]]
  }

  const onDragEnd = (result: DropResult): void => {
    const { source, destination } = result

    if (!destination) {
      return
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        getList(source.droppableId),
        source.index,
        destination.index
      )

      let updatedState: State = { ...localState }

      if (source.droppableId === DropZone.DROPZONE_TWO) {
        updatedState = { ...localState, extra: items }
      } else if (source.droppableId === DropZone.DROPZONE_ONE) {
        updatedState = { ...localState, items }
      }

      const reorderedItems = [...updatedState.items, ...updatedState.extra]

      onUpdate(reorderedItems)
    } else {
      const resultFromMove: IMoveResult = move(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination
      )

      const updatedState = splitItems([
        ...resultFromMove[DropZone.DROPZONE_ONE],
        ...resultFromMove[DropZone.DROPZONE_TWO],
      ])

      const reorderedItems = [...updatedState.items, ...updatedState.extra]

      onUpdate(reorderedItems)
    }
  }

  const { items, extra } = splitItems(sourceItems)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div
        style={{
          display: `flex`,
          justifyContent: `space-between`,
          flexDirection: `column`,
        }}
      >
        {isMounted && (
          <>
            <Droppable droppableId={DropZone.DROPZONE_ONE}>
              {(provided: DroppableProvided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <List>
                    {items.map((item, index) => (
                      <Draggable
                        draggableId={item.id.toString()}
                        index={index}
                        key={item.id}
                      >
                        {(
                          providedDraggable: DraggableProvidedWithPlaceholder
                        ) => (
                          <>
                            <DraggableListRowStyled
                              className='group'
                              ref={providedDraggable.innerRef}
                              onClick={() => onClick(item.id)}
                              {...providedDraggable.draggableProps}
                              {...providedDraggable.dragHandleProps}
                            >
                              {item.content}
                            </DraggableListRowStyled>
                            {providedDraggable.placeholder}
                          </>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </List>
                </div>
              )}
            </Droppable>

            <Container size='extra-large'>
              <Title
                className='mt-8 mb-4'
                description='With Setlist Mode enabled, songs that exceed the 75 song maximum will not play.'
                heading='Extras'
              />
            </Container>

            <Droppable droppableId={DropZone.DROPZONE_TWO}>
              {(providedDroppable2: DroppableProvided) => (
                <div ref={providedDroppable2.innerRef}>
                  <List>
                    {extra.map((item, index) => (
                      <Draggable
                        draggableId={item.id.toString()}
                        index={index}
                        key={item.id}
                      >
                        {(
                          providedDraggable2: DraggableProvidedWithPlaceholder
                        ) => (
                          <>
                            <DraggableListRowStyled
                              className='group'
                              ref={providedDraggable2.innerRef}
                              {...providedDraggable2.draggableProps}
                              {...providedDraggable2.dragHandleProps}
                            >
                              {item.content}
                            </DraggableListRowStyled>
                            {providedDraggable2.placeholder}
                          </>
                        )}
                      </Draggable>
                    ))}
                    {providedDroppable2.placeholder}
                  </List>
                </div>
              )}
            </Droppable>
          </>
        )}
      </div>
    </DragDropContext>
  )
})
