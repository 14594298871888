import { CommonProps, merge } from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React from 'react'

import { Image } from './Image'
import { Logo } from './Logo'
import { useGameContext } from '../pages/game/context/game-context'

export const BrandImage = observer(function BrandImage({
  className,
}: CommonProps): React.ReactElement {
  const { customImageUrl, hasCustomImage } = useGameContext()

  if (hasCustomImage) {
    return (
      <BrandImageContainer className={merge(className)}>
        <Image alt='Logo' className='object-contain' src={customImageUrl} />
      </BrandImageContainer>
    )
  }

  return (
    <BrandImageContainer
      className={merge('bg-shade-0 items-center justify-center p-3', className)}
    >
      <Logo />
    </BrandImageContainer>
  )
})

const BrandImageContainer = observer(function BrandImageContainer({
  children,
  className,
}: CommonProps) {
  return (
    <div
      className={merge(
        'flex w-32 h-32 flex-shrink-0 aspect-square overflow-hidden rounded-xl',
        className
      )}
    >
      {children}
    </div>
  )
})
