import { CreateGameOptions, Game } from '@matthewlongpre/music-bingo-common'
import { makeAutoObservable } from 'mobx'

import { apiClient } from '@/api/api-client'
import { InternalPlaylistService } from '@/pages/playlists/playlist-queries/InternalPlaylistService'

import { GameStore } from './game/GameStore'
import { Host, HostStore } from './host/HostStore'
import { SpotifyIntegration } from './integrations/spotify/SpotifyIntegrationModel'
import { UserStore } from './user/UserStore'

export class Store {
  public user

  public host?: HostStore

  public currentGame?: GameStore
  public isLoadingGame = true

  public spotify
  public playlistService

  public constructor() {
    this.user = new UserStore()
    this.spotify = new SpotifyIntegration()
    this.playlistService = new InternalPlaylistService()

    makeAutoObservable(this)
  }

  public setCurrentGame(currentGame?: Game): void {
    if (!currentGame) {
      this.currentGame = undefined
      this.isLoadingGame = false
      return
    }

    if (currentGame?.gameId !== this.currentGame?.gameId) {
      this.currentGame = new GameStore(currentGame.gameId, currentGame)
      this.isLoadingGame = false
    }
  }

  public createGame(options: CreateGameOptions): Promise<{ gameId: string }> {
    return apiClient.createGame(options)
  }

  public setHost(host?: Host) {
    if (!host) {
      this.host = undefined
      return
    }

    if (host?.hostId !== this.host?.hostId) {
      this.host = new HostStore(host)
      return
    }

    this.host.apply(host)
  }
}
