import { ColourPalette } from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'

import { PlayerList, PlayerListTab } from './PlayerList'
import { useGameContext } from '../pages/game/context/game-context'
import { useGameControlsContext } from '../pages/game/context/game-controls-context'

export const ActivePlayers = observer(
  function ActivePlayers(): React.ReactElement {
    const { playersSortedByCorrect } = useGameContext()

    const { handleRemovePlayerClick } = useGameControlsContext()
    const [isRemovingPlayer, setIsRemovingPlayer] = useState(false)

    const handleClick = async (playerId: string) => {
      setIsRemovingPlayer(true)
      await handleRemovePlayerClick(playerId)
      setIsRemovingPlayer(false)
    }

    return (
      <PlayerList
        badgeColor={ColourPalette.GREEN}
        buttonProps={{
          disabled: isRemovingPlayer,
          loading: isRemovingPlayer,
          loadingText: 'Removing...',
          onClick: (playerId: string) => void handleClick(playerId),
          text: 'Remove',
        }}
        defaultTab={PlayerListTab.CARDS}
        description='Guests who are actively playing the game.'
        noItemsText='No guests are in the game.'
        players={playersSortedByCorrect}
        title='Playing'
        enableTabs
      />
    )
  }
)
