import { PlanType, merge, Badge } from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React from 'react'

import { createUpgradeLink } from '@/billing/createUpgradeToProLink'

import StoreService from '../store/StoreService'

const CLASSES = {
  free: '',
  premium: 'bg-green-500 border-green-500 text-white',
  basic: 'bg-green-500 border-green-500 text-white',
  pro: 'bg-pink-500 border-pink-500 text-white',
}

interface UserPlanTypeBadgeProps {
  className?: string
}

export const PlanTypeBadge = observer(function PlanTypeBadge({
  className,
}: UserPlanTypeBadgeProps): React.ReactElement {
  const { user } = StoreService.getStore()
  const { planType } = user

  return <PlanTypeBadgeInternal className={className} planType={planType} />
})

interface PlanTypeBadgeProps {
  children?: React.ReactNode
  className?: string
  planType: PlanType
}

const PlanTypeBadgeInternal = observer(function PlanTypeBadge({
  className,
  children,
  planType,
}: PlanTypeBadgeProps): React.ReactElement {
  return (
    <Badge
      className={merge(
        'text-xs sm:text-xs w-auto',
        CLASSES[planType],
        className
      )}
    >
      {planType.replace('_', ' ')}
      {children}
    </Badge>
  )
})

interface RequiredPlanTypeBadgeProps {
  className?: string
  requiredPlanType: PlanType
}

export const RequiredPlanTypeBadge = observer(function RequiredPlanTypeBadge({
  requiredPlanType,
  className,
}: RequiredPlanTypeBadgeProps): React.ReactElement | null {
  const { user } = StoreService.getStore()

  if (user.planType === requiredPlanType) {
    return null
  }

  return (
    <button className='text-left' onClick={() => void createUpgradeLink()}>
      <PlanTypeBadgeInternal
        className={merge(requiredPlanType, className, 'gap-1')}
        planType={requiredPlanType}
      >
        <span>Only</span>
      </PlanTypeBadgeInternal>
    </button>
  )
})
