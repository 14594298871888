import {
  AuthProvider,
  ButtonGroup,
  Panel,
  Heading,
  Container,
} from '@matthewlongpre/music-bingo-common'
import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'

import { AuthButton, AuthType } from '@/authentication/auth-button'
import { Button } from '@/components/Button'
import {
  PasswordForm,
  PasswordFormValues,
} from '@/components/forms/PasswordForm'
import { LogoHeader } from '@/components/LogoHeader'
import { Main } from '@/components/Main'
import { Page } from '@/components/Page'
import { TermsOfService } from '@/components/TermsOfService'
import { firebaseApp } from '@/firebase/firebase'
import StoreService from '@/store/StoreService'
import { useSearchParams } from '@/utils/use-search-params'

export const CreateAccountRoute = observer(
  function CreateAccountRoute(): React.ReactElement {
    return <CreateAccount />
  }
)

const CreateAccount = observer(function CreateAccount(): React.ReactElement {
  const query = useSearchParams()

  const [showEmailForm, setShowEmailForm] = useState(false)

  const { user } = StoreService.getStore()

  const handleShowEmailForm = () => {
    setShowEmailForm(!showEmailForm)
  }

  const handleSubmit = async ({ email, password }: PasswordFormValues) => {
    const auth = getAuth(firebaseApp)
    return createUserWithEmailAndPassword(auth, email, password)
  }

  if (user.isLoggedIn && !user.isAnonymous) {
    return <Redirect to={`/setup-account?${query.toString()}`} />
  }

  return (
    <Page title='Create Account'>
      <Main>
        <LogoHeader />

        <Container size='small'>
          <Panel className='flex flex-col gap-6'>
            <Heading className='text-center' size='large'>
              Create an Account
            </Heading>

            {!showEmailForm && (
              <ButtonGroup direction='column'>
                <AuthButton
                  authType={AuthType.SIGNUP}
                  provider={AuthProvider.EMAIL}
                  onClick={handleShowEmailForm}
                />
                <AuthButton
                  authType={AuthType.SIGNUP}
                  provider={AuthProvider.GOOGLE}
                  onClick={() => void user.signInGoogle()}
                />
              </ButtonGroup>
            )}

            {showEmailForm && (
              <>
                <PasswordForm
                  buttonText='Sign up with email'
                  onSubmit={handleSubmit}
                />

                <Button
                  text='Choose another sign up method'
                  variant='anchor'
                  onClick={handleShowEmailForm}
                />
              </>
            )}

            <footer className='border-t border-shade-3 text-right py-6'>
              <Heading className='m-0 justify-end' size='small'>
                Already have an account?
              </Heading>
              <Button
                className='mt-2 justify-end'
                text='Log in'
                to='/login'
                variant='anchor'
              />
            </footer>
          </Panel>
        </Container>

        <TermsOfService />
      </Main>
    </Page>
  )
})
