import { queryClient } from '@/queryClient'

import { SpotifyClient } from './SpotifyClient'
import { IntegrationPlaylistService } from '..'
import StoreService from '../../StoreService'

export class SpotifyPlaylistService implements IntegrationPlaylistService {
  private client: SpotifyClient

  public constructor(client: SpotifyClient) {
    this.client = client
  }

  get store() {
    return StoreService.getStore()
  }

  get user() {
    return this.store.user
  }

  get spotify() {
    return this.store.spotify
  }

  get queries() {
    return {
      all: {
        key: ['spotify-playlists', this.user.userId, this.spotify.auth.email],
        fn: () => this.client.getPlaylists(),
      },
      byId: {
        key: ['spotify-playlist-id', this.user.userId, this.spotify.auth.email],
        fn: (playlistId: string) => this.client.getPlaylistById(playlistId),
      },
      byUrl: {
        key: [
          'spotify-playlist-url',
          this.user.userId,
          this.spotify.auth.email,
        ],
        fn: (playlistUrl: string) => this.client.getPlaylistByUrl(playlistUrl),
      },
      getTrack: {
        key: (trackId: string) => ['spotify-track', trackId],
        fn: (trackId: string) => this.client.getTrack(trackId),
      },
      searchTracks: {
        key: (query: string) => ['search-spotify-tracks', query],
        fn: (query: string) => this.client.searchTracks(query),
      },
      trackFeatures: {
        key: ['spotify-track-features'],
        fn: (trackId: string) => this.client.getTrackFeatures(trackId),
      },
    } as const
  }

  queryPlaylistById(playlistId: string) {
    return queryClient.fetchQuery([...this.queries.byId.key, playlistId], () =>
      this.queries.byId.fn(playlistId)
    )
  }

  queryPlaylistByUrl(playlistUrl: string) {
    return queryClient.fetchQuery(
      [...this.queries.byUrl.key, playlistUrl],
      () => this.queries.byUrl.fn(playlistUrl)
    )
  }

  queryPlaylists() {
    return queryClient.fetchQuery(this.queries.all.key, this.queries.all.fn)
  }

  queryTrack(query: string) {
    return queryClient.fetchQuery([this.queries.searchTracks.key, query], () =>
      this.queries.searchTracks.fn(query)
    )
  }

  queryTrackFeatures(trackId: string) {
    return queryClient.fetchQuery(
      [...this.queries.trackFeatures.key, trackId],
      () => this.queries.trackFeatures.fn(trackId)
    )
  }
}
