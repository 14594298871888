import { Playlist, Song } from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React from 'react'

import { DraggableList } from './DraggableList'
import { PlaylistTrack } from './PlaylistTrack'

interface ReorderPlaylistProps {
  canDelete?: boolean
  isCompact?: boolean
  onClick: (id: number) => void
  onClickDelete: (id: number) => void
  onUpdate: (values: { id: number }[]) => void
  playlist: Playlist
}

export const ReorderPlaylist = observer(function ReorderPlaylist({
  isCompact = false,
  onClick,
  onClickDelete,
  onUpdate,
  playlist,
}: ReorderPlaylistProps): React.ReactElement {
  const { songs } = playlist

  function mapSongToDraggableListItem(song: Song, index: number) {
    return {
      id: song.id,
      content: (
        <PlaylistTrack
          canReorder={true}
          index={index}
          isCompact={isCompact}
          song={song}
          onClick={onClick}
          onClickDelete={onClickDelete}
        />
      ),
    }
  }

  return (
    <DraggableList
      sourceItems={songs.map(mapSongToDraggableListItem)}
      onClick={onClick}
      onUpdate={onUpdate}
    />
  )
})
