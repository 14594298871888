import * as Sentry from '@sentry/react'
import { Form, Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import * as yup from 'yup'
import { RequiredStringSchema } from 'yup/lib/string'
import { AnyObject } from 'yup/lib/types'

import { getFirebaseAuthErrorMessage } from '@/authentication/utils'
import { Button } from '@/components/Button'

import { FormError } from './FormError'
import { TextField } from './TextField'

export interface PasswordFormValues {
  email: string
  password: string
}

interface PasswordFormProps {
  buttonText: string
  email?: string
  onSubmit: (values: PasswordFormValues) => Promise<unknown>
  passwordValidationSchema?: RequiredStringSchema<string | undefined, AnyObject>
}

export const emailValidationSchema = yup
  .string()
  .email('Please enter a valid email address')
  .required('Please enter a valid email address')

export const PASSWORD_VALIDATION_SCHEMA = yup
  .string()
  .required('Please enter a password')
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
    'Password must contain minimum 8 characters, one uppercase, one lowercase, one number and one special character'
  )

export const PasswordForm = observer(function PasswordForm({
  buttonText,
  email,
  passwordValidationSchema = PASSWORD_VALIDATION_SCHEMA,
  onSubmit,
}: PasswordFormProps): React.ReactElement {
  const [formError, setFormError] = useState<string | undefined>()

  const validationSchema = yup.object({
    email: emailValidationSchema,
    password: passwordValidationSchema,
  })

  const handleSubmit = async (values: PasswordFormValues) => {
    try {
      await onSubmit(values)
    } catch (error) {
      console.error(error)
      Sentry.captureException(error)

      const errorMessage = getFirebaseAuthErrorMessage(error)
      setFormError(errorMessage)
    }
  }

  return (
    <Formik
      initialValues={{
        email: email ?? '',
        password: '',
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, isValid }) => (
        <Form className='flex flex-col gap-8'>
          <div className='flex flex-col gap-6'>
            <TextField label='Email' name='email' type='email' />
            <TextField label='Password' name='password' type='password' />
          </div>

          <Button
            disabled={isSubmitting || !isValid}
            text={buttonText}
            type='submit'
          />

          {formError && <FormError error={formError} />}
        </Form>
      )}
    </Formik>
  )
})
