import { observer } from 'mobx-react-lite'

import { useGameContext } from '@/pages/game/context/game-context'

interface ActiveDeviceWarningProps {
  setShowDeviceSelectorModal: (value: boolean) => void
}
export const ActiveDeviceWarning = observer(function ActiveDeviceWarning({
  setShowDeviceSelectorModal,
}: ActiveDeviceWarningProps) {
  const { tracker } = useGameContext()

  return (
    <div
      className='bg-red-500 text-white flex flex-col gap-2 items-center justify-center p-4 rounded-lg hover:cursor-pointer'
      onClick={() => {
        tracker.spotifyFixDeviceClicked()
        setShowDeviceSelectorModal(true)
      }}
    >
      <span className='text-center'>
        Your Spotify device is not responding.
      </span>{' '}
      <span className='uppercase text-sm font-semibold tracking-wide'>
        Fix Now
      </span>
    </div>
  )
})
