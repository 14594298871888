import { CommonProps, merge } from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'

export const AppFooter = observer(function AppFooter({
  children,
  className,
}: CommonProps) {
  return (
    <footer
      className={merge(
        'sticky w-full bottom-0 self-end py-6 bg-shade-0',
        className
      )}
    >
      {children}
    </footer>
  )
})
