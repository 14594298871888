import { Song } from '@matthewlongpre/music-bingo-common'

import { isAlbumMatch } from './isAlbumMatch'
import { normalizedCompare } from './normalizedCompare'

export function isDuplicateByMetadata(song: Song, playlistSong: Song) {
  const isTitleMatch = normalizedCompare(song.title, playlistSong.title)
  const isArtistMatch = normalizedCompare(song.artist, playlistSong.artist)

  return {
    isTitleAndArtistMatch: isTitleMatch && isArtistMatch,
    isAlbumMatch: isAlbumMatch(song, playlistSong),
  }
}
