import type { FieldInputProps } from 'formik'
import { observer } from 'mobx-react-lite'
import React from 'react'
import styled from 'styled-components'

interface TextareaLabelProps {
  children: React.ReactNode
  id: string
  inputValue: string
  maxLength: number
}

const TextareaLabel = observer(function TextareaLabel({
  children,
  id,
  inputValue,
  maxLength,
}: TextareaLabelProps): React.ReactElement {
  return (
    <TextareaLabelStyled htmlFor={id}>
      <span>{children}</span>
      <span>{`${inputValue.length} / ${maxLength}`}</span>
    </TextareaLabelStyled>
  )
})

const TextareaLabelStyled = styled.label`
  display: flex;
  justify-content: space-between;
`

interface TextareaProps {
  children: React.ReactNode
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  field: FieldInputProps<any>
  id: string
  maxLength: number
}

export const Textarea = observer(function Textarea({
  children,
  id,
  field,
  maxLength,
}: TextareaProps): React.ReactElement {
  return (
    <>
      <TextareaLabel
        id={id}
        inputValue={field.value as string}
        maxLength={maxLength}
      >
        {children}
      </TextareaLabel>
      <TextareaStyled {...field} maxLength={maxLength} />
    </>
  )
})

const TextareaStyled = styled.textarea`
  resize: vertical;
  font-size: 16px;
  padding: 10px;
  min-height: 150px;
`
