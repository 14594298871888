import {
  PlaylistType,
  Container,
  Label,
} from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React from 'react'

import { Playlist } from '@/components/Playlist'
import { Title } from '@/components/Title'

import { useGameContext } from '../../game/context/game-context'

export const PlaylistHistory = observer(
  function PlaylistHistory(): React.ReactElement | null {
    const { gameData } = useGameContext()

    if (gameData.type !== 'music') return null

    const { sourcePlaylistMeta } = gameData

    const { title, type } = sourcePlaylistMeta

    return (
      <>
        <Label>Playlist:</Label>

        <Title description={formatPlaylistType(type)} heading={title} />

        <Container size='large'>
          {gameData.drawnItems && (
            <div className='playlist-container'>
              <Playlist
                enableDimmedPlayedSongs={false}
                enableScrollToSong={false}
              />
            </div>
          )}
        </Container>
      </>
    )
  }
)

function formatPlaylistType(playlistType: PlaylistType) {
  switch (playlistType) {
    case PlaylistType.SYSTEM_PLAYLISTS:
      return 'Provided by Rockstar Bingo'
    case PlaylistType.USER_CUSTOM_PLAYLISTS:
      return 'Custom Playlist'
    case PlaylistType.USER_SPOTIFY_LIBRARY:
      return 'From your Spotify Library'
    case PlaylistType.USER_SPOTIFY_URL:
      return 'From a Spotify URL'
    default:
      return 'Unknown playlist type'
  }
}
