import {
  Collections,
  Game,
  Player,
  Scoreboard,
  Spinner,
} from '@matthewlongpre/music-bingo-common'
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
} from 'firebase/firestore'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useQueries } from 'react-query'

import { databaseRef } from '@/firebase/firebase'
import { GameStore } from '@/store/game/GameStore'
import { toErrorWithMessage } from '@/utils/get-error-message'

import { GameContext } from '../game/context/game-context'

const GAME_QUERY_KEY = 'game-query'
const PLAYERS_QUERY_KEY = 'players-query'
const SCOREBOARD_QUERY_KEY = 'scoreboard-query'

async function fetchGame(gameId: string) {
  const docRef = doc(databaseRef, Collections.GAMES, gameId)
  const docSnapshot = await getDoc(docRef)

  if (!docSnapshot.exists()) return

  return docSnapshot.data() as Game
}

async function fetchPlayers(gameId: string) {
  const playersCollection = collection(
    databaseRef,
    Collections.GAMES,
    gameId,
    Collections.PLAYERS
  )

  const playersQuery = query(playersCollection, orderBy('joinedLobby', 'asc'))

  const playersSnapshot = await getDocs(playersQuery)

  if (playersSnapshot.empty) return []

  return playersSnapshot.docs.map((doc) => doc.data() as Player)
}

async function fetchScoreboard(gameId: string) {
  const scoreboardCollection = collection(
    databaseRef,
    Collections.GAMES,
    gameId,
    Collections.SCOREBOARD
  )

  const scoreboardQuery = query(
    scoreboardCollection,
    orderBy('timestamp', 'desc'),
    limit(1)
  )

  const scoreboardSnapshot = await getDocs(scoreboardQuery)

  if (scoreboardSnapshot.empty) return

  return scoreboardSnapshot.docs[0].data() as Scoreboard
}

interface GameHistoryContextProviderProps {
  children: React.ReactNode
  gameId: string
}

export const GameHistoryContextProvider = observer(
  ({ children, gameId }: GameHistoryContextProviderProps) => {
    const queryResults = useQueries([
      { queryKey: [GAME_QUERY_KEY, gameId], queryFn: () => fetchGame(gameId) },
      {
        queryKey: [PLAYERS_QUERY_KEY, gameId],
        queryFn: () => fetchPlayers(gameId),
      },
      {
        queryKey: [SCOREBOARD_QUERY_KEY, gameId],
        queryFn: () => fetchScoreboard(gameId),
      },
    ])

    const isLoading = queryResults.some((result) => result.isLoading)
    const isError = queryResults.some((result) => result.isError)
    const error = queryResults.find((result) => result.error)?.error

    const gameData = queryResults[0].data
    const players = queryResults[1].data ?? []
    const scores = queryResults[2].data

    if (isLoading) return <Spinner label='Loading game details...' />
    if (isError) return <div>Error: {toErrorWithMessage(error).message}</div>

    if (!gameData) return <div>No game found</div>

    return (
      <GameContext.Provider
        value={createGameStore(gameId, gameData, players, scores)}
      >
        {children}
      </GameContext.Provider>
    )
  }
)

function createGameStore(
  gameId: string,
  gameData: Game,
  players: Player[],
  scores: Scoreboard | undefined
) {
  const gameStore = new GameStore(gameId, gameData)

  gameStore.apply({ players, scores })

  return gameStore
}
