import { Collections } from '@matthewlongpre/music-bingo-common'
import { doc, getDoc } from 'firebase/firestore'

import { databaseRef } from '@/firebase/firebase'

export async function fetchHostIdByCustomUrl(
  customUrl: string
): Promise<string | void> {
  const customJoinSnapshot = await getDoc(
    doc(databaseRef, Collections.CUSTOM_JOIN_SCREEN_URLS, customUrl)
  )

  if (!customJoinSnapshot.exists()) return

  const customJoinDoc = customJoinSnapshot.data() as { hostId: string }

  return customJoinDoc.hostId
}
