import { CommonProps, merge } from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React from 'react'

export const FieldError = observer(function FieldError({
  children,
  className,
}: CommonProps): React.ReactElement {
  return (
    <div
      className={merge(
        'bg-red-500 text-red-100 rounded-md rounded-t-none -mt-1.5 text-xs pt-1.5 pb-1 px-2',
        className
      )}
    >
      {children}
    </div>
  )
})
