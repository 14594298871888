import { Container, Heading, Panel } from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { AppHeader } from '@/components/AppHeader'
import { DisplayGameCode } from '@/components/DisplayGameCode'
import { BackButton } from '@/components/icon-buttons/IconButtons'
import { Main } from '@/components/Main'
import { Page } from '@/components/Page'
import { PageHeading } from '@/components/PageHeading'
import { StaticScoreboard } from '@/components/Scoreboard'
import { Section } from '@/components/Section'

import { formatDate } from './components/format-date'
import { GameLobbyHistory } from './components/game-lobby-history'
import { PlayersHistory } from './components/players-history'
import { PlaylistHistory } from './components/playlist-history'
import { RemovedPlayersHistory } from './components/removed-players-history'
import { VenueDetails } from './components/venue-details'
import { WinnerContactDetailsList } from './components/winner-contact-details-list'
import {
  GameContextProvider,
  useGameContext,
} from '../game/context/game-context'

export const GameDetailsRoute = observer(
  function GameDetailsRoute(): React.ReactElement {
    const { gameId } = useParams<{ gameId: string }>()

    return (
      <GameContextProvider gameId={gameId}>
        <GameDetails />
      </GameContextProvider>
    )
  }
)

const GameDetails = observer(function GameDetails(): React.ReactElement {
  const { gameData, scores } = useGameContext()
  const history = useHistory()

  return (
    <Page title='Game Details'>
      <AppHeader
        actionsLeft={<BackButton onClick={history.goBack} />}
        header={<PageHeading>Game Details</PageHeading>}
      />

      <Main>
        <Container className='mt-6 mb-8' size='large'>
          <Panel>
            <div className='flex gap-2 justify-between'>
              <div className='flex flex-col items-end justify-center overflow-hidden'>
                <Heading>{formatDate(gameData.timestamp)}</Heading>
              </div>
              <DisplayGameCode />
            </div>
          </Panel>
        </Container>

        <Container className='mb-8' size='large'>
          <Heading className='mb-2'>Venue Details</Heading>
          <Panel>
            {gameData.venueId && <VenueDetails venueId={gameData.venueId} />}
            {!gameData.venueId && (
              <div className='text-muted'>No venue details were found.</div>
            )}
          </Panel>
        </Container>

        <Container size='large'>
          <WinnerContactDetailsList />
        </Container>

        {scores && (
          <Container className='my-8 ' size='large'>
            <Panel>
              <StaticScoreboard scores={scores} />
            </Panel>
          </Container>
        )}

        <Container size='large'>
          <Section>
            <PlayersHistory />
          </Section>
        </Container>

        <Container size='large'>
          <Section>
            <GameLobbyHistory />
          </Section>
        </Container>

        <Container size='large'>
          <Section>
            <RemovedPlayersHistory />
          </Section>
        </Container>

        <Container className=' mt-8' size='large'>
          <PlaylistHistory />
        </Container>
      </Main>
    </Page>
  )
})
