import { Heading, ChevronLeftIcon } from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React from 'react'

import { NavigationLink } from './NavigationLink'

interface BannerGameEndedProps {
  isSidekick?: boolean
}

export const BannerGameEnded = observer(function BannerGameEnded({
  isSidekick,
}: BannerGameEndedProps): React.ReactElement {
  return (
    <div className='bg-red-500 text-white p-6 rounded-lg'>
      <Heading>This game has ended.</Heading>

      {!isSidekick && (
        <NavigationLink
          className='flex gap-2 items-center text-white uppercase mt-4'
          to='/'
        >
          <ChevronLeftIcon className='w-4 h-4' />
          Exit Game
        </NavigationLink>
      )}

      {isSidekick && <div>Waiting for the next game to start...</div>}
    </div>
  )
})
