/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Device, PlaybackOption } from '@matthewlongpre/music-bingo-common'
import startCase from 'lodash.startcase'

import { getPlaylistDetails } from './getPlaylistDetails'
import { formatTimestamp } from './utils'
import { GameStore } from '../store/game/GameStore'

// Keep in sync with API

function getMusicGameDetails(game: GameStore) {
  if (game.gameData.type !== 'music') return

  return {
    'Current Song Number': game.gameData.currentItemIndex,
    'Hide Artist': game.gameData.disableArtist,
    ...getPlaylistDetails(game.gameData.sourcePlaylistMeta),
  }
}

function getDeviceDetails(device?: Device) {
  if (!device) return

  return {
    'Playback Player Type': device.isExternal ? 'External' : 'Internal',
    'Playback Device ID': device.id,
    'Playback Device Type': startCase(device.type),
    'Playback Device Name': device.name,
  }
}

function getIntegrationDetails(game: GameStore) {
  if (
    game.gameData.type !== 'music' ||
    game.gameData.playbackSource === PlaybackOption.NONE ||
    !game.integration
  )
    return

  const { integration, gameData } = game

  return {
    Integration: startCase(integration.id),
    'Playback Source': gameData.playbackSource
      ? startCase(gameData.playbackSource)
      : undefined,
    ...getDeviceDetails(integration.devices.activeDevice),
  }
}

function getNumbersGameDetails(game: GameStore) {
  if (game.gameData.type !== 'numbers') return

  return {
    'Current Number': game.gameData.currentItemIndex,
  }
}

export function getGameDetails(game: GameStore) {
  return {
    'Game ID': game.gameId,
    'Game Type': startCase(game.gameData.type),
    'Game Code': game.gameData.gameCode,
    'Game Status': startCase(game.gameData.gameStatus.replace(/_/g, ' ')),
    'Active Players': game.activePlayers.length,
    'Players in Lobby': game.playersInLobby.length,
    'Removed Players': game.removedPlayers.length,
    'Remaining Player Seats': game.remainingPlayerSlots,
    'Players Left Game': game.playersLeftGame.length,
    'Bingo Count': game.gameData.bingoCount,
    'Disable Bingo Verification': game.gameData.disableSystemBingoVerification,
    'Scoreboard Enabled': game.gameData.enableScoreboard,
    'Highlight Current Column': game.gameData.highlightColumns,
    'Host ID': game.gameData.hostId,
    'Host Email': game.gameData.hostEmail,
    'Player Count (Current)': game.gameData.playerCount,
    'Player Count (Total)': game.gameData.playerCountFinal,
    'Request Winner Contact Details': game.gameData.requestContactDetails,
    'Game Branding Call To Action Button Label':
      game.gameData.userSettings?.branding?.callToAction?.button.label,
    'Game Branding Call To Action Button URL':
      game.gameData.userSettings?.branding?.callToAction?.button.url,
    'Game Branding Image Filename':
      game.gameData.userSettings?.branding?.image?.filename,
    'Game Branding Image URL': game.gameData.userSettings?.branding?.image?.url,
    'Venue ID': game.gameData.venueId,
    'Venue Name': game.gameData.venueName,
    'Bingo Target': startCase(game.gameData.currentTarget.replace(/_/g, ' ')),
    'Last Activity': formatTimestamp(game.gameData.lastActivity),
    'Called Bingos Count (Current)': game.calledBingosList.length,
    'Current Column Letter': game.currentColumnName,
    'Game Created': formatTimestamp(game.gameData.timestamp),
    'Game Started': formatTimestamp(game.gameData.gameStarted),
    ...getMusicGameDetails(game),
    ...getNumbersGameDetails(game),
    ...getIntegrationDetails(game),
  }
}
