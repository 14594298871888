import { Panel } from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'

import { List } from '@/components/List'
import { Nav } from '@/components/Nav'
import { Title } from '@/components/Title'
import StoreService from '@/store/StoreService'

import { EnableJumbotronBrandImage } from './EnableJumbotronBrandImage'
import { EnableJumbotronCurrentLetter } from './EnableJumbotronCurrentLetter'
import { EnableJumbotronGameCode } from './EnableJumbotronGameCode'
import { EnableJumbotronPreviousSongs } from './EnableJumbotronPreviousSongs'
import { EnableJumbotronQRCode } from './EnableJumbotronQRCode'
import { EnableJumbotronQRCodeFullscreen } from './EnableJumbotronQRCodeFullscreen'
import { JumbotronLink } from '../JumbotronLink'

export const JumbotronSettingsToggles = observer(
  function JumbotronSettingsToggles() {
    const { currentGame } = StoreService.getStore()

    return (
      <Panel disablePadding>
        <JumbotronLink />

        <Nav>
          <List className='rounded-none'>
            <List.Item
              className='border-x-0 !border-t-0'
              to='/game/settings/jumbotron/video'
              disableRadiusBottom
              disableRadiusTop
              showChevron
            >
              <Title
                description='Update the default videos that will be displayed on the Jumbotron.'
                heading='Video URLs'
              />
            </List.Item>
          </List>
        </Nav>

        <EnableJumbotronBrandImage />

        <EnableJumbotronCurrentLetter />

        <EnableJumbotronGameCode />

        {currentGame?.gameData.type === 'music' && (
          <EnableJumbotronPreviousSongs />
        )}

        <EnableJumbotronQRCode />

        {currentGame?.gameData.jumbotron?.enableQRCode && (
          <EnableJumbotronQRCodeFullscreen />
        )}
      </Panel>
    )
  }
)
