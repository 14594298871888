import {
  BingoCard,
  BingoResult,
  Game,
  Player,
  markPlayerData,
} from '@matthewlongpre/music-bingo-common'
import { Dialog } from '@matthewlongpre/music-bingo-common/client'

interface BingoCardDialogProps {
  gameData: Game
  messageData: Player
  players: Player[]
  result: BingoResult
}

export function BingoCardDialog({
  gameData,
  messageData,
  players,
  result,
}: BingoCardDialogProps) {
  const [playerData] = players.filter(
    (player) => player.playerId === messageData.playerId
  )

  return (
    <Dialog>
      <Dialog.Body>
        <h2 className='justify-center'>
          {result === BingoResult.DENIED && `Bingo DENIED!`}
          {result === BingoResult.CONFIRMED && `Bingo CONFIRMED!`}
        </h2>

        <h2 className='justify-center'>{playerData.playerName} </h2>

        <BingoCard
          currentPlayerHasCalledBingo={false}
          gameData={gameData}
          playerData={markPlayerData(gameData, playerData)}
          onSquareClick={() => undefined}
        />
      </Dialog.Body>
    </Dialog>
  )
}
