import { Badge } from '@matthewlongpre/music-bingo-common'

export function BetaBadge() {
  return (
    <Badge
      className='mr-auto w-auto text-xs sm:text-xs absolute top-2 right-2'
      colour='red'
    >
      Beta
    </Badge>
  )
}
