import {
  PlaybackOption,
  Playlist,
  PlaylistType,
} from '@matthewlongpre/music-bingo-common'

import { ISpotifyPlaylist } from '@/store/integrations/spotify/types'

import { convertSongs } from './convertSongs'

export default function convertPlaylist(
  spotifyPlaylist: ISpotifyPlaylist
): Playlist {
  const songs = spotifyPlaylist.tracks?.items?.length
    ? convertSongs(spotifyPlaylist.tracks.items.map((item) => item.track))
    : []

  return {
    id: spotifyPlaylist.id,
    playbackOptions: [PlaybackOption.SPOTIFY],
    songs,
    title: spotifyPlaylist.name,
    type: PlaylistType.USER_SPOTIFY_LIBRARY,
    image: spotifyPlaylist.images?.[0],
  }
}
