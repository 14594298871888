import { Description, Heading } from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'

import { SpotifyAuthButton } from './SpotifyAuthButton'
import { useQuerySpotifyProfile } from '../queries/useQuerySpotifyProfile'

export const SpotifyConnectionSettings = observer(
  function SpotifyConnectionSettings() {
    const { data: profile } = useQuerySpotifyProfile()

    if (!profile)
      return (
        <div className='flex flex-col gap-6'>
          <div className='flex flex-col gap-2'>
            <Heading>Spotify Connection</Heading>
            <Description>
              Connect a Spotify Premium account to access your playlists and
              manage playback
            </Description>
          </div>

          <SpotifyAuthButton />
        </div>
      )

    return (
      <div className='flex flex-col gap-6'>
        <div className='flex flex-col gap-2'>
          <Heading>Spotify Connection</Heading>
        </div>

        <SpotifyAuthButton disconnectVariant='outlined' showDisconnect />
      </div>
    )
  }
)
