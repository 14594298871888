import {
  Container,
  Description,
  Heading,
  Spinner,
  UserImage,
  merge,
} from '@matthewlongpre/music-bingo-common'
import * as Sentry from '@sentry/react'
import { getDownloadURL, ref } from 'firebase/storage'
import { observer } from 'mobx-react-lite'
import React, { useRef, useState } from 'react'

import { Button } from '@/components/Button'
import { Image } from '@/components/Image'
import { FirebaseFileUploader } from '@/components/image-upload'
import { useDialogContext } from '@/dialogs/DialogContext'
import { storageRef } from '@/firebase/firebase'
import { getErrorMessage } from '@/utils/get-error-message'

const MAX_FILE_SIZE = 1000000 // 1 MB

interface ImageUploadProps {
  initialValue?: UserImage
  isDisabled?: boolean
  onRemoveImage: () => void
  onUploadSuccess: (image: UserImage) => void
  storageFolder: string
}

export const ImageUpload = observer(function ImageUpload({
  initialValue,
  isDisabled = false,
  storageFolder,
  onRemoveImage,
  onUploadSuccess,
}: ImageUploadProps): React.ReactElement {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [isUploading, setIsUploading] = useState(false)
  const [image, setImage] = useState<UserImage | undefined>(initialValue)
  const [isImageLoading, setIsImageLoading] = useState(
    Boolean(initialValue?.url)
  )
  const [imageErrorUrl, setImageErrorUrl] = useState('')

  const { setErrorDialog } = useDialogContext()

  const handleUploadStart = () => {
    setIsUploading(true)
    setIsImageLoading(true)
  }

  const handleUploadError = (error: Error) => {
    setIsUploading(false)
    console.error(error)
    Sentry.captureException(error)

    setErrorDialog({ error: getErrorMessage(error) })
  }

  const handleUploadSuccess = async (filename: string) => {
    setIsUploading(false)

    const imageRef = ref(storageRef, `${storageFolder}/${filename}`)
    const url = await getDownloadURL(imageRef)

    setImage({ filename, url })
    onUploadSuccess({ filename, url })
  }

  const handleImageLoad = () => {
    setIsImageLoading(false)
    setImageErrorUrl('')
  }

  const handleImageError = () => {
    setIsImageLoading(false)
    image?.url && setImageErrorUrl(image.url)
  }

  const handleRemoveImageClick = () => {
    setImage(undefined)
    const fileInput = document.querySelector(
      'input[type=file]'
    ) as HTMLInputElement

    if (fileInput) fileInput.value = ''

    setImageErrorUrl('')
    onRemoveImage()
  }

  const hasImage = Boolean(image?.url)

  const showImage =
    image && !isUploading && !isImageLoading && imageErrorUrl !== image.url

  const showSpinner = isUploading || (image && isImageLoading)

  const showError = imageErrorUrl && !isUploading && !isImageLoading

  const showImageContainer = hasImage || isUploading || isImageLoading

  const showRemoveImageSpacer = hasImage || isUploading || isImageLoading

  return (
    <div className='flex flex-col gap-6'>
      <Container
        className='flex justify-center align-items-center text-center'
        size='medium'
      >
        <div
          className={merge(
            'flex items-center justify-center text-center h-full bg-shade-1 relative',
            {
              'w-full p-6': image || isImageLoading || isUploading,
            }
          )}
        >
          {showSpinner && (
            <Spinner label='Preparing image preview...' position='absolute' />
          )}

          {showError && (
            <div className='absolute text-center'>
              <Heading>An error occurred</Heading>
              <Description>Could not load the image.</Description>
            </div>
          )}

          {showImageContainer && (
            <div
              className={merge(
                'flex items-center justify-center bg-shade-0 rounded-xl shadow-xl overflow-hidden invisible opacity-0 transition-opacity duration-300',
                { 'opacity-100 visible': showImage }
              )}
            >
              <Image
                alt='Logo Preview'
                className='w-60 h-60 object-contain'
                src={image?.url}
                onError={handleImageError}
                onLoad={handleImageLoad}
              />
            </div>
          )}
        </div>
      </Container>

      <Button
        disabled={isDisabled}
        text={hasImage ? `Replace Image` : `Upload Image`}
        variant='outlined'
        onClick={() => inputRef.current?.click()}
      />

      <FirebaseFileUploader
        accept='image/*'
        maxFilesize={MAX_FILE_SIZE}
        name='logo'
        setRef={(node) => {
          inputRef.current = node
        }}
        storageRef={ref(storageRef, storageFolder)}
        hidden
        randomizeFilename
        onUploadError={handleUploadError}
        onUploadStart={handleUploadStart}
        onUploadSuccess={(filename: string) =>
          void handleUploadSuccess(filename).catch((error) => {
            console.error(error)
            Sentry.captureException(error)
          })
        }
      />

      <div style={showRemoveImageSpacer ? { minHeight: 48 } : {}}>
        {hasImage && (
          <Button
            disabled={!hasImage}
            text='Remove Image'
            variant='anchor'
            onClick={handleRemoveImageClick}
          />
        )}
      </div>
    </div>
  )
})
