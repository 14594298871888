import { apiClient } from '@/api/api-client'
import StoreService from '@/store/StoreService'

import { useDeletePlaylist } from '../useDeletePlaylist'

export function useDeleteUserPlaylist() {
  const { playlistService } = StoreService.getStore()

  const mutationFn = (playlistId: string) =>
    apiClient.userDeletePlaylist(playlistId)

  const { queries } = playlistService
  const { queryKey, queryFn } = queries.userPlaylists

  return useDeletePlaylist(mutationFn, queryKey, queryFn)
}
