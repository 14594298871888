import { Container } from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React from 'react'

interface IAppHeaderProps {
  actionsLeft?: React.ReactElement
  actionsRight?: React.ReactElement
  header?: React.ReactElement
  secondaryHeader?: React.ReactElement
  setRef?: (node: HTMLElement | null) => void
}

export const AppHeader = observer(function AppHeader({
  actionsLeft,
  actionsRight,
  header,
  secondaryHeader,
  setRef,
}: IAppHeaderProps): React.ReactElement {
  const showActions = actionsLeft || actionsRight

  return (
    <header
      className='sticky top-0 z-50 bg-shade-1 flex items-center border-b border-shade-3'
      ref={setRef}
    >
      <Container className='py-0 px-4' size='extra-large'>
        <div className='flex gap-1 items-center justify-center w-full h-16'>
          {showActions && (
            <div className='flex items-center justify-start'>{actionsLeft}</div>
          )}

          <div className='flex items-center h-16 md:justify-center md:w-2/3 md:absolute md:max-w-sm md:text-center flex-1 overflow-hidden'>
            {header}
          </div>

          {showActions && (
            <div className='flex gap-1 items-center justify-end justify-items-end ml-auto'>
              {actionsRight}
            </div>
          )}
        </div>

        {secondaryHeader}
      </Container>
    </header>
  )
})
