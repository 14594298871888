import { merge } from '@matthewlongpre/music-bingo-common'

import { Image } from '@/components/Image'

export function Avatar({
  imageUrl,
  className,
}: {
  className?: string
  imageUrl?: string
}) {
  return (
    <div
      className={merge(
        'h-8 w-8 rounded-full overflow-hidden shrink-0',
        className
      )}
    >
      <Image alt='Spotify Avatar' src={imageUrl} />{' '}
    </div>
  )
}
