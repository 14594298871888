export const KEYS = {
  accessToken: 'spotify_token',
  authRedirectUrl: 'spotify_auth_redirect_url',
  refreshToken: 'spotify_refresh_token',
  state: 'spotify_auth_state',
  email: 'spotify_email',
} as const

type KeyValues = (typeof KEYS)[keyof typeof KEYS]

export function getItem(key: KeyValues) {
  return localStorage.getItem(key) ?? undefined
}

export function setItem(key: KeyValues, value: string) {
  return localStorage.setItem(key, value)
}

export function removeItem(key: KeyValues) {
  return localStorage.removeItem(key)
}
