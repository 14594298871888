import { Container } from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'

import { Logo } from './Logo'

export const LogoHeader = observer(function LogoHeader() {
  return (
    <Container className='p-6 md:p-8 lg:p-12 flex justify-center' size='small'>
      <Logo />
    </Container>
  )
})
