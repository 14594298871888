import { Label } from '@matthewlongpre/music-bingo-common'
import 'firebase/auth'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import styled from 'styled-components'

import { BrandImage } from '@/components/BrandImage'
import { DisplayGameCode } from '@/components/DisplayGameCode'
import { ColumnLabelStyled, Playlist } from '@/components/Playlist'
import { JumbotronVideo } from '@/components/video/JumbotronVideo'

import { DisplayQrCode } from './components/DisplayQrCode'
import { AnonymousGameRoute } from '../context/anonymous-game-route'
import { useGameContext } from '../context/game-context'
import { NumbersBoard } from '../numbers/NumbersBoard'

export const JumbotronRoute = observer(
  function JumbotronRoute(): React.ReactElement {
    return (
      <AnonymousGameRoute>
        <Helmet>
          <title>Jumbotron | Rockstar Bingo</title>
        </Helmet>
        <Jumbotron />
      </AnonymousGameRoute>
    )
  }
)

const Jumbotron = observer(function Jumbotron(): React.ReactElement {
  const { gameData } = useGameContext()

  useEffect(() => {
    document.documentElement.classList.remove('light')
    document.documentElement.classList.add('dark')
  }, [])

  const {
    enableBrandImage,
    enableCurrentLetter,
    enableGameCode,
    enablePreviousSongs,
    enableQRCode,
    enableQRCodeFullscreen,
  } = gameData.jumbotron

  const showCurrentLetter =
    enableCurrentLetter && gameData.currentItemIndex !== null

  const showPreviousSongs =
    Boolean(gameData.currentItemIndex) && enablePreviousSongs

  const showOverlay = [
    enableBrandImage,
    enableGameCode,
    enableQRCode,
    showCurrentLetter,
    showPreviousSongs,
  ].some((setting) => setting === true)

  const hideGameInfo = enableQRCode && enableQRCodeFullscreen

  const showGameInfo =
    [enableBrandImage, enableGameCode, enableQRCode, showCurrentLetter].some(
      (setting) => setting === true
    ) && !hideGameInfo

  if (gameData.type === 'numbers') {
    return (
      <>
        {showGameInfo && (
          <div className='flex w-full gap-8 items-center justify-between overflow-hidden p-8'>
            <div className='w-32'>{enableBrandImage && <BrandImage />}</div>

            {enableGameCode && (
              <div className='flex items-center justify-center mx-auto sm:ml-auto lg:mr-0'>
                <DisplayGameCode />
              </div>
            )}

            {enableQRCode && (
              <div>
                <DisplayQrCode className='m-auto rounded-xl' />
              </div>
            )}
          </div>
        )}

        {enableQRCode && enableQRCodeFullscreen ? (
          <DisplayQrCode isFullscreen />
        ) : (
          <NumbersBoard />
        )}
      </>
    )
  }

  return (
    <VideoContainerStyled>
      <JumbotronVideo />

      {showOverlay && <OverlayStyled />}

      {showGameInfo && (
        <ContentStyled className='game-info-container'>
          {enableBrandImage && <BrandImage className='m-auto mb-6' />}
          {enableGameCode && <DisplayGameCode />}
          {enableQRCode && (
            <DisplayQrCode className='mt-6 mx-auto rounded-xl' />
          )}
          {showCurrentLetter && <CurrentColumnName />}
        </ContentStyled>
      )}

      {showPreviousSongs && (
        <ContentStyled className='playlist-container'>
          <PreviousSongs />
        </ContentStyled>
      )}

      {enableQRCode && enableQRCodeFullscreen && <DisplayQrCode isFullscreen />}
    </VideoContainerStyled>
  )
})

const PreviousSongs = observer(function PreviousSongs(): React.ReactElement {
  return (
    <>
      <h2>Last 3 Played</h2>

      <Playlist
        enableDimmedPlayedSongs={false}
        enableRowAudioFeatures={false}
        scrollContainer='element'
        scrollToSong='next'
        showFullPlaylist={false}
      />
    </>
  )
})

export const CurrentColumnName = observer(
  function CurrentColumnName(): React.ReactElement {
    const { currentColumnName } = useGameContext()

    return (
      <CurrentColumnNameStyled>
        <Label className='justify-center'>Current Column</Label>
        <ColumnLabel>{currentColumnName}</ColumnLabel>
      </CurrentColumnNameStyled>
    )
  }
)

const CurrentColumnNameStyled = styled.div`
  margin-top: 1em;
`

const ColumnLabel = styled(ColumnLabelStyled)`
  position: relative;
  background: var(--orange-500);
  color: #181818;
  padding: 0.5em;
  font-size: 2em;
  margin: 0.1em auto 0;
  max-width: 120px;
`

const VideoContainerStyled = styled.div`
  width: 100vw;
  height: 100vh;

  iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100vw;
    height: 100vh;
    transform: translate(-50%, -50%);
    border: 0;
  }
`

const ContentStyled = styled.div`
  position: absolute;
  font-size: 24px;
  color: var(--text);
  background: rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  overflow: hidden;
  width: 100%;
  max-width: 10vw;
  min-width: 200px;

  &.game-info-container {
    top: 10vh;
    right: 6vw;
    padding: 40px 32px;
    text-align: center;

    > * {
      position: relative;
    }
  }

  &.playlist-container {
    left: 6vw;
    bottom: 4vh;
    font-size: 14px;
    padding: 20px;
    max-width: 25vw;

    h2 {
      font-size: 1.5em;
      position: relative;
      margin: 0 0 10px;
      padding: 0.5em;
    }

    .playlist {
      padding-bottom: 0;
    }
  }

  &:before {
    content: '';
    filter: blur(12px);
    width: 100%;
    height: 100%;
    flex: 1;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
  }
`

const OverlayStyled = styled.div`
  width: 100vw;
  height: 100vh;
  mix-blend-mode: darken;
  background-color: rgba(0, 0, 0, 0.33);
`
