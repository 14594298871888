import debounce from 'lodash.debounce'
import { useCallback, useEffect, useLayoutEffect, useMemo } from 'react'

type Id = string | number

export function useScrollIntoView({
  targetId,
  items,
  options = { behavior: 'smooth', block: 'nearest' },
}: {
  items: { id: Id }[]
  options?: ScrollIntoViewOptions
  targetId?: Id
}) {
  const ids = useMemo(() => new Set(items.map(({ id }) => id)), [items])

  const itemMap = useMemo(() => {
    const map = new Map<Id, HTMLElement | null>()
    ids.forEach((id) => map.set(id, null))
    return map
  }, [ids])

  const scrollIntoView = useCallback(
    (targetId: Id) => {
      const ref = itemMap.get(targetId)
      if (!ref) return

      requestAnimationFrame(() => ref.scrollIntoView(options))
    },
    [itemMap, options]
  )

  const setRef = useCallback(
    (id: Id, ref: HTMLElement | null) => {
      itemMap.set(id, ref)
    },
    [itemMap]
  )

  useLayoutEffect(() => {
    if (!targetId) return

    scrollIntoView(targetId)
  }, [items, itemMap, options, scrollIntoView, targetId])

  useEffect(() => {
    if (!targetId) return

    const handleResize = debounce(() => {
      scrollIntoView(targetId)
    }, 250)

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [targetId, items, scrollIntoView])

  return {
    setRef,
    scrollIntoView,
  }
}
