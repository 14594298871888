import { Collections, User } from '@matthewlongpre/music-bingo-common'
import { doc, setDoc } from 'firebase/firestore'
import startCase from 'lodash.startcase'

import { databaseRef } from '@/firebase/firebase'

import { MixpanelService } from '../../mixpanel'


export async function createUserAccount(user: User): Promise<void> {
  const { userId } = user

  const data = { userId }

  await setDoc(doc(databaseRef, Collections.USERS, userId), data)

  MixpanelService.alias(user)
  MixpanelService.identify(user)

  MixpanelService.track('User Account Created', {
    'Sign-in Provider': startCase(user.signInProvider),
    'Plan Type': startCase(user.planType),
    'Max Players': user.maxPlayers,
  })

  MixpanelService.setPeopleProperties(user)
}
