import {
  AuthProvider,
  ButtonGroup,
  Heading,
} from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'

import { createPortalLink } from '@/billing'
import { Button } from '@/components/Button'
import { Logout } from '@/components/Logout'
import { PlanRestrictions } from '@/components/PlanRestrictions'
import { PlanTypeBadge } from '@/components/PlanTypeBadge'
import { MixpanelService } from '@/mixpanel'
import { PRICING_URL } from '@/pages/account/redirect-pricing'
import StoreService from '@/store/StoreService'

export const AccountSettings = observer(
  function AccountSettings(): React.ReactElement | null {
    const { user } = StoreService.getStore()

    return (
      <>
        <Heading className='mb-2'>Account</Heading>

        <p>
          <UserDisplayName />
        </p>

        <p className='mt-4 text-muted'>
          <PlanRestrictions />
        </p>

        <ButtonGroup className='mt-6' direction='column'>
          {user.canManageSubscription && <AccountButton />}

          <Logout />
        </ButtonGroup>
      </>
    )
  }
)

const UserDisplayName = observer(() => {
  const { user } = StoreService.getStore()

  const spotifyUsername = `${user.userId.replace('spotify:', '')} via Spotify`

  const userDisplayName =
    user.signInProvider === AuthProvider.SPOTIFY ? spotifyUsername : user.email

  return (
    <>
      {userDisplayName} <PlanTypeBadge className='ml-2' />
    </>
  )
})

export const ManageSubscriptionButton = observer(() => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleBillingPortalClick = async () => {
    setIsSubmitting(true)

    await createPortalLink(() => {
      MixpanelService.openCustomerPortal()
    })
  }

  return (
    <Button
      disabled={isSubmitting}
      isLoading={isSubmitting}
      text='Manage Subscription'
      onClick={handleBillingPortalClick}
    />
  )
})

const AccountButton = observer(() => {
  const { user } = StoreService.getStore()

  if (user.hasSubscription) {
    return <ManageSubscriptionButton />
  }

  return <Button target='_blank' text='Upgrade Account' to={PRICING_URL} />
})
