import { Panel, Container } from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { AppHeader } from '@/components/AppHeader'
import { BackButton, CloseButton } from '@/components/icon-buttons/IconButtons'
import { Main } from '@/components/Main'
import { Page } from '@/components/Page'
import { PageHeading } from '@/components/PageHeading'
import { useGameContext } from '@/pages/game/context/game-context'
import { BingoTargetSettings } from '@/pages/settings/components/BingoTargetSettings'
import { DisableBingoVerification } from '@/pages/settings/components/DisableBingoVerification'
import { EnableScoreboard } from '@/pages/settings/components/EnableScoreboard'
import { HideArtist } from '@/pages/settings/components/HideArtist'
import { PlayerCardHighlighting } from '@/pages/settings/components/PlayerCardHighlighting'
import { RequestContactDetailsSettings } from '@/pages/settings/components/RequestContactDetailsSettings'

export const GameplaySettingsRoute = observer(
  function GameplaySettingsRoute(): React.ReactElement {
    return <GameplaySettingsPage />
  }
)

const GameplaySettingsPage = observer(
  function GameplaySettingsPage(): React.ReactElement {
    const { gameData, isStartedGame } = useGameContext()

    const history = useHistory()

    const handleClickBack = () => {
      history.goBack()
    }

    const handleClickClose = () => {
      history.push('/game')
    }

    return (
      <Page title='Gameplay Settings'>
        <AppHeader
          actionsLeft={<BackButton onClick={handleClickBack} />}
          actionsRight={<CloseButton onClick={handleClickClose} />}
          header={<PageHeading>Gameplay Settings</PageHeading>}
        />

        <Main>
          <Container size='medium'>
            <Panel disablePadding>
              <DisableBingoVerification />

              {!gameData.disableSystemBingoVerification && (
                <BingoTargetSettings
                  disableConfirmPrompt={!isStartedGame}
                  disableMessageQueue={!isStartedGame}
                />
              )}

              <PlayerCardHighlighting />

              {gameData.type == 'music' && <HideArtist />}

              <EnableScoreboard />

              <RequestContactDetailsSettings />
            </Panel>
          </Container>
        </Main>
      </Page>
    )
  }
)
