import {
  Collections,
  Container,
  Panel,
  Spinner,
} from '@matthewlongpre/music-bingo-common'
import { collection, getDocs } from 'firebase/firestore'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'

import { AppHeader } from '@/components/AppHeader'
import { Button } from '@/components/Button'
import { BackButton, CloseButton } from '@/components/icon-buttons/IconButtons'
import { List } from '@/components/List'
import { Main } from '@/components/Main'
import { Page } from '@/components/Page'
import { PageHeading } from '@/components/PageHeading'
import { Title } from '@/components/Title'
import { databaseRef } from '@/firebase/firebase'
import { VenueFormValues } from '@/pages/settings/components/VenueForm'
import StoreService from '@/store/StoreService'

export const VenueListRoute = observer(
  function VenueListRoute(): React.ReactElement {
    return <VenueListPage />
  }
)

const VenueListPage = observer(function VenueListPage(): React.ReactElement {
  const history = useHistory()

  const handleClickBack = () => {
    history.goBack()
  }

  const handleClickClose = () => {
    history.push('/')
  }

  return (
    <Page title='Venue List'>
      <AppHeader
        actionsLeft={<BackButton onClick={handleClickBack} />}
        actionsRight={<CloseButton onClick={handleClickClose} />}
        header={<PageHeading>Venue List</PageHeading>}
        secondaryHeader={
          <div className='p-6 px-0'>
            <Container className='px-0' size='medium'>
              <Button text='Create Venue' to='venues/create' />
            </Container>
          </div>
        }
      />

      <Main>
        <Container className='flex flex-col gap-8' size='medium'>
          <VenueListContainer />
        </Container>
      </Main>
    </Page>
  )
})

export const VENUES_QUERY_KEY = 'venue-list'

export interface Venue extends VenueFormValues {
  id: string
}

export async function fetchVenues(userId: string): Promise<Venue[]> {
  const collectionRef = collection(
    databaseRef,
    Collections.USERS,
    userId,
    Collections.VENUES
  )

  const docSnapshot = await getDocs(collectionRef)

  if (docSnapshot.empty) return []

  return docSnapshot.docs.map((docSnapshot) => {
    return {
      id: docSnapshot.id,
      ...docSnapshot.data(),
    }
  }) as Venue[]
}

const VenueListContainer = observer(function VenueFormContainer() {
  const { user } = StoreService.getStore()

  const { data, isLoading, isError } = useQuery<Venue[], Error>(
    [VENUES_QUERY_KEY],
    () => fetchVenues(user.userId)
  )

  if (isLoading) return <Spinner label='Loading venue details...' />

  if (isError || !data) {
    return <Panel>There was a problem loading your venues.</Panel>
  }

  if (!data.length) {
    return (
      <Panel className='text-center text-muted'>
        You haven{`'`}t added any venues.
      </Panel>
    )
  }

  return (
    <List>
      {data.map((venue) => (
        <List.Item key={venue.id} to={`venues/${venue.id}`}>
          <Title heading={venue.name} />
        </List.Item>
      ))}
    </List>
  )
})
