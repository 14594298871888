import { merge, ExternalIcon } from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React from 'react'

import { ExternalLinkStyled } from './StyledComponents'
import { MixpanelService } from '../mixpanel'

interface ExternalLinkProps {
  children: React.ReactNode
  className?: string
  href: string
}

export const ExternalLink = observer(function ExternalLink({
  href,
  children,
  className,
}: ExternalLinkProps): React.ReactElement {
  const handleClick = () => {
    MixpanelService.track('External Link Clicked', { URL: href })
  }

  return (
    <ExternalLinkStyled
      className={merge('inline-flex items-center', className)}
      href={href}
      target='_blank'
      onClick={handleClick}
    >
      {children}
      <ExternalIcon />
    </ExternalLinkStyled>
  )
})
