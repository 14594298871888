import { Description, Spinner } from '@matthewlongpre/music-bingo-common'

import { CardGridCategory } from '@/components/CardGridCategory'

import { useQuerySystemPlaylists } from '../../playlists/playlist-queries/system-playlists/useQuerySystemPlaylists'

interface SystemPlaylistsProps {
  onPlaylistClick: (playlistId: string) => void
}

export function SystemPlaylists({ onPlaylistClick }: SystemPlaylistsProps) {
  const { isLoading, data = [] } = useQuerySystemPlaylists()

  if (isLoading) return <Spinner label='Loading our playlists...' />

  return (
    <CardGridCategory
      description={
        <Description>Try one of our suggested playlists.</Description>
      }
      handleClick={onPlaylistClick}
      heading='Our Playlists'
      items={data}
    />
  )
}
