import {
  CreateGameOptions,
  Pattern,
  Player,
  Playlist,
  Song,
  Square,
} from '@matthewlongpre/music-bingo-common'
import { getAuth } from 'firebase/auth'

import { firebaseApp } from '@/firebase/firebase'

import { apiUrl } from '../utils/constants'

enum FetchMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
}

class APIClient {
  public async send<T>(
    endpoint: string,
    options?: {
      body?: { [key: string]: unknown }
      method?: FetchMethod
    }
  ): Promise<T> {
    const auth = getAuth(firebaseApp)
    const token = await auth.currentUser?.getIdToken()

    if (!token) {
      throw new Error('Invalid auth token')
    }

    const method = options?.method || FetchMethod.POST
    const body = options?.body

    const response = await fetch(`${apiUrl}${endpoint}`, {
      method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `BEARER ${token}`,
      },
      body: body ? JSON.stringify(body) : null,
    })

    let output

    if (response.status === 200) {
      const data = (await response.json()) as T
      if (data) {
        output = data
      }
    }

    if (!response.ok) {
      if (response.status === 401) {
        throw new Error('Unauthorized')
      }

      const error = (await response.json()) as {
        message: Error
        status: number
      }
      throw error
    }

    return output as T
  }

  public createGame(options: CreateGameOptions): Promise<{ gameId: string }> {
    return this.send('/create-game', { body: { ...options } })
  }

  public startGame(gameId: string): Promise<Response> {
    return this.send('/start-game', { body: { gameId } })
  }

  public finishGame(gameId: string): Promise<Response> {
    return this.send('/finish-game', { body: { gameId } })
  }

  public nextSong(gameId: string): Promise<number> {
    return this.send('/next-song', { body: { gameId } })
  }

  public prevSong(gameId: string): Promise<number | null> {
    return this.send('/prev-song', { body: { gameId } })
  }

  public nextItem(gameId: string): Promise<number> {
    return this.send('/next-item', { body: { gameId } })
  }

  public updateTarget(
    gameId: string,
    target: Pattern,
    disableMessageQueue: boolean
  ): Promise<Response> {
    return this.send('/update-target', {
      body: { gameId, target, disableMessageQueue },
    })
  }

  public admitPlayers(
    gameId: string,
    playerIdsInLobby: string[]
  ): Promise<Response> {
    return this.send('/admit-players', { body: { gameId, playerIdsInLobby } })
  }

  public admitAllPlayers(gameId: string): Promise<Response> {
    return this.send('/admit-all-players', { body: { gameId } })
  }

  public removePlayers(
    gameId: string,
    playerIdsToRemove: string[]
  ): Promise<Response> {
    return this.send('/remove-players', {
      body: { gameId, playerIdsToRemove },
    })
  }

  public readmitPlayers(
    gameId: string,
    playerIdsToReadmit: string[]
  ): Promise<Response> {
    return this.send('/readmit-players', {
      body: { gameId, playerIdsToReadmit },
    })
  }

  public verifyBingo(
    gameId: string,
    playerId: string
  ): Promise<{ bingoConfirmed: boolean }> {
    return this.send('/verify-bingo', { body: { gameId, playerId } })
  }

  public confirmBingo(
    gameId: string,
    markedPlayer: Player,
    correctLines: { [key: number]: Square[] } = {}
  ): Promise<{ bingoConfirmed: boolean }> {
    return this.send('/confirm-bingo', {
      body: { gameId, markedPlayer, correctLines },
    })
  }

  public denyBingo(
    gameId: string,
    playerId: string
  ): Promise<{ bingoConfirmed: boolean }> {
    return this.send('/deny-bingo', { body: { gameId, playerId } })
  }

  public drawAllSongs(
    gameId: string,
    playlistTracks: Song[]
  ): Promise<Response> {
    return this.send('/draw-all-songs', {
      body: {
        gameId,
        playlistTracks,
      },
    })
  }

  public deleteGame(gameId: string): Promise<Response> {
    return this.send('/delete-game', { body: { gameId } })
  }

  public deleteAllGames(): Promise<Response> {
    return this.send('/delete-all-games')
  }

  public userUpdatePlaylist(
    options: Playlist
  ): Promise<{ playlistId: string }> {
    return this.send('/update-playlist', {
      body: {
        ...options,
      },
    })
  }

  public userDeletePlaylist(playlistId: string): Promise<Response> {
    return this.send('/delete-playlist', { body: { playlistId } })
  }

  public adminUpdateSystemPlaylist(
    options: Playlist
  ): Promise<{ playlistId: string }> {
    return this.send('/admin/update-system-playlist', {
      body: {
        ...options,
      },
    })
  }

  public adminDeleteSystemPlaylist(playlistId: string): Promise<Response> {
    return this.send('/admin/delete-system-playlist', { body: { playlistId } })
  }

  public getLyrics(title: string, artist: string): Promise<string> {
    return this.send('/get-lyrics', { body: { title, artist } })
  }

  public clonePlaylists(): Promise<Response> {
    return this.send('/clone-playlists')
  }
}

export const apiClient = new APIClient()
