import {
  Button,
  CloseIcon,
  Heading,
  IconButton,
  List,
  PlaybackOption,
  Playlist,
  Song,
  TextInput,
  merge,
} from '@matthewlongpre/music-bingo-common'
import { ChangeEventHandler, useDeferredValue, useState } from 'react'

import { useDialogContext } from '@/dialogs/DialogContext'
import { SpotifyAuthButton } from '@/store/integrations/spotify/components/SpotifyAuthButton'
import { addFeaturesToSong } from '@/store/integrations/spotify/converters/convertSong'
import { useSpotify } from '@/store/integrations/spotify/useSpotify'

import { checkDuplicates } from './duplicate-checker/checkDuplicates'
import { useQuerySpotifyTracks } from '../../../store/integrations/spotify/queries/useQuerySpotifyTracks'

interface AddTrackProps {
  onClickAdd: (song: Song) => void
  playlist: Playlist
}

export function AddMoreSongs({ playlist, onClickAdd }: AddTrackProps) {
  const { auth } = useSpotify()

  return (
    <div className='flex flex-col gap-4 pt-6'>
      <Heading>Add More Songs</Heading>

      {auth.isAuthenticated ? (
        <SearchSpotify playlist={playlist} onClickAdd={onClickAdd} />
      ) : (
        <div className='mr-auto'>
          <SpotifyAuthButton />
        </div>
      )}
    </div>
  )
}

interface SearchSpotifyProps {
  onClickAdd: (song: Song) => void
  playlist: Playlist
}

function SearchSpotify({ playlist, onClickAdd }: SearchSpotifyProps) {
  const { playlistService } = useSpotify()

  const [query, setQuery] = useState('')
  const deferredQuery = useDeferredValue(query)
  const { setErrorDialog, setConfirmPrompt } = useDialogContext()
  const { data = [] } = useQuerySpotifyTracks(deferredQuery)

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setQuery(event.target.value)
  }

  const handleClickClear = () => {
    setQuery('')
  }

  const addSong = async (song: Song) => {
    if (!song.spotifyMeta) return

    const features = await playlistService.queryTrackFeatures(
      song.spotifyMeta.track.id
    )

    const updatedSong = addFeaturesToSong(song, features)

    onClickAdd(updatedSong)

    setQuery('')
  }

  const handleClickAdd = async (song: Song) => {
    if (!song.spotifyMeta) return

    const result = checkDuplicates(song, playlist.songs, {
      isSpotifyCompatible: playlist.playbackOptions.includes(
        PlaybackOption.SPOTIFY
      ),
    })

    if (result.status === 'error') {
      setErrorDialog({
        title: result.title,
        error: result.message,
      })
      return
    }

    if (result.status === 'warning') {
      setConfirmPrompt({
        heading: result.title,
        description: result.message,
        onConfirm: () => addSong(song),
      })
      return
    }

    addSong(song)
  }

  return (
    <div className='flex flex-col gap-4'>
      <div className='flex w-full items-center gap-2'>
        <div className='flex w-full max-w-md flex-col gap-2'>
          <TextInput
            label='Search Spotify'
            name='song-query'
            value={query}
            onChange={handleChange}
          />
        </div>
        <div
          className={merge(
            { 'opacity-0 pointer-events-none': !query },
            'relative -left-12 flex items-center mt-8 dark:bg-shade-2 bg-shade-3 transition-opacity'
          )}
        >
          <IconButton size='small' title='Clear' onClick={handleClickClear}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <Results data={data} handleClickAdd={handleClickAdd} />
    </div>
  )
}

interface ResultsProps {
  data: Song[]
  handleClickAdd: (song: Song) => void
}

function Results({ data, handleClickAdd }: ResultsProps) {
  return (
    <>
      {Boolean(data.length) && (
        <List>
          {data.map((song) => (
            <List.Item
              actions={
                <>
                  <Button text='Add' onClick={() => handleClickAdd(song)} />
                </>
              }
              className='flex items-center justify-between'
              key={song.id}
            >
              <div className='w-full flex flex-col justify-center gap-0.5 overflow-hidden'>
                <span className='leading-tight text-ellipsis overflow-hidden whitespace-nowrap'>
                  {song.title}
                </span>
                <span className='uppercase font-semibold tracking-tight leading-tight text-muted text-sm text-ellipsis overflow-hidden whitespace-nowrap'>
                  {song.artist}
                </span>
                <span className='tracking-wide leading-tight text-muted text-xs text-ellipsis overflow-hidden whitespace-nowrap'>
                  {song?.album}
                </span>
              </div>
            </List.Item>
          ))}
        </List>
      )}
    </>
  )
}
