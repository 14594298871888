import { Description, Heading, merge } from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React from 'react'

import { CopyLink } from '@/components/CopyLink'
import { useGameContext } from '@/pages/game/context/game-context'

export const JumbotronLink = observer(
  function JumbotronLink(): React.ReactElement {
    const { hostUrlSlug, tracker } = useGameContext()

    const handleClickCopy = () => {
      tracker.copyLinkJumbotron()
    }

    return (
      <div
        className={merge('p-4 md:p-6 border-b last:border-b-0 border-shade-3')}
      >
        <div className='flex flex-col md:flex-row gap-4 justify-between'>
          <div className='flex flex-col gap-2'>
            <Heading>Jumbotron View</Heading>
            <Description>
              If you have configured video URLs in a custom playlist, use this
              link to display them on a video screen.
            </Description>
          </div>

          <div className='flex items-center'>
            <CopyLink
              buttonVariant='outlined'
              link={`${window.location.origin}/${hostUrlSlug}/jumbotron`}
              text='Copy Link'
              hideInput
              onClickCopy={handleClickCopy}
            />
          </div>
        </div>
      </div>
    )
  }
)
