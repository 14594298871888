import {
  CommonProps,
  Description,
  Heading,
} from '@matthewlongpre/music-bingo-common'
import {
  ConfirmPrompt,
  ErrorDialog,
} from '@matthewlongpre/music-bingo-common/client'
import { createContext, useContext, useState } from 'react'

export const DialogContext = createContext({} as DialogContext)

interface ConfirmPromptProps {
  description?: React.ReactNode
  heading: React.ReactNode
  onConfirm: () => void
}

interface ErrorDialogProps {
  error: React.ReactNode
  onContinue?: () => void
  title?: React.ReactNode
}

interface DialogContext {
  confirmPrompt?: ConfirmPromptProps
  errorDialog?: ErrorDialogProps
  setConfirmPrompt: React.Dispatch<
    React.SetStateAction<ConfirmPromptProps | undefined>
  >
  setErrorDialog: React.Dispatch<
    React.SetStateAction<ErrorDialogProps | undefined>
  >
}

export function useDialogContext(): DialogContext {
  const context = useContext(DialogContext)

  if (context === undefined) {
    throw new Error(
      'useDialogContext must be used within a DialogContextProvider'
    )
  }

  return context
}

export function DialogContextProvider({ children }: CommonProps) {
  const [confirmPrompt, setConfirmPrompt] = useState<ConfirmPromptProps>()
  const [errorDialog, setErrorDialog] = useState<ErrorDialogProps>()

  const value: DialogContext = {
    confirmPrompt,
    setConfirmPrompt,
    errorDialog,
    setErrorDialog,
  }

  return (
    <DialogContext.Provider value={value}>
      <ConfirmPrompt
        isVisible={Boolean(confirmPrompt)}
        onClose={() => setConfirmPrompt(undefined)}
        onConfirm={
          confirmPrompt?.onConfirm ? confirmPrompt.onConfirm : () => undefined
        }
      >
        <Heading disableTruncation>{confirmPrompt?.heading}</Heading>

        {confirmPrompt?.description && (
          <Description className='mt-2'>
            {confirmPrompt.description}
          </Description>
        )}
      </ConfirmPrompt>

      <ErrorDialog
        isVisible={Boolean(errorDialog)}
        onClose={() => setErrorDialog(undefined)}
        onContinue={errorDialog?.onContinue}
      >
        <Heading disableTruncation>
          {errorDialog?.title ?? 'Sorry, something went wrong'}
        </Heading>
        <Description className='mt-2'>{errorDialog?.error}</Description>
      </ErrorDialog>

      {children}
    </DialogContext.Provider>
  )
}
