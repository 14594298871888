import { Container, Spinner } from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'

import { ActiveDeviceWarning } from './ActiveDeviceWarning'
import { PlaybackControlsExternal } from './PlaybackControlsExternal'
import { PlaybackControlsInternal } from './PlaybackControlsInternal'
import { PlaybackSyncWarning } from './PlaybackSyncWarning'
import { PlayingInAnotherTabWarning } from './PlayingInAnotherTabWarning'
import { useQuerySpotifyDevices } from '../../queries/useQuerySpotifyDevices'
import { useSpotify } from '../../useSpotify'
import { SpotifyAuthButton } from '../SpotifyAuthButton'

interface PlaybackControlsProps {
  setShowDeviceSelectorModal: (value: boolean) => void
}

export const PlaybackControls = observer(function PlaybackControls({
  setShowDeviceSelectorModal,
}: PlaybackControlsProps) {
  const { auth, devices, deviceService, playerService, player } = useSpotify()
  const { isLoading: isLoadingDevices } = useQuerySpotifyDevices()

  const { activeDevice, isInternalPlayerInAnotherTab } = devices

  if (!auth.isAuthenticated) {
    return (
      <Container size='small'>
        <SpotifyAuthButton />
      </Container>
    )
  }

  if (
    (playerService.isEnabled && !playerService.isReady) ||
    !deviceService.isReady ||
    isLoadingDevices ||
    deviceService.isActivatingDevice
  ) {
    return <Spinner />
  }

  if (!activeDevice) {
    return (
      <ActiveDeviceWarning
        setShowDeviceSelectorModal={setShowDeviceSelectorModal}
      />
    )
  }

  if (isInternalPlayerInAnotherTab) {
    return <PlayingInAnotherTabWarning />
  }

  if (player.isInternalPlayerOutOfSync) {
    return <PlaybackSyncWarning />
  }

  if (activeDevice?.isExternal) {
    return (
      <PlaybackControlsExternal
        setShowDeviceSelectorModal={setShowDeviceSelectorModal}
      />
    )
  }

  return (
    <PlaybackControlsInternal
      setShowDeviceSelectorModal={setShowDeviceSelectorModal}
    />
  )
})
