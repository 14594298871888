import { Game, Badge, Heading } from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React from 'react'

import { GameListByHost } from './game-list-by-host'

export const RecentGames = observer(function RecentGames({
  activeGamesListDisplay,
  recentlyActiveGamesListDisplay,
  recentlyCompletedGamesListDisplay,
}: {
  activeGamesListDisplay: Game[]
  recentlyActiveGamesListDisplay: Game[]
  recentlyCompletedGamesListDisplay: Game[]
}): React.ReactElement {
  return (
    <div className='flex flex-col gap-12'>
      <div className='flex flex-col gap-4'>
        <div className='flex gap-2'>
          <Badge colour='green'>{activeGamesListDisplay.length}</Badge>
          <Heading>Active</Heading>
        </div>

        <GameListByHost games={activeGamesListDisplay} />
      </div>

      <div className='flex flex-col gap-4'>
        <div className='flex gap-2'>
          <Badge colour='orange'>{recentlyActiveGamesListDisplay.length}</Badge>
          <Heading>Recently Active</Heading>
        </div>

        <GameListByHost games={recentlyActiveGamesListDisplay} />
      </div>

      <div className='flex flex-col gap-4'>
        <div className='flex gap-2'>
          <Badge colour='blue'>
            {recentlyCompletedGamesListDisplay.length}
          </Badge>
          <Heading>Recently Completed</Heading>
        </div>
        <GameListByHost games={recentlyCompletedGamesListDisplay} />
      </div>
    </div>
  )
})
