import { Heading } from '@matthewlongpre/music-bingo-common'
import {
  RadioButtonGroup,
  RadioButtonGroupOption,
} from '@matthewlongpre/music-bingo-common/client'
import { observer } from 'mobx-react-lite'
import React, { SyntheticEvent } from 'react'

import { MixpanelService } from '@/mixpanel'
import { ColourMode, useThemeContext } from '@/theme/ThemeContext'

const options: RadioButtonGroupOption[] = [
  {
    id: 'auto',
    name: 'colour-mode',
    value: 'auto',
    label: <h2>Auto</h2>,
  },
  {
    id: 'light',
    name: 'colour-mode',
    value: 'light',
    label: <h2>Light</h2>,
  },
  {
    id: 'dark',
    name: 'colour-mode',
    value: 'dark',
    label: <h2>Dark</h2>,
  },
]

export const ColourSettings = observer(
  function ColourSettings(): React.ReactElement {
    const { colourPreference, setColourPreference } = useThemeContext()

    const handleChange = (event: SyntheticEvent) => {
      const { value } = event.target as HTMLInputElement
      const colourMode = value as ColourMode
      setColourPreference(colourMode)

      MixpanelService.track('Color Theme Changed', {
        'Color Theme': colourPreference,
      })
    }

    return (
      <div>
        <Heading className='mb-4'>Color Mode</Heading>
        <RadioButtonGroup
          direction='horizontal'
          options={options}
          value={colourPreference}
          variant='compact'
          onChange={handleChange}
        />
      </div>
    )
  }
)
