import { ColourPalette } from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React from 'react'

import { PlayerList, PlayerListTab } from '@/components/PlayerList'

import { useGameContext } from '../../game/context/game-context'

export const PlayersHistory = observer(
  function PlayersHistory(): React.ReactElement {
    const { players } = useGameContext()
    const playersList = players.filter(({ isRemoved }) => !isRemoved)

    return (
      <PlayerList
        badgeColor={ColourPalette.GREEN}
        defaultTab={PlayerListTab.LIST}
        description='Guests who were in the game.'
        noItemsText='No guests were in the game.'
        players={playersList}
        title='Guests'
        enableTabs
      />
    )
  }
)
