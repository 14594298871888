import { merge } from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React, { useRef } from 'react'

import { NavigationLink } from './NavigationLink'
import { Tooltip, TooltipProps } from './shared/tooltip/Tooltip'

const SIZES = {
  large:
    'w-8 h-8 [&_svg]:w-4 [&_svg]:h-4 md:w-12 md:h-12 md:[&_svg]:w-6 md:[&_svg]:h-6',
  small:
    'w-4 h-4 [&_svg]:w-3 [&_svg]:h-3 md:w-8 md:h-8 md:[&_svg]:w-4 md:[&_svg]:h-4',
} as const

export type IconButtonSizes = keyof typeof SIZES

export interface IconButtonProps {
  children?: React.ReactNode
  className?: string
  icon: React.ReactNode
  onClick?: (() => void) | (() => Promise<void>)
  size?: IconButtonSizes
  title: string
  tooltipProps?: Partial<TooltipProps>
  url?: string
}

export const IconButton = observer(function IconButton({
  children,
  className,
  icon,
  size = 'large',
  title,
  tooltipProps = { placement: 'bottom', title },
  url,
  onClick,
}: IconButtonProps): React.ReactElement {
  const ref = useRef<HTMLButtonElement | null>(null)
  const classes = merge('group', SIZES[size], 'focus:outline-none', className)

  const buttonContent = (
    <div className='relative flex items-center justify-center rounded-full w-full h-full group-hover:bg-shade-2 group-focus:ring-purple-500 group-focus:ring group-focus:shadow transition-colors duration-300'>
      {icon}
      {children}
    </div>
  )

  return (
    <>
      {url && !onClick && (
        <NavigationLink
          className={classes}
          ref={ref as React.RefObject<HTMLAnchorElement>}
          to={url}
        >
          {buttonContent}
        </NavigationLink>
      )}

      {!url && onClick && (
        <button className={classes} ref={ref} onClick={() => void onClick()}>
          {buttonContent}
        </button>
      )}

      <Tooltip refObject={ref} {...tooltipProps} />
    </>
  )
})
