import {
  Heading,
  Panel,
  Container,
  Description,
} from '@matthewlongpre/music-bingo-common'
import * as Sentry from '@sentry/react'
import {
  confirmPasswordReset,
  getAuth,
  verifyPasswordResetCode,
} from 'firebase/auth'
import { Form, Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'

import { Button } from '@/components/Button'

import { FormError } from './FormError'
import { PASSWORD_VALIDATION_SCHEMA } from './PasswordForm'
import { TextField } from './TextField'
import { LogoHeader } from '../LogoHeader'
import { Main } from '../Main'
import { Page } from '../Page'

interface VerifyPasswordResetFormValues {
  password: string
}

interface VerifyPasswordResetFormProps {
  code: string
}

export const VerifyPasswordResetForm = observer(
  function VerifyPasswordResetForm({
    code,
  }: VerifyPasswordResetFormProps): React.ReactElement {
    const [formError, setFormError] = useState<string | undefined>()
    const history = useHistory()

    const [isLoading, setIsLoading] = useState(true)
    const [isValid, setIsValid] = useState(true)

    const handleSubmit = async (values: VerifyPasswordResetFormValues) => {
      try {
        const auth = getAuth()
        await confirmPasswordReset(auth, code, values.password)

        history.push('/login?email=true')
      } catch (error) {
        console.error(error)
        Sentry.captureException(error)

        setFormError('Sorry, something went wrong')
      }
    }

    useEffect(() => {
      const auth = getAuth()

      verifyPasswordResetCode(auth, code)
        .then(() => setIsValid(true))
        .catch(() => setIsValid(false))
        .finally(() => setIsLoading(false))
    }, [code])

    if (isLoading) return <></>

    if (!isValid) return <InvalidPasswordReset />

    return (
      <Page title='Reset Password'>
        <Main>
          <LogoHeader />

          <Container size='small'>
            <Panel className='flex flex-col gap-8'>
              <Heading>Reset your password</Heading>

              <Formik
                initialValues={{
                  password: '',
                }}
                validationSchema={yup.object({
                  password: PASSWORD_VALIDATION_SCHEMA,
                })}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting, isValid }) => (
                  <Form className='flex flex-col gap-8'>
                    <div className='flex flex-col gap-6'>
                      <TextField
                        label='Password'
                        name='password'
                        type='password'
                      />
                    </div>

                    <Button
                      disabled={isSubmitting || !isValid}
                      text='Reset Password'
                      type='submit'
                    />

                    {formError && <FormError error={formError} />}
                  </Form>
                )}
              </Formik>
            </Panel>
          </Container>
        </Main>
      </Page>
    )
  }
)

export const InvalidPasswordReset = observer(
  function InvalidPasswordReset(): React.ReactElement {
    return (
      <Page title='Invalid Password Reset'>
        <Main>
          <LogoHeader />

          <Container size='small'>
            <Panel className='flex flex-col gap-8'>
              <div>
                <Heading>Invalid password reset</Heading>
                <Description className='mt-2'>
                  Sorry, an error occurred.
                </Description>
              </div>
              <Button text='Try Again' to='/reset-password' variant='anchor' />
            </Panel>
          </Container>
        </Main>
      </Page>
    )
  }
)
