import { useQuery } from 'react-query'

import { useSpotify } from '@/store/integrations/spotify/useSpotify'

export function useQuerySpotifyTracks(query = '') {
  const { playlistService } = useSpotify()

  const { key, fn } = playlistService.queries.searchTracks

  return useQuery(key(query), () => fn(query), { enabled: Boolean(query) })
}
