import {
  Panel,
  Spinner,
  Heading,
  Container,
  Description,
} from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React from 'react'

import { LogoHeader } from '@/components/LogoHeader'
import { Main } from '@/components/Main'
import { Page } from '@/components/Page'
import StoreService from '@/store/StoreService'

import { GameContextProvider } from './game-context'

interface AnonymousGameRouteProps {
  children: React.ReactElement | React.ReactElement[]
}

export const AnonymousGameRoute = observer(function AnonymousGameRoute({
  children,
}: AnonymousGameRouteProps): React.ReactElement {
  const { currentGame, isLoadingGame } = StoreService.getStore()

  if (isLoadingGame) return <Spinner />

  if (!currentGame) {
    return (
      <Page title='Waiting for a game to start'>
        <Main className='sm:pt-16 md:pt-24 lg:pt-32'>
          <BrandHeader />

          <Container className='text-center' size='small'>
            <Panel>
              <Heading>Waiting for a game to start</Heading>
              <Description className='mt-2'>
                The host may be setting up the next game.
              </Description>
            </Panel>
          </Container>
        </Main>
      </Page>
    )
  }

  return <GameContextProvider>{children}</GameContextProvider>
})

const BrandHeader = observer(function BrandImage() {
  const { host } = StoreService.getStore()

  const brandImageUrl = host?.settings?.branding?.image?.url

  if (brandImageUrl) {
    return (
      <div className='flex w-48 h-48 aspect-square overflow-auto rounded-xl m-auto mb-8'>
        <img className='max-w-full object-contain' src={brandImageUrl} />
      </div>
    )
  }

  return <LogoHeader />
})
