import { Container, Panel } from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { AppHeader } from '@/components/AppHeader'
import { Button } from '@/components/Button'
import { BackButton, CloseButton } from '@/components/icon-buttons/IconButtons'
import { List } from '@/components/List'
import { Main } from '@/components/Main'
import { Nav } from '@/components/Nav'
import { LocationState } from '@/components/NavigationLink'
import { Page } from '@/components/Page'
import { PageHeading } from '@/components/PageHeading'
import { SubscriptionActionRequired } from '@/components/SubscriptionActionRequired'
import { Title } from '@/components/Title'
import { ConnectSpotifyCard } from '@/store/integrations/spotify/components/ConnectSpotifyCard'
import { SpotifyConnectionSettings } from '@/store/integrations/spotify/components/SpotifyConnectionSettings'
import StoreService from '@/store/StoreService'

import { AccountSettings } from '../components/AccountSettings'
import { ColourSettings } from '../components/ColourSettings'

export const HomeScreenSettingsRoute = observer(
  function HomeScreenSettingsRoute(): React.ReactElement {
    return <HomeScreenSettings />
  }
)

const HomeScreenSettings = observer(
  function HomeScreenSettings(): React.ReactElement {
    const { user } = StoreService.getStore()
    const history = useHistory()
    const location = useLocation<LocationState>()
    const from = location.state?.from

    const handleBackClick = () => {
      if (from) {
        history.push(from)
      } else {
        history.push('/')
      }
    }

    return (
      <Page title='Settings'>
        <AppHeader
          actionsLeft={<BackButton onClick={handleBackClick} />}
          actionsRight={<CloseButton onClick={handleBackClick} />}
          header={<PageHeading>Settings</PageHeading>}
        />

        <Main>
          {user.isSubscriptionActionRequired && (
            <Container className='mb-6' size='medium'>
              <SubscriptionActionRequired />
            </Container>
          )}

          <Container size='medium'>
            <Panel>
              <AccountSettings />
            </Panel>
          </Container>

          <Container className='mt-6' size='medium'>
            <Panel>
              <SpotifyConnectionSettings />
            </Panel>
          </Container>

          <Container className='mt-6' size='medium'>
            <Panel>
              <ColourSettings />
            </Panel>
          </Container>

          <Container className='mt-6' size='medium'>
            <Nav>
              <List>
                <List.Item to='/settings/branding' showChevron>
                  <Title
                    description='Customize how your brand appears throughout the application.'
                    heading='Branding Settings'
                    size='small'
                  />
                </List.Item>
                <List.Item to='/settings/join-screen' showChevron>
                  <Title
                    description='Customize the web address guests join games from.'
                    heading='Join Screen URL'
                    size='small'
                  />
                </List.Item>
                <List.Item to='/settings/venues' showChevron>
                  <Title
                    description='Manage the locations you host games at.'
                    heading='Venues'
                    size='small'
                  />
                </List.Item>
              </List>
            </Nav>
          </Container>

          <Container className='my-8' size='medium'>
            <Button text='Done' onClick={() => history.push('/')} />
          </Container>
        </Main>
      </Page>
    )
  }
)
