import { Song } from '@matthewlongpre/music-bingo-common'

export function SongMetadata({ song }: { song: Song }) {
  return (
    <div className='w-full flex flex-col justify-center gap-0.5 overflow-hidden'>
      <span className='leading-tight text-ellipsis overflow-hidden whitespace-nowrap'>
        {song.title}
      </span>
      <span className='uppercase font-semibold tracking-tight leading-tight text-muted text-sm text-ellipsis overflow-hidden whitespace-nowrap'>
        {song.artist}
      </span>
      <span className='tracking-wide leading-tight text-muted text-xs text-ellipsis overflow-hidden whitespace-nowrap'>
        {song?.album}
      </span>
    </div>
  )
}
