import { ColourPalette } from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React from 'react'

import { PlayerList, PlayerListTab } from '@/components/PlayerList'

import { useGameContext } from '../../game/context/game-context'

export const RemovedPlayersHistory = observer(
  function RemovedPlayersHistory(): React.ReactElement {
    const { players } = useGameContext()
    const removedPlayers = players.filter(({ isRemoved }) => isRemoved)

    return (
      <PlayerList
        badgeColor={ColourPalette.RED}
        defaultTab={PlayerListTab.LIST}
        description='Guests who were removed from the game.'
        noItemsText='No guests were removed.'
        players={removedPlayers}
        title='Removed Guests'
        enableTabs
      />
    )
  }
)
