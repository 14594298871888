import {
  CloseIcon,
  Container,
  ExpandIcon,
  Heading,
} from '@matthewlongpre/music-bingo-common'
import { ScrollContainer } from '@matthewlongpre/music-bingo-common/client'
import 'firebase/auth'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { AppFooter } from '@/components/AppFooter'
import { AppHeader } from '@/components/AppHeader'
import { BannerGameEnded } from '@/components/BannerGameEnded'
import { BingoResultsBanner } from '@/components/BingoResultsBanner'
import { CalledBingos } from '@/components/CalledBingos'
import { GameHeader } from '@/components/GameHeader'
import { SettingsButton } from '@/components/icon-buttons/IconButtons'
import { IconButton } from '@/components/IconButton'
import { LobbyIcon } from '@/components/LobbyIcon'
import { Main } from '@/components/Main'
import { Page } from '@/components/Page'
import { Playlist, PlaylistRow } from '@/components/Playlist'
import { useGameContext } from '@/pages/game/context/game-context'
import { isDrawnSong } from '@/utils/type-guards'

import { Lyrics } from './components/Lyrics'
import { MessageBanners } from '../components/MessageBanners'
import { MessageQueue } from '../message-queue/MessageQueue'

export const SidekickLyrics = observer(
  function SidekickLyrics(): React.ReactElement | null {
    const {
      isCompletedGame,
      currentDrawnItem,
      currentItemIndex,
      gameData,
      isStartedGame,
    } = useGameContext()

    const [isExpandedLyrics, setIsExpandedLyrics] = useState(false)

    const { pathname } = useLocation()
    const getPathname = () => pathname.replace(/\/\s*$/, '')

    const drawnItem = isStartedGame ? currentDrawnItem : gameData.drawnItems[0]
    if (gameData.type !== 'music' || !isDrawnSong(drawnItem)) return null

    if (isExpandedLyrics) {
      return (
        <Page title='Sidekick Lyrics'>
          <ExpandedLyricsStyled>
            <AppHeader
              actionsRight={
                <IconButton
                  icon={<CloseIcon />}
                  title='Close'
                  onClick={() => setIsExpandedLyrics(!isExpandedLyrics)}
                />
              }
              header={
                <Container size='medium'>
                  <PlaylistRow
                    {...drawnItem}
                    className='song-title compact m-0'
                    currentItemIndex={currentItemIndex}
                    enableRowAudioFeatures={true}
                    index={currentItemIndex}
                  />
                </Container>
              }
            />

            <Container className='p-6 h-100' size='large'>
              <Lyrics song={drawnItem.song} />
            </Container>

            <AppFooter>
              <Container size='large'>
                <MessageBanners isSidekick={true} />
              </Container>
            </AppFooter>
          </ExpandedLyricsStyled>
        </Page>
      )
    }

    return (
      <Page title='Sidekick'>
        <Helmet>
          <body className='overflow-hidden' />

          <style type='text/css'>
            {`
           .overflow-hidden #root {
              overflow: hidden;
            }

            body {
              scroll-behavior: smooth;
            }
          `}
          </style>
        </Helmet>

        <MessageQueue />

        <AppHeader
          actionsRight={
            <>
              <LobbyIcon url={`${getPathname()}/lobby`} />
              <SettingsButton url={`${getPathname()}/settings`} />
            </>
          }
          header={<GameHeader />}
          secondaryHeader={
            <>
              {isCompletedGame && (
                <Container className='px-0' size='large'>
                  <BannerGameEnded isSidekick />
                </Container>
              )}

              {!isCompletedGame && <SecondaryHeader />}
            </>
          }
        />

        <Main className='pt-0 lg:pt-0 pb-0 flex flex-col overflow-hidden'>
          <GridContainerStyled>
            <ScrollContainer className='px-6 compact w-100'>
              <Notes />

              <LyricsHeaderStyled>
                <div className='flex gap-2 items-center py-6'>
                  <Heading size='large'>Lyrics</Heading>
                  <IconButton
                    icon={<ExpandIcon />}
                    title='Expand'
                    onClick={() => setIsExpandedLyrics(!isExpandedLyrics)}
                  />
                </div>
              </LyricsHeaderStyled>

              {isDrawnSong(drawnItem) && <Lyrics song={drawnItem.song} />}
            </ScrollContainer>

            {!isExpandedLyrics && (
              <Playlist
                enableRowAudioFeatures={true}
                scrollContainer='element'
                scrollToSong='next'
              />
            )}
          </GridContainerStyled>
        </Main>
      </Page>
    )
  }
)

const ExpandedLyricsStyled = styled.div`
  height: 100%;

  .song-title {
    font-size: 1rem;

    @media (max-width: 599px) {
      font-size: 0.75rem;
      margin-left: -20px;
    }

    div {
      overflow: hidden;
    }

    h3,
    .artist {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 100%;
    }
  }
`

const LyricsHeaderStyled = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`

const Notes = observer(function Notes(): React.ReactElement | null {
  const { gameData, currentItemIndex } = useGameContext()

  if (gameData.type !== 'music') return null

  const notes =
    gameData.sourcePlaylistMeta.songs[currentItemIndex].userMeta?.notes

  if (!notes) return null

  return (
    <NotesStyled>
      <h2 className='text-muted'>Notes</h2>
      <p>{notes}</p>
    </NotesStyled>
  )
})

const SecondaryHeader = observer(
  function GameHeader(): React.ReactElement | null {
    const { isStartedGame, currentDrawnItem, currentItemIndex, gameData } =
      useGameContext()
    const drawnItem = isStartedGame ? currentDrawnItem : gameData.drawnItems[0]

    if (!isDrawnSong(drawnItem)) return null

    return (
      <>
        <SecondaryHeaderStyled className='pb-6'>
          <Container size='large'>
            <PlaylistRow
              {...drawnItem}
              className='compact m-0'
              currentItemIndex={currentItemIndex}
              enableRowAudioFeatures={true}
              index={currentItemIndex}
            />
          </Container>
        </SecondaryHeaderStyled>
        <CalledBingos hideControls />
        <BingoResultsBanner />
      </>
    )
  }
)

const GridContainerStyled = styled.div`
  display: grid;
  grid-template-columns: 0.6fr 0.4fr;
  grid-gap: 20px;
  overflow: hidden;
  width: 100%;
`

const SecondaryHeaderStyled = styled.div``

const NotesStyled = styled.div`
  border-bottom: 1px solid var(--text-muted);
  margin-bottom: 40px;

  p {
    font-size: 1.33em;
  }
`
