import { Collections } from '@matthewlongpre/music-bingo-common'
import { doc, getDoc } from 'firebase/firestore'
import { useEffect, useState } from 'react'

import { databaseRef } from '@/firebase/firebase'

import StoreService from '../store/StoreService'


interface IUseAuthorization {
  isAdmin: boolean
  loading: boolean
}

export const useAuthorization = (): IUseAuthorization => {
  const [loading, setLoading] = useState(true)
  const [isAdmin, setIsAdmin] = useState<boolean>(false)

  const { user } = StoreService.getStore()
  const { isLoggedIn, userId } = user

  useEffect(() => {
    if (!isLoggedIn) {
      setLoading(false)
      return
    }

    const fetchUserData = async () => {
      try {
        setLoading(true)

        const adminDoc = await getDoc(
          doc(databaseRef, Collections.ADMINS, userId)
        )

        if (adminDoc.exists()) {
          setIsAdmin(true)
        } else {
          setIsAdmin(false)
        }

        setLoading(false)
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    }

    void fetchUserData()
  }, [isLoggedIn, userId])

  return { loading, isAdmin }
}
