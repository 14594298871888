import { Playlist } from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React from 'react'

import { PlaylistCard } from './PlaylistCard'
import {
  AnimatedStaggerChild,
  AnimatedStaggerParent,
} from './shared/AnimatedStagger'
import { CardGridItemStyled, CardGridStyled } from './StyledComponents'

interface ICardGridProps {
  createCard?: React.ReactElement
  handleClick: (id: string) => void
  items: readonly Playlist[]
}

export const CardGrid = observer(function CardGrid({
  createCard,
  handleClick,
  items = [],
}: ICardGridProps): React.ReactElement {
  return (
    <AnimatedStaggerParent>
      <CardGridStyled>
        {createCard}

        {items.map(({ title, colour, id, image }) => (
          <AnimatedStaggerChild key={`animated--${id}`} keyId={id}>
            <CardGridItemStyled>
              <PlaylistCard
                colour={colour}
                image={image}
                title={title}
                onClick={() => handleClick(id)}
              />
            </CardGridItemStyled>
          </AnimatedStaggerChild>
        ))}
      </CardGridStyled>
    </AnimatedStaggerParent>
  )
})
