import {
  Collections,
  Container,
  Panel,
  UserBrandingSettings,
} from '@matthewlongpre/music-bingo-common'
import { doc, updateDoc } from 'firebase/firestore'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { AppHeader } from '@/components/AppHeader'
import { BackButton, CloseButton } from '@/components/icon-buttons/IconButtons'
import { Main } from '@/components/Main'
import { Page } from '@/components/Page'
import { PageHeading } from '@/components/PageHeading'
import { databaseRef } from '@/firebase/firebase'
import { BrandingSettingsForm } from '@/pages/settings/components/BrandingSettingsForm'
import StoreService from '@/store/StoreService'

import { useGameContext } from '../context/game-context'

export const BrandingSettingsGameLevelRoute = observer(
  function BrandingSettingsGameLevelRoute(): React.ReactElement {
    return <BrandingSettingsGameLevelPage />
  }
)

const BrandingSettingsGameLevelPage = observer(
  function BrandingSettingsGameLevelPage(): React.ReactElement {
    const { user } = StoreService.getStore()
    const { gameId, gameData, tracker } = useGameContext()
    const history = useHistory()

    const initialValues =
      gameData?.userSettings?.branding ?? user?.settings?.branding ?? {}

    const handleSave = async (values: UserBrandingSettings) => {
      const data = { [`userSettings.branding`]: { ...values } }
      await updateDoc(doc(databaseRef, Collections.GAMES, gameId), data)
      tracker.changeBranding(initialValues, values)
    }

    const handleClickBack = () => {
      history.goBack()
    }

    const handleClickClose = () => {
      history.push('/game')
    }

    const handleSuccess = () => {
      history.push('/game')
    }

    return (
      <Page title='Branding Settings'>
        <AppHeader
          actionsLeft={<BackButton onClick={handleClickBack} />}
          actionsRight={<CloseButton onClick={handleClickClose} />}
          header={<PageHeading>Branding Settings</PageHeading>}
        />

        <Main>
          <Container size='medium'>
            <Panel>
              <BrandingSettingsForm
                initialValues={initialValues}
                onSubmit={handleSave}
                onSuccess={handleSuccess}
              />
            </Panel>
          </Container>
        </Main>
      </Page>
    )
  }
)
