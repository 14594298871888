import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import React from 'react'
import styled from 'styled-components'

export function secondsToTime(seconds: number): string {
  const m = Math.floor((seconds % 3600) / 60)
      .toString()
      .padStart(2, '0'),
    s = Math.floor(seconds % 60)
      .toString()
      .padStart(2, '0')

  return m + ':' + s
}

interface PlaybackProgressProps {
  currentPlaybackDuration: number
  currentPlaybackTime: number
  isLoading?: boolean
  isPlaying?: boolean
  progressPercent: string
}

export const PlaybackProgress = observer(function PlaybackProgress({
  currentPlaybackDuration,
  currentPlaybackTime,
  isLoading = false,
  isPlaying = false,
  progressPercent,
}: PlaybackProgressProps): React.ReactElement {
  return (
    <PlaybackProgressStyled
      className={classNames({
        'is-loading': isLoading,
        'is-playing': isPlaying,
      })}
    >
      <div className='time'>{secondsToTime(currentPlaybackTime)}</div>
      <div className='progress-bar'>
        <div className='progress' style={{ width: progressPercent }}></div>
      </div>
      <div className='time'>{secondsToTime(currentPlaybackDuration)}</div>
    </PlaybackProgressStyled>
  )
})

const PlaybackProgressStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  .time {
    display: flex;
    align-items: center;
    width: 4em;
    will-change: opacity;
    transition: opacity 0.3s ease-in-out;
  }

  .progress-bar {
    display: flex;
    align-items: center;
    width: 100%;
    height: 8px;
    border-radius: 4px;
    background: var(--bg-03);
    overflow: hidden;

    .progress {
      display: flex;
      height: 100%;
      background: var(--text-muted);
      transition: background 0.3s ease-in-out;
    }
  }

  &.is-loading {
    .time {
      opacity: 0;
    }
  }

  &.is-playing {
    .progress {
      background: var(--primary-500);
    }
  }
`
