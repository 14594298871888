import { Container } from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React from 'react'

import { AppHeader } from '@/components/AppHeader'
import { AppHeaderLogo } from '@/components/AppHeaderLogo'
import { BackButton } from '@/components/icon-buttons/IconButtons'
import { List } from '@/components/List'
import { Main } from '@/components/Main'
import { Nav } from '@/components/Nav'
import { Page } from '@/components/Page'
import { PageTitle } from '@/components/PageTitle'
import { TermsOfService } from '@/components/TermsOfService'
import { Title } from '@/components/Title'

interface CreatePlaylistProps {
  baseUrl?: string
}

export const CreatePlaylistView = observer(function CreatePlaylistView({
  baseUrl = '/',
}: CreatePlaylistProps): React.ReactElement {
  return (
    <Page title='Create a Custom Playlist'>
      <AppHeader
        actionsLeft={<BackButton url={baseUrl} />}
        header={<AppHeaderLogo />}
      />

      <Main>
        <Container size='medium'>
          <PageTitle
            className='text-center mb-6'
            size='large'
            disableTruncation
          >
            Create a Custom Playlist
          </PageTitle>
        </Container>

        <Container size='medium'>
          <Nav>
            <List>
              <List.Item
                to={`${baseUrl}playlists/create/import-spotify`}
                showChevron
              >
                <Title
                  description='Start with a playlist from Spotify, then customize the titles and
                set playback start positions.'
                  heading='Import from Spotify'
                  size='small'
                />
              </List.Item>
              <List.Item
                to={`${baseUrl}playlists/create/import-spreadsheet`}
                showChevron
              >
                <Title
                  description='Upload a CSV document to import songs in bulk. Please note: This
              method is not compatible with automatic playback.'
                  heading='Import a Spreadsheet'
                  size='small'
                />
              </List.Item>
              <List.Item
                to={`${baseUrl}playlists/create/import-json`}
                showChevron
              >
                <Title
                  description='Upload a Rockstar Bingo playlist in JSON format.'
                  heading='Import a JSON file'
                  size='small'
                />
              </List.Item>
            </List>
          </Nav>
        </Container>

        <TermsOfService />
      </Main>
    </Page>
  )
})
