import { Container } from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'

import { PlaybackProgress } from './PlaybackProgress'
import { useSpotify } from '../../useSpotify'

export const PlaybackProgressSpotify = observer(
  ({ isPlaying }: { isPlaying: boolean }) => {
    const { player, playback, playerService } = useSpotify()

    const {
      currentPlaybackDuration,
      currentPlaybackTime,
      isActiveDevice: webPlayerIsActive,
      progressPercent,
    } = player

    if (!playerService.isReady || !webPlayerIsActive) return null

    return (
      <Container size='small'>
        <PlaybackProgress
          currentPlaybackDuration={currentPlaybackDuration}
          currentPlaybackTime={currentPlaybackTime}
          isLoading={playback.isLoading}
          isPlaying={isPlaying}
          progressPercent={progressPercent}
        />
      </Container>
    )
  }
)
