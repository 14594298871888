import { Column, DrawnItem } from '@matthewlongpre/music-bingo-common'

interface CurrentDrawnItemProps {
  drawnItem: DrawnItem
}

export function CurrentDrawnItem({ drawnItem }: CurrentDrawnItemProps) {
  const { id, column } = drawnItem

  return (
    <div className='flex gap-2 text-4xl md:text-6xl font-bold'>
      <div className='w-16 h-16 sm:w-24 sm:h-24 md:w-32 md:h-32 rounded-3xl rounded-tr-none rounded-br-none bg-orange-500 text-black flex items-center justify-center duration-300 transition-opacity'>
        <span className='-mt-1'>{Column[column]}</span>
      </div>
      <div className='w-16 h-16 sm:w-24 sm:h-24 md:w-32 md:h-32 rounded-3xl rounded-tl-none rounded-bl-none bg-blue-500 flex items-center justify-center duration-300 transition-opacity'>
        <span className='-mt-2'>{id}</span>
      </div>
    </div>
  )
}
