import {
  Panel,
  Spinner,
  Container,
  Heading,
} from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { Redirect, Route } from 'react-router-dom'

import { useAuthorization } from './use-authorization'
import { Logo } from '../components/Logo'
import { Main } from '../components/Main'
import { Page } from '../components/Page'
import StoreService from '../store/StoreService'

interface IAdminRouteProps {
  component: React.ElementType
  exact?: boolean
  path: string | string[]
}

export const AdminRoute = observer(function AdminRoute({
  component: Component,
  ...rest
}: IAdminRouteProps): React.ReactElement {
  const { user } = StoreService.getStore()
  const { isLoggedIn } = user
  const { loading, isAdmin } = useAuthorization()

  if (loading) return <Spinner label='Loading...' />

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isLoggedIn)
          return (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          )

        return isAdmin ? <Component {...props} /> : <NotAuthorizedRoute />
      }}
    />
  )
})

const NotAuthorizedRoute = observer(
  function NotAuthorizedRoute(): React.ReactElement {
    return (
      <Page title='401 - Not Authorized'>
        <Main>
          <Container
            className='flex flex-col gap-6 justify-center'
            size='small'
          >
            <Logo size='small' />

            <Panel className='text-center'>
              <Heading>401 - Not Authorized</Heading>
            </Panel>
          </Container>
        </Main>
      </Page>
    )
  }
)
