import type { Song } from '@matthewlongpre/music-bingo-common'
import { Label } from '@matthewlongpre/music-bingo-common'
import { Dialog } from '@matthewlongpre/music-bingo-common/client'
import { FastField, Form, Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { ZodError } from 'zod'

import { Button } from '@/components/Button'
import { Textarea } from '@/components/forms/Textarea'
import { TextField } from '@/components/forms/TextField'
import { AudioInfoBadge } from '@/components/RowAudioFeatures'
import { Select, SelectOption } from '@/components/shared/Select'

import { StartPositionField } from './StartPositionField'
import { SongSchema } from '../playlist-import/playlist-schema'

export const keys = [
  'C',
  'C♯',
  'D',
  'D♯',
  'E',
  'F',
  'F♯',
  'G',
  'G♯',
  'A',
  'A♯',
  'B',
]

const keyOptions: SelectOption[] = keys.map((key, index) => {
  return { id: index.toString(), value: index.toString(), name: key }
})

function validateForm(values: Song) {
  try {
    SongSchema.parse(values)
  } catch (error) {
    if (error instanceof ZodError) {
      return error.formErrors.fieldErrors
    }
  }
}

interface PlaylistTrackFormProps {
  isLoading?: boolean
  isVisible?: boolean
  onClose: () => void
  onSubmit: (values: Song) => Promise<void>
  showSuccess?: boolean
  song?: Song
}

export const PlaylistTrackForm = observer(function PlaylistTrackForm({
  isVisible = false,
  isLoading = false,
  showSuccess = false,
  song,
  onSubmit,
  onClose,
}: PlaylistTrackFormProps): React.ReactElement | null {
  if (!song) return null

  const { id, spotifyMeta } = song

  return (
    <Dialog isVisible={isVisible}>
      <Dialog.Header>
        <Dialog.Title>Edit Track</Dialog.Title>
        <Dialog.CloseButton onClick={onClose} />
      </Dialog.Header>

      <Dialog.Separator />

      <Formik initialValues={song} validate={validateForm} onSubmit={onSubmit}>
        <Form>
          <Dialog.Body className='py-0'>
            <div className='flex flex-col gap-6'>
              <TextField label='Title' name='title' />
              <TextField label='Artist' name='artist' />

              <>
                <div className='flex flex-col gap-3'>
                  <Label htmlFor={`video--${id}`}>Video</Label>
                  <FastField name='video.url' />
                </div>

                <div className='flex flex-col gap-3'>
                  <FastField name='userMeta.notes'>
                    {/* @ts-expect-error Formik */}
                    {({ field }) => (
                      <Textarea field={field} id='notes' maxLength={500}>
                        Notes
                      </Textarea>
                    )}
                  </FastField>
                </div>
              </>

              {spotifyMeta && (
                <div className='flex gap-4'>
                  {spotifyMeta.features?.tempo && (
                    <TextField
                      label='Tempo'
                      name='userMeta.tempo'
                      type='number'
                    />
                  )}

                  {spotifyMeta.features?.key !== undefined && (
                    <div className='flex flex-col gap-3'>
                      <Label htmlFor={`key--${id}`}>Key</Label>
                      <FastField name='userMeta.key'>
                        {/* @ts-expect-error Formik */}
                        {({ field }) => (
                          <Select
                            options={keyOptions}
                            value={String(field.value)}
                            onChange={(value: string) => {
                              field.onChange(parseInt(value, 10))
                            }}
                          />
                        )}
                      </FastField>
                    </div>
                  )}

                  <div className='flex flex-col gap-3 flex-1'>
                    <Label htmlFor={`start-position--${id}`}>Start</Label>
                    <StartPositionField duration={spotifyMeta.duration} />
                  </div>

                  {spotifyMeta.features && (
                    <div className='flex flex-col items-center gap-3 w-12'>
                      <Label
                        className='text-center'
                        htmlFor={`spotify-info--${id}`}
                      >
                        Info
                      </Label>
                      <div className='flex items-center'>
                        <AudioInfoBadge className='w-12 h-12' song={song} />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </Dialog.Body>

          <Dialog.Footer>
            <Dialog.Separator />
            <Button
              isLoading={isLoading}
              showSuccess={showSuccess}
              text='Save'
              type='submit'
            />
          </Dialog.Footer>
        </Form>
      </Formik>
    </Dialog>
  )
})
