import { Panel, Container } from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Button } from '@/components/Button'
import { PasswordResetForm } from '@/components/forms/PasswordResetForm'
import { LogoHeader } from '@/components/LogoHeader'
import { Main } from '@/components/Main'
import { Page } from '@/components/Page'
import { Title } from '@/components/Title'
import { useSearchParams } from '@/utils/use-search-params'

import { MixpanelService } from '../../mixpanel'

export const ResetPasswordRoute = observer(
  function ResetPasswordRoute(): React.ReactElement {
    const history = useHistory()
    const query = useSearchParams()
    const email = query.get('email') ?? ''

    const [hasSubmitted, setHasSubmitted] = useState(false)

    const handleClickBack = () => {
      history.push('/login')
    }

    const handleSubmit = () => {
      setHasSubmitted(true)

      MixpanelService.track('Password Reset Submitted')
    }

    if (hasSubmitted) {
      return (
        <Page title='Password Reset Sent'>
          <Main>
            <LogoHeader />

            <Container size='small'>
              <Panel>
                <Title
                  description='Please check your inbox for an email from us and follow the
                instructions to finish resetting your password.'
                  heading='Password reset sent'
                />

                <Button
                  className='mt-6'
                  text='Back'
                  variant='anchor'
                  onClick={handleClickBack}
                />
              </Panel>
            </Container>
          </Main>
        </Page>
      )
    }

    return (
      <Page title='Reset Password'>
        <Main>
          <LogoHeader />

          <Container size='small'>
            <Panel className='flex flex-col gap-6'>
              <Title
                description='Please enter your email address to reset your password'
                heading='Reset Password'
              />

              <PasswordResetForm email={email} onSubmit={handleSubmit} />
            </Panel>
          </Container>
        </Main>
      </Page>
    )
  }
)
