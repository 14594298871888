import {
  NextIcon,
  PauseIcon,
  PlayIcon,
  PreviousIcon,
} from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React, { useRef } from 'react'
import styled from 'styled-components'

import { Tooltip } from './shared/tooltip/Tooltip'

export enum PlaybackAction {
  'PLAY' = 'Play',
  'PAUSE' = 'Pause',
  'NEXT' = 'Next',
  'PREVIOUS' = 'Previous',
}

export enum PlaybackButtonVariant {
  'MEDIUM' = 'medium',
  'LARGE' = 'large',
}

interface IPlaybackButtonProps {
  actionType: PlaybackAction
  disabled?: boolean
  onClick: () => void
  variant?: PlaybackButtonVariant
}

export const PlaybackButton = observer(function PlaybackButton({
  variant = PlaybackButtonVariant.MEDIUM,
  actionType,
  ...props
}: IPlaybackButtonProps): React.ReactElement {
  const ref = useRef<HTMLButtonElement>(null)

  return (
    <>
      <PlaybackButtonStyled
        {...props}
        className={variant === PlaybackButtonVariant.LARGE ? `large` : ``}
        ref={ref}
      >
        {actionType === PlaybackAction.PLAY && <PlayIcon />}
        {actionType === PlaybackAction.PAUSE && <PauseIcon />}
        {actionType === PlaybackAction.NEXT && <NextIcon />}
        {actionType === PlaybackAction.PREVIOUS && <PreviousIcon />}
      </PlaybackButtonStyled>

      <Tooltip placement='top' refObject={ref} title={actionType} />
    </>
  )
})

const PlaybackButtonStyled = styled.button`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  color: var(--text);
  border: 0;
  border-radius: 100%;
  transition: all 0.3s ease-in-out;
  will-change: opacity;

  &.large {
    width: 60px;
    height: 60px;

    svg {
      width: 32px;
      height: 32px;
    }
  }

  &:not(:disabled) {
    &:hover {
      background: var(--bg-02);
    }
  }

  &:disabled {
    opacity: 0.3;
  }
`
