import {
  Heading,
  Spinner,
  Description,
} from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useQuery } from 'react-query'

import StoreService from '@/store/StoreService'

import { VenueFormValues } from '../../settings/components/VenueForm'
import { fetchVenue } from '../../settings/pages/venue-update'

const QUERY_KEY = 'venue-details'
export interface VenueDetails {
  venueId: string
}
export const VenueDetails = observer(function VenueDetails({
  venueId,
}: VenueDetails) {
  const { user } = StoreService.getStore()

  const { data, isLoading, isError } = useQuery<VenueFormValues, Error>(
    [QUERY_KEY, venueId],
    () => fetchVenue(user.userId, venueId)
  )

  if (isLoading) return <Spinner />

  if (isError || !data) {
    return <div>Could not retrieve venue information.</div>
  }

  const { name, address, city, state, country } = data

  const combinedAddress = [city, state, country].filter(Boolean).join(', ')

  return (
    <>
      <Heading>{name}</Heading>

      {address && <Description>{address}</Description>}

      <div className='flex'>
        {combinedAddress && <Description>{combinedAddress}</Description>}
      </div>
    </>
  )
})
