import { Spinner } from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Redirect, Route, useRouteMatch } from 'react-router-dom'

import StoreService from '@/store/StoreService'

import { ChoosePlaylist } from './choose-playlist'
import { HomeScreenSettingsRoute } from '../../settings/pages/global-settings'

export const ChoosePlaylistRoute = observer(
  function ChoosePlaylistRoute(): React.ReactElement {
    const { path } = useRouteMatch()
    const { currentGame, isLoadingGame } = StoreService.getStore()

    if (isLoadingGame) {
      return <Spinner label='Loading...' />
    }

    if (currentGame) {
      return <Redirect to='/game' />
    } else {
      return (
        <>
          <Helmet>
            <title>Choose a Playlist | Rockstar Bingo</title>
          </Helmet>

          <Route path='/settings'>
            <HomeScreenSettingsRoute />
          </Route>

          <Route path={path} exact>
            <ChoosePlaylist />
          </Route>
        </>
      )
    }
  }
)
