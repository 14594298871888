import { merge, Heading , Description } from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React from 'react'


interface TitleProps {
  className?: string
  description?: React.ReactNode
  heading: React.ReactNode
  size?: 'small' | 'medium' | 'large'
}

export const Title = observer(function Title({
  className,
  description,
  heading,
  size,
}: TitleProps): React.ReactElement {
  return (
    <div
      className={merge(
        'flex flex-col gap-2 overflow-hidden',
        { 'gap-0.5': size === 'small' },
        { 'gap-3': size === 'large' },
        className
      )}
    >
      <Heading size={size}>{heading}</Heading>
      {description && <Description>{description}</Description>}
    </div>
  )
})
