import { getAnalytics } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check'
import {
  FieldValue,
  Timestamp as FirebaseTimestamp,
  getFirestore,
  serverTimestamp,
} from 'firebase/firestore'
import { getStorage, ref } from 'firebase/storage'

export type Timestamp = FirebaseTimestamp

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
}

export const firebaseApp = initializeApp(firebaseConfig)

export const databaseRef = getFirestore(firebaseApp)

export const setServerTimestamp = (): FieldValue => serverTimestamp()

const storage = getStorage(firebaseApp)
export const storageRef = ref(storage)

const isLocal = import.meta.env.VITE_ENV === 'local'

const appCheckKey = import.meta.env.VITE_FIREBASE_APP_CHECK_SITE_KEY

if (!isLocal && appCheckKey) {
  initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaV3Provider(appCheckKey),
    isTokenAutoRefreshEnabled: true,
  })
}

getAnalytics(firebaseApp)
