import { Badge } from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'

export const TipBadge = observer(function TipBadge() {
  return (
    <Badge
      className='w-auto mr-auto text-muted text-2xs sm:text-2xs'
      size='small'
    >
      Tip
    </Badge>
  )
})
