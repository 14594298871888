import {
  Collections,
  PLANTYPES,
  PlanType,
  PlanTypeV2,
} from '@matthewlongpre/music-bingo-common'
import * as Sentry from '@sentry/react'
import { loadStripe } from '@stripe/stripe-js'
import { addDoc, collection, onSnapshot } from 'firebase/firestore'

import { databaseRef } from '@/firebase/firebase'

import StoreService from '../store/StoreService'

export const ERROR_EXISTING_SUBSCRIPTION = 'Customer has existing subscription'

const DATA = {
  allow_promotion_codes: true,
  success_url: `${import.meta.env.VITE_PRIMARY_URL}/payment-success`,
  cancel_url: `${window.location.origin}/setup-account/cancelled`,
}

export async function createCheckoutSession(
  priceId: string,
  planType: Omit<PlanTypeV2, 'free'>,
  quantity = 20
): Promise<void> {
  const { user } = StoreService.getStore()

  if (user.hasSubscription) {
    throw new Error(ERROR_EXISTING_SUBSCRIPTION)
  }

  if (!import.meta.env.VITE_STRIPE_KEY) {
    throw new Error('Could not access Stripe key')
  }

  const stripe = await loadStripe(import.meta.env.VITE_STRIPE_KEY)

  if (!stripe || !user) {
    throw new Error('Could not access Stripe')
  }

  const getData = () => {
    if (planType === PlanType.PRO) {
      const { minimum, maximum } = PLANTYPES.pro.quantity

      return {
        line_items: [
          {
            price: priceId,
            quantity,
            adjustable_quantity: {
              enabled: true,
              minimum,
              maximum,
            },
          },
        ],
      }
    }

    return {
      price: priceId,
    }
  }

  const data = {
    ...DATA,
    ...getData(),
  }

  const docRef = await addDoc(
    collection(
      databaseRef,
      Collections.CUSTOMERS,
      user.userId,
      Collections.CHECKOUT_SESSIONS
    ),
    data
  )

  onSnapshot(docRef, (snap) => {
    const data = snap.data()
    if (!data) {
      throw new Error('Could not retrieve Checkout Session data')
    }

    const error = data.error as Error
    const sessionId = data.sessionId as string

    if (error) {
      alert(`An error occured: ${error.message}`)
    }

    if (sessionId) {
      window.history.pushState(
        {},
        '',
        `${window.location.pathname}/redirect-checkout`
      )

      stripe
        .redirectToCheckout({
          sessionId,
        })
        .catch((error) => {
          console.error(error)
          Sentry.captureException(error)
        })
    }
  })
}
