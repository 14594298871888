import {
  Button,
  ButtonGroup,
  ColourPalette,
  Description,
  Heading,
  PlaybackOption,
  Playlist,
  PlaylistType,
} from '@matthewlongpre/music-bingo-common'
import { Dialog } from '@matthewlongpre/music-bingo-common/client'
import { useHistory } from 'react-router-dom'
import Balancer from 'react-wrap-balancer'

import { fixPlaylist } from '@/pages/playlists/playlist-editor/duplicate-checker/fixPlaylist'
import { useCreateUserPlaylist } from '@/pages/playlists/playlist-queries/user-playlists/useCreateUserPlaylist'

interface PlaylistErrorModalProps {
  isVisible: boolean
  onClose: () => void
  playlist: Playlist
}

export function PlaylistErrorModal({
  isVisible,
  playlist,
  onClose,
}: PlaylistErrorModalProps) {
  const history = useHistory()

  const result = fixPlaylist(playlist)

  const { mutateAsync: createUserPlaylist, isLoading } = useCreateUserPlaylist()

  const getOrCreatePlaylist = async () => {
    if (playlist.type === PlaylistType.USER_CUSTOM_PLAYLISTS) {
      return playlist.id
    }

    const { playlistId } = await createUserPlaylist({
      id: '',
      type: PlaylistType.USER_CUSTOM_PLAYLISTS,
      playbackOptions: [PlaybackOption.SPOTIFY],
      colour: ColourPalette.PURPLE,
      title: playlist.title,
      songs: playlist.songs,
    })

    return playlistId
  }

  const onClickFix = async () => {
    const playlistId = await getOrCreatePlaylist()

    onClose()
    history.push(`/playlists/edit/${playlistId}`)
  }

  if (result.status !== 'error') return null

  return (
    <Dialog isVisible={isVisible} size='small'>
      <Dialog.Body className='items-center text-center'>
        <Balancer className='mx-auto'>
          <Heading>Sorry, this playlist has issues</Heading>
          <Description className='mt-2'>{result.message}</Description>
        </Balancer>
      </Dialog.Body>
      <Dialog.Footer className='pt-6'>
        <ButtonGroup direction='column'>
          <Button
            isLoading={isLoading}
            text='Fix in Playlist Editor'
            onClick={onClickFix}
          />
          <Button text='Cancel' variant='outlined' onClick={onClose} />
        </ButtonGroup>
      </Dialog.Footer>
    </Dialog>
  )
}
