import {
  Collections,
  PlanType,
  SubscriptionData,
} from '@matthewlongpre/music-bingo-common'
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore'

import { databaseRef } from '@/firebase/firebase'

export async function getProSubscription(
  userId: string
): Promise<SubscriptionData | undefined> {
  const subscriptionsCollection = collection(
    databaseRef,
    Collections.CUSTOMERS,
    userId,
    Collections.SUBSCRIPTIONS
  )

  const subscriptionsQuery = query(
    subscriptionsCollection,
    where('status', 'in', ['active', 'trialing']),
    where('role', '==', PlanType.PRO),
    orderBy('created', 'desc')
  )

  const subscriptionsSnapshot = await getDocs(subscriptionsQuery)

  if (subscriptionsSnapshot.empty) return

  const [subscriptionDoc] = subscriptionsSnapshot.docs

  return subscriptionDoc.data()
}
