import React, { forwardRef } from 'react'
import { Link, useLocation } from 'react-router-dom'

export type LocationState = {
  from: Location
}

interface NavigationLinkProps {
  children: React.ReactNode
  className?: string
  ref: React.ForwardedRef<HTMLAnchorElement>
  target?: string
  to: string
}

export const NavigationLink = forwardRef<
  HTMLAnchorElement,
  NavigationLinkProps
>(({ className, children, target, to }: NavigationLinkProps, ref) => {
  const location = useLocation()

  return (
    <Link
      className={className}
      ref={ref}
      target={target}
      to={{
        pathname: to,
        state: { from: location },
      }}
    >
      {children}
    </Link>
  )
})

NavigationLink.displayName = 'NavigationLink'
