import { Spinner } from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { LogoHeader } from '@/components/LogoHeader'
import { Main } from '@/components/Main'
import { Page } from '@/components/Page'
import { TermsOfService } from '@/components/TermsOfService'

interface RedirectFreeProps {
  showWelcomeModal?: boolean
}

export const RedirectFree = observer(function RedirectFree({
  showWelcomeModal,
}: RedirectFreeProps) {
  const history = useHistory()

  useEffect(() => {
    if (showWelcomeModal) {
      history.push('/?welcome=true')
    } else {
      history.push('/')
    }
  }, [history, showWelcomeModal])

  return (
    <Page title='Creating Free Account'>
      <Main>
        <LogoHeader />

        <Spinner label='Creating your account...' />

        <TermsOfService />
      </Main>
    </Page>
  )
})
