import { Spinner } from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { Redirect, Route, useRouteMatch } from 'react-router-dom'

import StoreService from '@/store/StoreService'

import { GameContextProvider } from './context/game-context'
import { GameControlsRoute } from './game-controls'
import { LobbyRoute } from './lobby'
import { AccountSettingsGameLevelRoute } from './settings/account-settings-game-level'
import { BrandingSettingsGameLevelRoute } from './settings/branding-settings-game-level'
import { GameSettingsRoute } from './settings/game-settings'
import { GameplaySettingsRoute } from './settings/gameplay-settings'
import { JumbotronSettingsRoute } from './settings/jumbotron-settings'
import { PlaybackSettingsRoute } from './settings/playback-settings'
import { VenueSettingsRoute } from './settings/venue-settings'
import { SetupDevicesRoute } from './setup/setup-devices'
import { SetupGameRoute } from './setup/setup-game'
import { SetupPlaybackRoute } from './setup/setup-playback'
import { JumbotronDefaultVideoRoute } from '../settings/components/jumbotron/JumbotronDefaultVideo'

export const GameRoute = observer(function GameRoute(): React.ReactElement {
  const { path } = useRouteMatch()

  const { currentGame, isLoadingGame } = StoreService.getStore()

  if (!currentGame && isLoadingGame) return <Spinner label='Loading...' />

  if (!currentGame) {
    return <Redirect to='/' />
  }

  return (
    <GameContextProvider>
      <Route path={`${path}/setup/playback`}>
        <SetupPlaybackRoute />
      </Route>

      <Route path={`${path}/setup/devices`}>
        <SetupDevicesRoute />
      </Route>

      <Route path={`${path}/setup/gameplay`}>
        <SetupGameRoute />
      </Route>

      <Route path={`${path}/lobby/:setup?`}>
        <LobbyRoute backUrl={`/game`} />
      </Route>

      <Route path={`${path}/settings/account`} exact>
        <AccountSettingsGameLevelRoute />
      </Route>

      <Route path={`${path}/settings/gameplay`} exact>
        <GameplaySettingsRoute />
      </Route>

      <Route path={`${path}/settings/playback`} exact>
        <PlaybackSettingsRoute />
      </Route>

      <Route path={`${path}/settings/jumbotron`} exact>
        <JumbotronSettingsRoute />
      </Route>

      <Route path={`${path}/settings/jumbotron/video`} exact>
        <JumbotronDefaultVideoRoute />
      </Route>

      <Route path={`${path}/settings/branding`} exact>
        <BrandingSettingsGameLevelRoute />
      </Route>

      <Route path={`${path}/settings/venue`} exact>
        <VenueSettingsRoute />
      </Route>

      <Route path={`${path}/settings`} exact>
        <GameSettingsRoute />
      </Route>

      <Route path={path} exact>
        <GameControlsRoute />
      </Route>
    </GameContextProvider>
  )
})
