import { Spinner } from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'

import GameStoreService from '@/store/game/GameStoreService'
import StoreService from '@/store/StoreService'

import { GameContext, GameContextProviderProps } from './game-context'

export const CurrentGameContextProvider = observer(
  function CurrentGameContextProvider({
    children,
  }: GameContextProviderProps): React.ReactElement {
    useEffect(() => {
      GameStoreService.subscribe()

      return () => {
        GameStoreService.unsubscribe()
      }
    }, [])

    const { currentGame } = StoreService.getStore()

    if (!currentGame) {
      return <Spinner label='Loading game data...' />
    }

    return (
      <GameContext.Provider value={currentGame}>
        {children}
      </GameContext.Provider>
    )
  }
)
