import * as Sentry from '@sentry/react'

export function initializeSentry() {
  Sentry.init({
    dsn: 'https://56efe5bddf56881804bed148ff1712ec@o4506248165588992.ingest.sentry.io/4506248173453312',
    environment: import.meta.env.VITE_ENV,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost'],

        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}
