import * as Sentry from '@sentry/react'
import { useQuery } from 'react-query'

import StoreService from '@/store/StoreService'
import { toErrorWithMessage } from '@/utils/get-error-message'

export function useQuerySystemPlaylist(playlistId: string) {
  const { playlistService } = StoreService.getStore()

  const { queryKey, queryFn } = playlistService.queries.systemPlaylist

  return useQuery(queryKey(playlistId), () => queryFn(playlistId), {
    onError: (caughtError) => {
      const error = toErrorWithMessage(caughtError)
      console.error(error)
      Sentry.captureException(error)
      return error
    },
  })
}
