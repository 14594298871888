import {
  AuthProvider,
  ButtonGroup,
  Spinner,
} from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useLocation } from 'react-router-dom'

import { AuthButton, AuthType } from '@/authentication/auth-button'
import { Button } from '@/components/Button'
import { useDialogContext } from '@/dialogs/DialogContext'

import { SpotifyProfileBadge } from './SpotifyProfileBadge'
import { useSpotify } from '../useSpotify'

type SpotifyAuthButtonProps = {
  disconnectVariant?: 'anchor' | 'outlined'
  profileBadgeSize?: 'small' | 'large'
  showDisconnect?: boolean
  showSwitchAccount?: boolean
  switchVariant?: 'anchor' | 'outlined'
}

export const SpotifyAuthButton = observer(function SpotifyAuthButton({
  disconnectVariant = 'anchor',
  profileBadgeSize = 'large',
  showDisconnect = false,
  showSwitchAccount = true,
  switchVariant = 'anchor',
}: SpotifyAuthButtonProps): React.ReactElement {
  const spotify = useSpotify()
  const { isAuthenticated, isConnecting } = spotify.auth

  const location = useLocation()

  const { setConfirmPrompt } = useDialogContext()

  const handleClickDisconnectSpotify = async () => {
    setConfirmPrompt({
      heading: 'Are you sure you want to disconnect from Spotify?',
      description: `You'll need to reconnect afterwards to use Spotify features`,
      onConfirm: () => spotify.disconnect(),
    })
  }

  const handleClickSwitchSpotifyAccount = async () => {
    setConfirmPrompt({
      heading: 'Are you sure you want to switch Spotify accounts?',
      description: `Choose a different Spotify account via Spotify's login screen`,
      onConfirm: async () => {
        await spotify.switchAccount({ currentUrl: location.pathname })
      },
    })
  }

  if (isAuthenticated) {
    return (
      <div className='flex flex-col gap-6'>
        <SpotifyProfileBadge size={profileBadgeSize} />

        <ButtonGroup direction='column'>
          {showSwitchAccount && (
            <Button
              text='Switch Spotify Account'
              variant={switchVariant}
              onClick={handleClickSwitchSpotifyAccount}
            />
          )}

          {showDisconnect && (
            <Button
              text='Disconnect Spotify'
              variant={disconnectVariant}
              onClick={handleClickDisconnectSpotify}
            />
          )}
        </ButtonGroup>
      </div>
    )
  }

  if (isConnecting) {
    return <Spinner label='Connecting to Spotify...' />
  }

  return (
    <AuthButton
      authType={AuthType.CONNECT}
      provider={AuthProvider.SPOTIFY}
      onClick={() => spotify.connect({ currentUrl: location.pathname })}
    />
  )
})
