import {
  ChevronLeftIcon,
  ChevronRightIcon,
  merge,
} from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React, { useRef, useState } from 'react'
import styled from 'styled-components'

import { Tab, TabProps } from './Tab'
import { useMeasureElement } from '../../hooks/useMeasureElement'
import { focusOutlineStyles } from '../StyledComponents'

interface TabGroupProps {
  className?: string
  tabs: TabProps[]
}

export const TabGroup = observer(function TabGroup({
  className,
  tabs,
}: TabGroupProps): React.ReactElement {
  const tabScrollableContainerRef = useRef<HTMLDivElement>(null)
  const tabsRef = useRef<HTMLDivElement | null>(null)
  const [isScrollable, setIsScrollable] = useState<boolean>(false)

  const scrollTabContainer = (direction: 'forward' | 'backward') => {
    if (!tabScrollableContainerRef.current) return

    const element = tabScrollableContainerRef.current
    const currentXPosition = element.scrollLeft
    const scrollDistance = 240

    let xDelta = 0
    switch (direction) {
      case 'forward':
        xDelta = currentXPosition + scrollDistance
        break
      case 'backward':
        xDelta = currentXPosition - scrollDistance
        break
    }

    element.scroll({
      left: xDelta,
      behavior: 'smooth',
    })
  }

  const handlePrevClick = () => {
    scrollTabContainer('backward')
  }

  const handleNextClick = () => {
    scrollTabContainer('forward')
  }

  const tabElements: React.ReactElement[] = tabs.map((tabInfo) => {
    return <Tab {...tabInfo} key={tabInfo.id} />
  })

  const measureTabContainer = useMeasureElement((values) => {
    const tabContainerWidth = Math.round(values.width)
    const tabsWidth = tabsRef?.current?.scrollWidth || tabContainerWidth
    setIsScrollable(tabsWidth > tabContainerWidth)
  })

  return (
    <TabContainerStyled className={merge('tabs', className)}>
      <div className='tab-container' ref={measureTabContainer}>
        {isScrollable && (
          <>
            <TabArrowButtonStyled onClick={handlePrevClick}>
              <ChevronLeftIcon />
            </TabArrowButtonStyled>
            <div
              className='tab-container--scrollable'
              ref={tabScrollableContainerRef}
            >
              <div className='tab-container--inner' ref={tabsRef}>
                {tabElements}
              </div>
            </div>
            <TabArrowButtonStyled onClick={handleNextClick}>
              <ChevronRightIcon />
            </TabArrowButtonStyled>
          </>
        )}

        {!isScrollable && (
          <>
            <div className='tab-container--inner' ref={tabsRef}>
              {tabElements}
            </div>
          </>
        )}
      </div>
    </TabContainerStyled>
  )
})

const TabArrowButtonStyled = styled.button`
  color: var(--text);
  background: transparent;

  ${focusOutlineStyles}
`

const TabContainerStyled = styled.div`
  --tab-width: 160px;
  --tab-height: var(--app-header-height, 64px);
  --tab-arrow-button-width: var(--app-header-item-size, 40px);

  &.tabs {
    position: sticky;
    top: 0;
    background: var(--bg-00);
    transition: all 0.3s ease-in-out;
    padding: 0;

    .tab-container {
      display: flex;
      overflow: hidden;
      width: 100%;
      height: var(--tab-height);
      position: relative;
    }

    .tab-container--scrollable {
      width: calc(100% - (var(--tab-arrow-button-width) * 2));
      height: 100%;
      overflow: hidden;
      overflow-x: scroll;

      &::-webkit-scrollbar {
        display: none;
      }

      scrollbar-width: none;
    }

    .tab-container--inner {
      flex: 1;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(var(--tab-width), 1fr));
      grid-auto-flow: column;
    }

    .tab-arrow {
      position: absolute;
      top: 0;
      z-index: 100;
      width: var(--tab-arrow-button-width);
      min-width: var(--tab-arrow-button-width);
      max-width: var(--tab-arrow-button-width);
      height: 100%;
      border-bottom: 2px solid var(--border-01);
      border-right: 0px;
      border-left: 0px;
      border-radius: 0px;
      outline: 0;

      &:active,
      &:focus {
        border-bottom-color: var(--primary-500);
        outline: 0;
        box-shadow: none;
        border-right: 0px;
        border-left: 0px;
        border-top: 1px solid transparent;
      }

      ${focusOutlineStyles}

      i {
        color: var(--text) !important;
      }
    }

    .tab-arrow--prev {
      left: 0;
    }

    .tab-arrow--next {
      right: 0;
    }
  }
`
