import { Column, DrawnItem } from '@matthewlongpre/music-bingo-common'
import { forwardRef } from 'react'

interface PreviousDrawnItemProps {
  drawnItem: DrawnItem
}

export const PreviousDrawnItem = forwardRef<
  HTMLDivElement,
  PreviousDrawnItemProps
>(({ drawnItem }: PreviousDrawnItemProps, ref) => {
  return (
    <div
      className='flex flex-col items-center justify-center flex-shrink-0 w-16 h-16 md:w-24 md:h-24'
      ref={ref}
    >
      <div className='flex w-full flex-1 items-center justify-center bg-shade-1 border-2 border-orange-500 rounded-tl-lg rounded-tr-lg font-bold text-xl'>
        <span className='-mt-[2.5px]'>{Column[drawnItem.column]}</span>
      </div>
      <div className='flex w-full flex-1 items-center justify-center bg-shade-2 rounded-bl-lg rounded-br-lg font-bold text-xl'>
        <span className='-mt-1'>{drawnItem.id}</span>
      </div>
    </div>
  )
})

PreviousDrawnItem.displayName = 'PreviousDrawnItem'
