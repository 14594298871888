import { merge, Heading, Description } from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React from 'react'

interface SettingsRowProps {
  children: React.ReactNode
  className?: string
  description: React.ReactNode
  heading: React.ReactNode
}

export const SettingsRow = observer(function SettingsRow({
  children,
  className,
  description,
  heading,
}: SettingsRowProps): React.ReactElement {
  return (
    <div
      className={merge(
        'p-4 md:p-6 border-b last:border-b-0 border-shade-3',
        className
      )}
    >
      <div className='flex flex-col xs:flex-row gap-2 xs:gap-6 justify-between'>
        <div className='flex flex-col gap-2'>
          <Heading>{heading}</Heading>
          <Description>{description}</Description>
        </div>

        <div className='flex items-center justify-end max-w-xs'>{children}</div>
      </div>
    </div>
  )
})
