import {
  Container,
  Heading,
  TriangleExclamationIcon,
} from '@matthewlongpre/music-bingo-common'

import { useTrackPageView } from '@/hooks/useTrackPageView'

export function NetworkConnectivityWarning() {
  useTrackPageView('Network Connectivity Warning')

  return (
    <div className='game:text-white flex h-full flex-1 flex-col items-center justify-center py-24 text-center'>
      <Container size='prose'>
        <TriangleExclamationIcon className='m-auto mb-6 h-12 w-12 ' />

        <Heading className='mx-auto mb-10 max-w-[13ch]' size='extra-large'>
          You&apos;re offline
        </Heading>

        <p className='px-5 text-center text-xl lg:text-2xl'>
          Please check your internet connection.
        </p>
      </Container>
    </div>
  )
}
