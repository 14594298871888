import {
  Panel,
  Heading,
  Container,
  Description,
} from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React, { ChangeEvent } from 'react'

import { AppHeader } from '@/components/AppHeader'
import { Button } from '@/components/Button'
import { BackButton } from '@/components/icon-buttons/IconButtons'
import { Main } from '@/components/Main'
import { Page } from '@/components/Page'
import { PageHeading } from '@/components/PageHeading'
import { TermsOfService } from '@/components/TermsOfService'
import { TipBadge } from '@/components/TipBadge'
import { MINIMUM_ITEM_COUNT } from '@/utils/constants'

interface ImportSpotifyPlaylistUrlViewProps {
  baseUrl?: string
  isLoading: boolean
  onCancel: () => void
  onPlaylistUrlInputChange: (event: ChangeEvent<HTMLInputElement>) => void
  onSubmit: () => Promise<void>
  playlistUrl: string
}

export const ImportSpotifyPlaylistUrlView = observer(
  function ImportSpotifyPlaylistUrlView({
    baseUrl = '/',
    isLoading,
    onPlaylistUrlInputChange,
    onCancel,
    onSubmit,
    playlistUrl,
  }: ImportSpotifyPlaylistUrlViewProps): React.ReactElement {
    return (
      <Page title='Import Playlist URL'>
        <AppHeader
          actionsLeft={
            <BackButton url={`${baseUrl}playlists/create/import-spotify`} />
          }
          header={<PageHeading>Import Playlist URL</PageHeading>}
        />

        <Main>
          <Container className='mb-12' size='medium'>
            <Panel>
              <TipBadge />
              <Description className='mt-2'>
                Playlists must have a minimum of {MINIMUM_ITEM_COUNT} songs.
              </Description>
            </Panel>
          </Container>

          <Container className='mt-6' size='medium'>
            <Heading>Playlist URL</Heading>

            <Description className='mt-2 mb-4'>
              Paste any playlist URL from Spotify. Does not need to be in your
              personal library.
            </Description>

            <div className='grid grid-cols-default gap-4'>
              <input
                className='input'
                type='text'
                value={playlistUrl}
                onChange={onPlaylistUrlInputChange}
              />

              <Button
                disabled={!playlistUrl || isLoading}
                isLoading={isLoading}
                text='Get Playlist'
                type='button'
                onClick={onSubmit}
              />
            </div>

            {playlistUrl && (
              <Button text='Cancel' variant='anchor' onClick={onCancel} />
            )}
          </Container>

          <TermsOfService />
        </Main>
      </Page>
    )
  }
)
