import { ISpotifyFeatures } from '@matthewlongpre/music-bingo-common'

export interface ISpotifyDevice {
  id: string
  is_active: boolean
  is_private_session: boolean
  is_restricted: boolean
  name: string
  type: string
  volume_percent: number
}

export type SpotifyToken = string
type SpotifyRefreshToken = string

export interface ISpotifyTokenResponse {
  access_token: SpotifyToken
  expires_in: number
  profile: ISpotifyProfile
  refresh_token?: SpotifyRefreshToken
  scope: string
  token_type: string
}

interface ISpotifyArtist {
  external_urls: { spotify: string }
  href: string
  id: string
  name: string
  type: string
  uri: string
}

export interface ISpotifySearchTracksResponse {
  href: string
  limit: number
  next: string | null
  offset: number
  previous: string | null
  total: number
  tracks: {
    items: ISpotifyTrack[]
  }
}

export interface ISpotifyTrack {
  album: { name: string }
  artists: ISpotifyArtist[]
  duration_ms: number
  features?: ISpotifyFeatures
  href: string
  id: string
  name: string
  preview_url: string
  track: boolean
  track_number: number
  type: string
  uri: string
}

export interface ISpotifyPlaylist {
  [key: string]: unknown

  collaborative: boolean
  description: string
  external_urls: { spotify: string }
  href: string
  id: string
  images: ISpotifyPlaylistImage[]
  name: string
  owner: {
    display_name: string
    external_urls: { spotify: string }
    href: string
    id: string
    type: string
  }
  primary_color: null
  public: true
  snapshot_id: string
  total: number
  tracks: ISpotifyPlaylistTracksResponse
  type: string
  uri: string
}

export interface ISpotifyPlaylistImage {
  height: number
  url: string
  width: number
}

export interface ISpotifyPlaylistTracksResponse {
  href: string
  items: ISpotifyPlaylistItem[]
  limit: number
  next: string | null
  offset: number
  previous: string | null
  total: number
}

export interface ISpotifyPlaylistItem {
  track: ISpotifyTrack
}

export interface ISpotifyErrorResponse {
  error: ISpotifyError
}

export interface ISpotifyError {
  message: string
  status: number
}

export enum SpotifyErrorMessage {
  NOT_FOUND = 'Not found.',
  BAD_GATEWAY = 'Bad gateway.',
  DEVICE_NOT_FOUND = 'Device not found',
  INVALID_AUTH_TOKEN = 'Invalid Spotify access token',
  INVALID_TRACK = 'Invalid track uri in payload',
  PREMIUM_REQUIRED = 'Player command failed: Premium required',
  SERVER_ERROR = 'Server error.',
}

export interface ISpotifyPlaylistResponse {
  href: string
  items: ISpotifyPlaylist[]
  next: string
  offset: number
  previous: string
  total: number
}

export interface ISpotifyProfile {
  display_name: string
  email: string
  href: string
  id: string
  images?: { url: string }[]
  product: 'open' | 'free' | 'premium'
  uri: string
}

export interface ISpotifyDeviceResponse {
  devices: ISpotifyDevice[]
}

export enum SpotifyAPIErrors {
  PLAYBACK_ALREADY_PAUSED = 'Player command failed: Restriction violated',
}

export interface ISpotifyPlaybackState {
  currently_playing_type: 'track' | 'episode' | 'ad' | 'unknown'
  device: ISpotifyDevice
  is_playing: boolean
  item: ISpotifyTrack
  progress_ms: number
  repeat_state: 'off' | 'track' | 'context'
  shuffle_state: boolean
  timestamp: number
}

export enum SpotifyDevice {
  COMPUTER = 'Computer',
  TABLET = 'Tablet',
  SMARTPHONE = 'Smartphone',
  SPEAKER = 'Speaker',
  TV = 'TV',
  AVR = 'AVR',
  STB = 'STB',
  AUDIODONGLE = 'AudioDongle',
  GAMECONSOLE = 'GameConsole',
  CASTVIDEO = 'CastVideo',
  CASTAUDIO = 'CastAudio',
  AUTOMOBILE = 'Automobile',
  UNKNOWN = 'Unknown',
}
