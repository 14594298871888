import { Container, Song } from '@matthewlongpre/music-bingo-common'

import {
  PlaybackAction,
  PlaybackButton,
  PlaybackButtonVariant,
} from '@/components/PlaybackButton'
import { FINAL_ITEM_INDEX } from '@/utils/constants'

interface PlaybackButtonsProps {
  currentItemIndex: number | null
  handleClickNext: () => void
  handleClickPause: () => void
  handleClickPlay: () => void
  handleClickPrevious: () => void
  isPlaybackDisabled: boolean
  isPlaying: boolean
  selectedTrack: Song | null
}

export function PlaybackButtons({
  handleClickNext,
  handleClickPause,
  handleClickPlay,
  handleClickPrevious,
  isPlaybackDisabled,
  isPlaying,
  selectedTrack,
  currentItemIndex,
}: PlaybackButtonsProps) {
  return (
    <Container className='flex items-center justify-between' size='extra-small'>
      <PlaybackButton
        actionType={PlaybackAction.PREVIOUS}
        disabled={isPlaybackDisabled || currentItemIndex === 0}
        onClick={handleClickPrevious}
      />

      {isPlaying && (
        <PlaybackButton
          actionType={PlaybackAction.PAUSE}
          disabled={isPlaybackDisabled || !selectedTrack}
          variant={PlaybackButtonVariant.LARGE}
          onClick={handleClickPause}
        />
      )}

      {!isPlaying && (
        <PlaybackButton
          actionType={PlaybackAction.PLAY}
          disabled={isPlaybackDisabled || !selectedTrack}
          variant={PlaybackButtonVariant.LARGE}
          onClick={handleClickPlay}
        />
      )}

      <PlaybackButton
        actionType={PlaybackAction.NEXT}
        disabled={isPlaybackDisabled || currentItemIndex === FINAL_ITEM_INDEX}
        onClick={handleClickNext}
      />
    </Container>
  )
}
