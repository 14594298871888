import { observer } from 'mobx-react-lite'
import React from 'react'

interface FormErrorProps {
  error: string
}

export const FormError = observer(function FormError({
  error,
}: FormErrorProps): React.ReactElement | null {
  if (!error) return null

  return (
    <div className='bg-red-500 text-red-100 rounded-lg border-red-500 flex flex-col gap-2 py-4 px-6'>
      {error}
    </div>
  )
})
