import {
  Heading,
  merge,
  MiniBingoCard,
  Pattern,
  TargetDisplay,
} from '@matthewlongpre/music-bingo-common'
import * as Sentry from '@sentry/react'
import { observer } from 'mobx-react-lite'
import React from 'react'

import { apiClient } from '@/api/api-client'
import { Select } from '@/components/shared/Select'
import { useDialogContext } from '@/dialogs/DialogContext'
import { useGameContext } from '@/pages/game/context/game-context'
import { getErrorMessage } from '@/utils/get-error-message'

interface BingoTargetSettingsProps {
  className?: string
  disableConfirmPrompt?: boolean
  disableMessageQueue?: boolean
}

export const BingoTargetSettings = observer(function BingoTargetSettings({
  className,
  disableConfirmPrompt,
  disableMessageQueue,
}: BingoTargetSettingsProps): React.ReactElement {
  return (
    <div
      className={merge(
        'p-4 md:p-6 border-b last:border-b-0 border-shade-3',
        className
      )}
    >
      <Heading className='mb-2'>Bingo Target</Heading>
      <BingoTargetSelector
        disableConfirmPrompt={disableConfirmPrompt}
        disableMessageQueue={disableMessageQueue}
      />
    </div>
  )
})

interface BingoTargetSelectorProps {
  disableConfirmPrompt?: boolean
  disableMessageQueue?: boolean
}

const BingoTargetSelector = observer(function BingoTargetSelector({
  disableConfirmPrompt = false,
  disableMessageQueue = false,
}: BingoTargetSelectorProps): React.ReactElement {
  const { setConfirmPrompt, setErrorDialog } = useDialogContext()

  const { gameData, gameId, tracker } = useGameContext()

  const { currentTarget } = gameData

  const updateTarget = async (target: Pattern) => {
    try {
      await apiClient.updateTarget(gameId, target, disableMessageQueue)
      tracker.changeTarget(target)
    } catch (error) {
      setErrorDialog({ error: getErrorMessage(error) })
      Sentry.captureException(error)
    }
  }

  const handleChange = async (target: Pattern) => {
    if (disableConfirmPrompt) {
      await updateTarget(target)
    } else {
      setConfirmPrompt({
        heading:
          'Are you sure you want to change the pattern that guests are looking for?',
        description: 'Guests will receive an update on their devices.',
        onConfirm: async () => await updateTarget(target),
      })
    }
  }

  return (
    <>
      <Select
        options={[
          {
            id: Pattern.ONE_LINE,
            value: Pattern.ONE_LINE,
            name: 'One Line',
            description: 'At least one line in any direction',
            graphic: <MiniBingoCard pattern={Pattern.ONE_LINE} size='small' />,
          },
          {
            id: Pattern.TWO_LINES,
            value: Pattern.TWO_LINES,
            name: 'Two Lines',
            description: 'At least two lines in any direction',
            graphic: <MiniBingoCard pattern={Pattern.TWO_LINES} size='small' />,
          },
          {
            id: Pattern.THREE_LINES,
            value: Pattern.THREE_LINES,
            name: 'Three Lines',
            description: 'At least three lines in any direction',
            graphic: (
              <MiniBingoCard pattern={Pattern.THREE_LINES} size='small' />
            ),
          },
          {
            id: Pattern.FOUR_LINES,
            value: Pattern.FOUR_LINES,
            name: 'Four Lines',
            description: 'At least four lines in any direction',
            graphic: (
              <MiniBingoCard pattern={Pattern.FOUR_LINES} size='small' />
            ),
          },
          {
            id: Pattern.CORNERS,
            value: Pattern.CORNERS,
            name: 'Four Corners',
            description: 'Each corner square on the card',
            graphic: <MiniBingoCard pattern={Pattern.CORNERS} size='small' />,
          },
          {
            id: Pattern.EDGES,
            value: Pattern.EDGES,
            name: 'Edges',
            description: 'Four lines around the outer border of the card',
            graphic: <MiniBingoCard pattern={Pattern.EDGES} size='small' />,
          },
          {
            id: Pattern.X_SHAPE,
            value: Pattern.X_SHAPE,
            name: 'X Shape',
            description: 'Both diagonal lines on the card',
            graphic: <MiniBingoCard pattern={Pattern.X_SHAPE} size='small' />,
          },
          {
            id: Pattern.FULL_CARD,
            value: Pattern.FULL_CARD,
            name: 'Full Card',
            description: 'Every square on the card',
            graphic: <MiniBingoCard pattern={Pattern.FULL_CARD} size='small' />,
          },
        ]}
        value={currentTarget}
        onChange={(target: Pattern) => void handleChange(target)}
      />
      <div className='mt-6 text-center flex items-center justify-center'>
        <TargetDisplay pattern={currentTarget} size='medium' />
      </div>
    </>
  )
})
