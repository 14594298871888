import { Device } from '@matthewlongpre/music-bingo-common'

import { ISpotifyDevice } from '@/store/integrations/spotify/types'

import { SPOTIFY_PLAYER_DEVICE_NAME } from '../player/SpotifyPlayerService'

export default function convertDevice(spotifyDevice: ISpotifyDevice): Device {
  return {
    id: spotifyDevice.id,
    isActive: spotifyDevice.is_active,
    isExternal: !spotifyDevice.name.startsWith(SPOTIFY_PLAYER_DEVICE_NAME),
    name: spotifyDevice.name,
    type: spotifyDevice.type,
  }
}
