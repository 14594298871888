import {
  Container,
  Panel,
  UserBrandingSettings,
} from '@matthewlongpre/music-bingo-common'
import * as Sentry from '@sentry/react'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { AppHeader } from '@/components/AppHeader'
import { BackButton, CloseButton } from '@/components/icon-buttons/IconButtons'
import { Main } from '@/components/Main'
import { Page } from '@/components/Page'
import { PageHeading } from '@/components/PageHeading'
import { compareChanges } from '@/mixpanel/utils'
import StoreService from '@/store/StoreService'

import { MixpanelService } from '../../../mixpanel'
import { BrandingSettingsForm } from '../components/BrandingSettingsForm'

export const BrandingSettingsRoute = observer(
  function BrandingSettingsRoute(): React.ReactElement {
    return <BrandingSettings />
  }
)

const BrandingSettings = observer(
  function BrandingSettings(): React.ReactElement {
    const { user } = StoreService.getStore()

    const initialValues = user?.settings?.branding ?? {}

    const history = useHistory()

    const handleSubmit = async (values: UserBrandingSettings) => {
      await user.updateBrandingSettings(values)

      try {
        MixpanelService.track(
          'Branding Changed',
          compareChanges(initialValues, values)
        )
      } catch (error) {
        Sentry.captureException(error)
      }
    }

    const handleClickBack = () => {
      history.goBack()
    }

    const handleClickClose = () => {
      history.push('/')
    }

    const handleSuccess = () => {
      history.push('/settings')
    }

    return (
      <Page title='Branding Settings'>
        <AppHeader
          actionsLeft={<BackButton onClick={handleClickBack} />}
          actionsRight={<CloseButton onClick={handleClickClose} />}
          header={<PageHeading>Branding Settings</PageHeading>}
        />

        <Main>
          <Container className='flex flex-col gap-8' size='medium'>
            <Panel>
              <BrandingSettingsForm
                initialValues={initialValues}
                onSubmit={handleSubmit}
                onSuccess={handleSuccess}
              />
            </Panel>
          </Container>
        </Main>
      </Page>
    )
  }
)
