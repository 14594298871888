import { CommonProps } from '@matthewlongpre/music-bingo-common'
import * as Sentry from '@sentry/react'
import { observer } from 'mobx-react-lite'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import { hasDocument } from '../utils/check-document'
import { hasLocalStorage } from '../utils/check-local-storage'
import { hasWindow } from '../utils/check-window'

const DEFAULT_THEME = 'dark'
const LOCAL_STORAGE_KEY = 'theme_colour_preference'

export type ColourMode = 'light' | 'dark'

const isColourMode = (input?: string | null): input is ColourMode => {
  if (!input) return false
  return ['light', 'dark'].includes(input)
}

export type ColourPreference = 'auto' | 'light' | 'dark'

const isColourPreference = (
  input?: string | null
): input is ColourPreference => {
  if (!input) return false
  return ['light', 'dark', 'auto'].includes(input)
}

interface IThemeContext {
  colourMode: ColourMode
  colourPreference: ColourPreference
  setColourPreference: (colourPreference: ColourPreference) => void
}

const baseContext: IThemeContext = {
  colourMode: getInitialColourMode(),
  colourPreference: DEFAULT_THEME,
  setColourPreference: () => undefined,
}

const ThemeContext = createContext<IThemeContext>(baseContext)

export function useThemeContext(): IThemeContext {
  const context = useContext(ThemeContext)

  if (context === undefined) {
    throw new Error(
      'useThemeContext must be used within a ThemeContextProvider'
    )
  }

  return context
}

function getStoredColourModePreference(): ColourPreference {
  if (!hasLocalStorage) return DEFAULT_THEME

  const colourPreference = localStorage.getItem(LOCAL_STORAGE_KEY)
  if (!isColourPreference(colourPreference)) return DEFAULT_THEME

  return colourPreference
}

function getSystemColourMode(): ColourMode {
  const prefersDarkSystem =
    hasWindow && window.matchMedia('(prefers-color-scheme: dark)').matches

  return prefersDarkSystem ? 'dark' : 'light'
}

function getInitialColourMode(): ColourMode {
  const storedColourModePreference = getStoredColourModePreference()

  if (isColourMode(storedColourModePreference)) {
    return storedColourModePreference
  }

  return getSystemColourMode()
}

function setColourModeClass(colourMode: ColourMode): void {
  if (!hasDocument) return

  const html = document.documentElement

  html.classList.remove('light', 'dark')
  html.classList.add(colourMode)
}

export const ThemeContextProvider = observer(function ThemeContextProvider({
  children,
}: CommonProps): React.ReactElement {
  const [systemColourMode, setSystemColourMode] = useState(
    getSystemColourMode()
  )
  const [colourPreference, setColourPreference] = useState<ColourPreference>(
    getStoredColourModePreference()
  )

  const handleSystemColourChange = useCallback(
    (event: Event) => {
      if (!(event instanceof MediaQueryListEvent)) return

      const updatedColourMode = event.matches ? 'dark' : 'light'

      if (updatedColourMode !== systemColourMode) {
        setSystemColourMode(updatedColourMode)
      }
    },
    [systemColourMode, setSystemColourMode]
  )

  const getColourMode = useCallback(() => {
    if (isColourMode(colourPreference)) return colourPreference
    return systemColourMode
  }, [colourPreference, systemColourMode])

  useEffect(() => {
    if (!hasWindow) return

    const darkModeQuery = window.matchMedia('(prefers-color-scheme: dark)')

    try {
      darkModeQuery.addEventListener('change', handleSystemColourChange)
    } catch (firstError) {
      try {
        darkModeQuery.addListener(handleSystemColourChange)
      } catch (secondError) {
        console.error(secondError)
        Sentry.captureException(secondError)
      }
    }

    return () => {
      if (!hasWindow) return

      try {
        window.removeEventListener('change', handleSystemColourChange)
      } catch (firstError) {
        try {
          darkModeQuery.removeListener(handleSystemColourChange)
        } catch (secondError) {
          console.error(secondError)
          Sentry.captureException(secondError)
        }
      }
    }
  }, [handleSystemColourChange])

  useEffect(() => {
    setColourModeClass(systemColourMode)
  }, [systemColourMode])

  useEffect(() => {
    if (!isColourPreference(colourPreference)) return

    const colourMode = getColourMode()

    localStorage.setItem(LOCAL_STORAGE_KEY, colourPreference)
    setColourModeClass(colourMode)
  }, [colourPreference, getColourMode])

  return (
    <ThemeContext.Provider
      value={{
        ...baseContext,
        colourMode: getColourMode(),
        colourPreference,
        setColourPreference,
      }}
    >
      {children}
    </ThemeContext.Provider>
  )
})
