import { MessageEvent, MessageType } from '@matthewlongpre/music-bingo-common'

export function getMessageType(currentMessage: MessageEvent) {
  const isDeniedBingo =
    currentMessage?.message.type === MessageType.DENIED_BINGO

  const isConfirmedBingo =
    currentMessage?.message.type === MessageType.CONFIRMED_BINGO

  const isBingoResult = isConfirmedBingo || isDeniedBingo

  const isContactRequest =
    currentMessage?.message.type === MessageType.CONTACT_DETAILS_REQUEST

  return {
    isBingoResult,
    isContactRequest,
  }
}
