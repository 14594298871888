import { Collections, Game } from '@matthewlongpre/music-bingo-common'
import { doc, onSnapshot } from 'firebase/firestore'

import { databaseRef } from '@/firebase/firebase'

import SnapshotListener from './AbstractSnapshotListener'
import { GameStore } from '../GameStore'


class GameListener extends SnapshotListener {
  public subscribe(game: GameStore): void {
    const docRef = doc(databaseRef, Collections.GAMES, game.gameId)

    this._unsubscribe = onSnapshot(
      docRef,
      (snapshot) => {
        const gameData = snapshot.data() as Game
        game.apply({ gameData })
      },
      (error) => {
        console.error(`Encountered error: ${error.message}`)
      }
    )
  }
}

export const gameListener = new GameListener()
