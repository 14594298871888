import { makeAutoObservable } from 'mobx'

import { normalizedCompare } from '@/pages/playlists/playlist-editor/duplicate-checker/utils/normalizedCompare'
import StoreService from '@/store/StoreService'
import { applyProperties } from '@/store/utils'
import { isDrawnSong } from '@/utils/type-guards'

import { IntegrationPlayerStore } from '../..'
import { convertArtist } from '../converters/convertArtist'
import { convertSongTitle } from '../converters/convertSongTitle'

export class SpotifyPlayerStore implements IntegrationPlayerStore {
  _playerElement?: Spotify.Player

  public currentPlaybackTime = 0
  public currentPlaybackDuration = 0
  public currentTrack?: Spotify.Track
  public timer?: number

  public deviceId?: string

  public isPlaying = false
  public isPaused = false
  public isLoading = false

  public constructor() {
    makeAutoObservable(this)
  }

  private get store() {
    return StoreService.getStore()
  }

  private get spotify() {
    return this.store.spotify
  }

  public get playerElement() {
    if (!this._playerElement) throw new Error('Player has not been initialized')
    return this._playerElement
  }

  public get progressPercent(): string {
    if (!this.currentPlaybackDuration || !this.currentPlaybackTime) return '0%'
    return `${(this.currentPlaybackTime / this.currentPlaybackDuration) * 100}%`
  }

  public get isActiveDevice(): boolean {
    if (!this.deviceId) return false

    return Boolean(this.spotify.devices.activeDevice?.id === this.deviceId)
  }

  public activateElement() {
    console.log('Activating player element...')
    return this.playerElement.activateElement()
  }

  public startTimer = (): void => {
    this.timer = window.setInterval(
      () => this.apply({ currentPlaybackTime: this.currentPlaybackTime + 1 }),
      1000
    )
  }

  public stopTimer = (): void => {
    clearInterval(this.timer)
    this.timer = undefined
  }

  public resetTrack = (): void => {
    this.apply({
      currentPlaybackTime: 0,
      currentPlaybackDuration: 0,
      currentTrack: undefined,
    })
    this.stopTimer()
  }

  apply(values: Partial<SpotifyPlayerStore>) {
    applyProperties(this, values)
  }

  get isInternalPlayerOutOfSync() {
    try {
      if (
        this.isLoading ||
        this.spotify.player.isLoading ||
        this.spotify.playback.isLoading
      )
        return false

      const { currentDrawnItem } = this.store.currentGame ?? {}

      if (!isDrawnSong(currentDrawnItem) || !this.currentTrack) {
        return false
      }

      const songFromPlaylist = currentDrawnItem.song
      const songFromWebPlayer = {
        title: convertSongTitle(this.currentTrack.name),
        artist: convertArtist(this.currentTrack.artists),
      }

      if (!songFromWebPlayer || !songFromPlaylist) return false

      return (
        !normalizedCompare(songFromWebPlayer.title, songFromPlaylist.title) &&
        !normalizedCompare(songFromWebPlayer.artist, songFromPlaylist.artist)
      )
    } catch {
      return false
    }
  }
}
