import { ColourPalette, Playlist } from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React from 'react'

import { ImportSpotifyUrlContainer } from './import-spotify-url-container'
import { useCreateSystemPlaylist } from '../../playlist-queries/system-playlists/useCreateSystemPlaylist'
import { useCreateUserPlaylist } from '../../playlist-queries/user-playlists/useCreateUserPlaylist'

export const ImportSpotifyPlaylistUrlRoute = observer(
  function ImportSpotifyPlaylistUrlRoute(): React.ReactElement {
    const { mutateAsync: createUserPlaylist } = useCreateUserPlaylist()

    const handleSubmit = async (playlist: Playlist) => {
      return createUserPlaylist({
        ...playlist,
        id: '',
        colour: ColourPalette.PURPLE,
      })
    }

    return <ImportSpotifyUrlContainer onSubmit={handleSubmit} />
  }
)

export const ImportSpotifyPlaylistUrlRouteAdmin = observer(
  function ImportSpotifyPlaylistUrlRouteAdmin(): React.ReactElement {
    const { mutateAsync: createSystemPlaylist } = useCreateSystemPlaylist()

    const handleSubmit = async (playlist: Playlist) => {
      return createSystemPlaylist({
        ...playlist,
        id: '',
        colour: ColourPalette.PURPLE,
      })
    }

    return (
      <ImportSpotifyUrlContainer baseUrl='/admin/' onSubmit={handleSubmit} />
    )
  }
)
