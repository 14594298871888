import {
  ButtonGroup,
  Container,
  Heading,
  Label,
  Panel,
} from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'

import { Button } from '@/components/Button'

import { LogoHeader } from './LogoHeader'
import { Logout } from './Logout'
import { Main } from './Main'
import { Page } from './Page'
import { PlanTypeBadge } from './PlanTypeBadge'
import { TermsOfService } from './TermsOfService'
import { createPortalLink } from '../billing'
import StoreService from '../store/StoreService'

const HEADING = {
  change: 'Are you trying to change your subscription?',
  purchase: 'Are you trying to purchase a subscription?',
}

export const SubscriptionDetails = observer(
  function SubscriptionDetails(): React.ReactElement {
    const { user } = StoreService.getStore()
    const type = user.isFree ? 'purchase' : 'change'

    const [isLoadingBillingPortal, setIsLoadingBillingPortal] = useState(false)

    const handleClickPortal = async () => {
      setIsLoadingBillingPortal(true)
      await createPortalLink()
    }

    return (
      <Page title='Subscription Details'>
        <Main>
          <LogoHeader />

          <Container size='small'>
            <Panel>
              <div className='flex flex-col gap-6'>
                <Heading
                  className='text-center max-w-[24ch] m-auto whitespace-normal'
                  size='large'
                >
                  {HEADING[type]}
                </Heading>

                <div>
                  <Label>Logged in as:</Label>
                  {user.email}
                </div>

                <div className='flex gap-4'>
                  <div className='flex flex-col gap-2'>
                    <Label>Plan type:</Label>
                    <PlanTypeBadge className='mr-auto' />
                  </div>

                  <div className='flex flex-col gap-2'>
                    <Label>Seats per game:</Label>
                    {user.maxPlayers}
                  </div>
                </div>

                <ButtonGroup direction='column'>
                  {!user.isFree && (
                    <Button
                      disabled={isLoadingBillingPortal}
                      isLoading={isLoadingBillingPortal}
                      text='Yes - Manage Subscription'
                      variant='outlined'
                      onClick={handleClickPortal}
                    />
                  )}

                  <Button className='mt-2' text='No - Go to App' to='/' />

                  <Heading className='mt-6'>Not your account?</Heading>
                  <Logout variant='anchor' />
                </ButtonGroup>
              </div>
            </Panel>
          </Container>

          <TermsOfService />
        </Main>
      </Page>
    )
  }
)
