import { Container, Heading, Label } from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useParams } from 'react-router-dom'

import { ActivePlayers } from '@/components/ActivePlayers'
import { AppFooter } from '@/components/AppFooter'
import { AppHeader } from '@/components/AppHeader'
import { BannerGameEnded } from '@/components/BannerGameEnded'
import { Button } from '@/components/Button'
import { DisplayGameCode } from '@/components/DisplayGameCode'
import { GameLobby } from '@/components/GameLobby'
import { BackButton, CloseButton } from '@/components/icon-buttons/IconButtons'
import { Main } from '@/components/Main'
import { Page } from '@/components/Page'
import { PageHeading } from '@/components/PageHeading'
import { PlayersLeftGame } from '@/components/PlayersLeftGame'
import { RemovedPlayers } from '@/components/RemovedPlayers'
import { AnimatedScoreboard } from '@/components/Scoreboard'
import { Section } from '@/components/Section'

import { MessageBanners } from './components/MessageBanners'
import { useGameContext } from './context/game-context'
import { GameControlsContextProvider } from './context/game-controls-context'
import { MessageQueue } from './message-queue/MessageQueue'

interface LobbyRouteProps {
  backUrl: string
}

export const LobbyRoute = observer(function LobbyRoute({
  backUrl,
}: LobbyRouteProps): React.ReactElement {
  return (
    <GameControlsContextProvider>
      <Lobby backUrl={backUrl} />
    </GameControlsContextProvider>
  )
})

interface LobbyRouteParams {
  setup: string
}

interface LobbyProps {
  backUrl: string
  isSidekick?: boolean
}

export const Lobby = observer(function Lobby({
  backUrl,
  isSidekick = false,
}: LobbyProps): React.ReactElement {
  const { gameData, isCompletedGame, scores } = useGameContext()
  const { setup } = useParams<LobbyRouteParams>()

  return (
    <Page title='Lobby'>
      <MessageQueue />

      <AppHeader
        actionsLeft={<BackButton url={backUrl} />}
        actionsRight={<CloseButton url={backUrl} />}
        header={<PageHeading>Lobby</PageHeading>}
        secondaryHeader={
          <>
            {isCompletedGame && (
              <Container className='py-6 px-0' size='large'>
                <BannerGameEnded isSidekick={isSidekick} />
              </Container>
            )}
          </>
        }
      />

      <Main>
        <Container size='large'>
          <InvitePlayers />
        </Container>

        <Container size='large'>
          <Section>
            <GameLobby />
          </Section>
        </Container>

        {gameData.enableScoreboard && scores && (
          <Container size='large'>
            <Section className='py-6'>
              <AnimatedScoreboard scores={scores} />
            </Section>
          </Container>
        )}

        <Container size='large'>
          <Section>
            <ActivePlayers />
          </Section>
        </Container>

        <Container size='large'>
          <Section>
            <PlayersLeftGame />
          </Section>
        </Container>

        <Container size='large'>
          <Section>
            <RemovedPlayers />
          </Section>
        </Container>
      </Main>

      <AppFooter>
        <Container size='medium'>
          <Button text={setup ? 'Continue' : 'Done'} to={backUrl} />
        </Container>

        <Container className='mt-6 ' size='large'>
          <MessageBanners isSidekick={isSidekick} />
        </Container>
      </AppFooter>
    </Page>
  )
})

const InvitePlayers = observer(function InvitePlayers(): React.ReactElement {
  return (
    <div className='flex flex-col gap-6'>
      <DisplayGameCode />
      <div className='text-center'>
        <Label className='justify-center'>Send guests to:</Label>
        <Heading className='break-all' disableTruncation>
          {`${import.meta.env.VITE_PRIMARY_URL}/join` || '/join'}
        </Heading>
      </div>
    </div>
  )
})
