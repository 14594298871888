import { CreateGameOptions, User } from '@matthewlongpre/music-bingo-common'
import startCase from 'lodash.startcase'
import mixpanel from 'mixpanel-browser'

import StoreService from '../store/StoreService'

class MixpanelService {
  public init() {
    const projectId = import.meta.env.VITE_MIXPANEL_PROJECT_ID

    if (!projectId) throw new Error('Invalid Mixpanel Project ID')

    mixpanel.init(projectId)
  }

  public identify(user: User) {
    mixpanel.identify(user.userId)
  }

  public setPeopleProperties(user: User) {
    mixpanel.people.set({
      $email: user.email,
      $name: user.name,
      'Sign-in Provider': startCase(user.signInProvider),
      'Plan Type': startCase(user.planType),
      'Max Players': user.maxPlayers,
    })
  }

  public alias(user: User) {
    mixpanel.alias(user.userId)
  }

  public reset() {
    mixpanel.reset()
  }

  private get store() {
    return StoreService.getStore()
  }

  public track(eventName: string, properties?: Record<string, unknown>) {
    const { user } = this.store

    const environmentDetails = {
      Environment: startCase(import.meta.env.VITE_ENV),
    }

    const userDetails = {
      'Max Players': user.maxPlayers,
      'Plan Type': startCase(user.planType),
      'Sign-in Provider': startCase(user.signInProvider),
      'User Email': user.email,
    }

    mixpanel.track(eventName, {
      ...environmentDetails,
      ...userDetails,
      ...properties,
    })
  }

  public createGame(gameId: string, options: CreateGameOptions): void {
    const getDetails = () => {
      if (options.type !== 'music') return

      const { playlist } = options

      return {
        'Playlist Title': playlist.title,
        'Playlist Type': startCase(playlist.type.replace(/_/g, ' ')),
        'Setlist Mode': playlist.disableShuffle,
        Video: Boolean(playlist.video),
        'Pre-game Video': Boolean(playlist.videoPreGame),
        'Playlist URL': playlist.url,
        'Playback Options': playlist.playbackOptions,
      }
    }

    this.track('Game Created', {
      'Game ID': gameId,
      'Game Type': options.type,
      ...getDetails(),
    })
  }

  public changeJoinScreenUrl(url: string) {
    this.track('Join Screen URL Changed', { 'Join Screen URL': url })
  }

  public importPlaylistSpotifyLibrary(
    playlistId: string,
    title: string,
    songCount: number
  ) {
    this.track('Playlist Imported from Spotify Library', {
      'Playlist ID': playlistId,
      'Playlist Title': title,
      'Song Count': songCount,
    })
  }

  public importPlaylistSpotifyURL(
    playlistId: string,
    title: string,
    songCount: number
  ) {
    this.track('Playlist Imported from Spotify URL', {
      'Playlist ID': playlistId,
      'Playlist Title': title,
      'Song Count': songCount,
    })
  }

  public importPlaylistSpreadsheet(playlistId: string, songCount: number) {
    this.track('Playlist Imported from Spreadsheet', {
      'Playlist ID': playlistId,
      'Song Count': songCount,
    })
  }

  public importPlaylistJson(playlistId: string, songCount: number) {
    this.track('Playlist Imported from JSON', {
      'Playlist ID': playlistId,
      'Song Count': songCount,
    })
  }

  public openCustomerPortal() {
    this.track('Customer Portal Opened')
  }
}

export default new MixpanelService()
