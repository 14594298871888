import {
  Container,
  IconButton,
  Panel,
  Playlist,
  Spinner,
  TrashIcon,
} from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React from 'react'

import { AppHeader } from '@/components/AppHeader'
import { Button } from '@/components/Button'
import { BackButton } from '@/components/icon-buttons/IconButtons'
import { List } from '@/components/List'
import { Main } from '@/components/Main'
import { Nav } from '@/components/Nav'
import { Page } from '@/components/Page'
import { PageHeading } from '@/components/PageHeading'
import { Title } from '@/components/Title'
import { MixpanelService } from '@/mixpanel'

interface ManagePlaylistsViewProps {
  baseUrl?: string
  isLoading: boolean
  onDeletePlaylistClick: (id: string) => void
  playlists: readonly Playlist[]
}

export const ManagePlaylistsView = observer(function ManagePlaylistsView({
  baseUrl = '/',
  isLoading,
  onDeletePlaylistClick,
  playlists = [],
}: ManagePlaylistsViewProps): React.ReactElement {
  return (
    <Page title='Manage Custom Playlists'>
      <AppHeader
        actionsLeft={<BackButton url='/music' />}
        header={<PageHeading>Manage Custom Playlists</PageHeading>}
        secondaryHeader={
          <Container className='py-6 px-0' size='medium'>
            <Button text='Create Playlist' to={`${baseUrl}playlists/create`} />
          </Container>
        }
      />

      <Main>
        {isLoading && (
          <div className='mb-8'>
            <Spinner label='Loading your custom playlists...' />
          </div>
        )}

        <Container size='medium'>
          <>
            {Boolean(playlists.length) && (
              <Nav>
                <List>
                  {playlists.map(({ id, title, songs }) => (
                    <List.Item
                      actions={
                        <IconButton
                          size='small'
                          title='Delete Playlist'
                          onClick={async () => {
                            await onDeletePlaylistClick(id)
                            MixpanelService.track('Custom Playlist Deleted')
                          }}
                        >
                          <TrashIcon />
                        </IconButton>
                      }
                      key={id}
                      to={`${baseUrl}playlists/edit/${id}`}
                      showActionsOnHover
                    >
                      <Title
                        description={`${songs.length} songs`}
                        heading={title}
                        size='small'
                      />
                    </List.Item>
                  ))}
                </List>
              </Nav>
            )}

            {!isLoading && !playlists.length && (
              <Panel className='text-muted text-center'>
                You haven{`'`}t created any custom playlists
              </Panel>
            )}
          </>
        </Container>
      </Main>
    </Page>
  )
})
