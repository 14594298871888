import {
  Collections,
  Container,
  Description,
  Heading,
  Panel,
} from '@matthewlongpre/music-bingo-common'
import { addDoc, collection, DocumentReference } from 'firebase/firestore'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { AppHeader } from '@/components/AppHeader'
import { BackButton, CloseButton } from '@/components/icon-buttons/IconButtons'
import { Main } from '@/components/Main'
import { Page } from '@/components/Page'
import { PageHeading } from '@/components/PageHeading'
import { databaseRef } from '@/firebase/firebase'
import { MixpanelService } from '@/mixpanel'
import {
  VenueForm,
  VenueFormValues,
} from '@/pages/settings/components/VenueForm'
import StoreService from '@/store/StoreService'

export async function createVenue(
  userId: string,
  data: VenueFormValues
): Promise<DocumentReference> {
  const collectionRef = collection(
    databaseRef,
    Collections.USERS,
    userId,
    Collections.VENUES
  )
  return addDoc(collectionRef, data)
}

export const VenueCreateRoute = observer(
  function VenueCreateRoute(): React.ReactElement {
    return <VenueCreatePage />
  }
)

const VenueCreatePage = observer(
  function VenueCreatePage(): React.ReactElement {
    const history = useHistory()
    const { user } = StoreService.getStore()

    const handleClickBack = () => {
      history.goBack()
    }

    const handleClickClose = () => {
      history.push('/')
    }

    const handleSubmit = async (values: VenueFormValues) => {
      const docRef = await createVenue(user.userId, values)

      MixpanelService.track('Venue Created', {
        'Venue ID': docRef.id,
        'Venue Name': values.name,
        'Venue Address': values.address,
        'Venue City': values.city,
        'Venue State': values.state,
        'Venue Country': values.country,
      })

      return docRef.id
    }

    const handleSuccess = () => {
      history.goBack()
    }

    return (
      <Page title='Create a Venue'>
        <AppHeader
          actionsLeft={<BackButton onClick={handleClickBack} />}
          actionsRight={<CloseButton onClick={handleClickClose} />}
          header={<PageHeading>Create a Venue</PageHeading>}
        />

        <Main>
          <Container className='flex flex-col gap-8' size='medium'>
            <Panel>
              <Heading>Create a Venue</Heading>
              <Description className='mt-2 mb-8'>
                Fill out the form below to create a new venue.
              </Description>

              <VenueForm onSubmit={handleSubmit} onSuccess={handleSuccess} />
            </Panel>
          </Container>
        </Main>
      </Page>
    )
  }
)
