import { useState } from 'react'

export function useDeviceSelectorModal() {
  const [showDeviceSelectorModal, setShowDeviceSelectorModal] = useState(false)

  return {
    showDeviceSelectorModal,
    setShowDeviceSelectorModal,
  }
}
