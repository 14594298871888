import {
  Container,
  Description,
  Heading,
  Label,
  Panel,
} from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import { Redirect, useLocation } from 'react-router-dom'

import { LogoHeader } from '@/components/LogoHeader'
import { Main } from '@/components/Main'
import { Page } from '@/components/Page'

import { SpotifyAuthButton } from '../components/SpotifyAuthButton'
import { useSpotify } from '../useSpotify'

export const SpotifyPremiumRequired = observer(
  function SpotifyPremiumRequired() {
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const email = params.get('email')

    const spotify = useSpotify()

    if (spotify.auth.isAuthenticated) {
      return <Redirect to='/' />
    }

    return (
      <Page title='Spotify Premium Account Required'>
        <Main>
          <LogoHeader />

          <Container size='small'>
            <Panel className='flex flex-col gap-6'>
              <div className='flex flex-col gap-4'>
                <Heading>Spotify Premium Account Required</Heading>
                <Description>
                  A Spotify <strong>Premium</strong> account is required to use
                  Rockstar Bingo. Please confirm that you have signed in to
                  Spotify with the correct email address.
                </Description>

                {Boolean(email) && (
                  <p>
                    <Label>Email:</Label>
                    {email}
                  </p>
                )}

                <Description>
                  You may need to upgrade your account on Spotify to continue.
                </Description>
              </div>

              <SpotifyAuthButton />
            </Panel>
          </Container>
        </Main>
      </Page>
    )
  }
)
