import type { Scoreboard } from '@matthewlongpre/music-bingo-common'
import { Dialog } from '@matthewlongpre/music-bingo-common/client'

import { AnimatedScoreboard } from '@/components/Scoreboard'

interface ScoreboardDialogProps {
  scores: Scoreboard
}

export function ScoreboardDialog({ scores }: ScoreboardDialogProps) {
  return (
    <Dialog>
      <Dialog.Body>
        <AnimatedScoreboard scores={scores} />
      </Dialog.Body>
    </Dialog>
  )
}
