import {
  Column,
  DrawnSong,
  merge,
  Container,
} from '@matthewlongpre/music-bingo-common'
import { ScrollContainer } from '@matthewlongpre/music-bingo-common/client'
import classNames from 'classnames'
import debounce from 'lodash.debounce'
import { observer } from 'mobx-react-lite'
import React, {
  RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react'
import styled from 'styled-components'

import { RowAudioFeatures } from './RowAudioFeatures'
import { useGameContext } from '../pages/game/context/game-context'

const DEBOUNCE_TIME_MS = 300

interface PlaylistRowProps extends DrawnSong {
  className?: string
  currentItemIndex: number
  enableRowAudioFeatures?: boolean
  index: number
  recentlyPlayed?: boolean
}

const PlaylistRowWithRef = React.forwardRef<HTMLLIElement, PlaylistRowProps>(
  (
    {
      className,
      column,
      currentItemIndex,
      enableRowAudioFeatures,
      index,
      recentlyPlayed,
      song,
    }: PlaylistRowProps,
    ref
  ) => {
    return (
      <PlaylistRowStyled
        className={classNames(
          'playlist-row',
          {
            active: currentItemIndex === index,
            played: currentItemIndex && currentItemIndex > index,
            'recently-played': recentlyPlayed,
          },
          className
        )}
        ref={ref}
      >
        <ColumnLabelStyled>
          <span>{Column[column]}</span>
        </ColumnLabelStyled>
        <div className='flex flex-col gap-1'>
          <div className='title'>
            <h3 className='m-0'>{song.title}</h3>
          </div>

          <div className='artist'>{song.artist}</div>

          {enableRowAudioFeatures && (
            <RowAudioFeatures
              currentItemIndex={currentItemIndex}
              index={index}
              song={song}
            />
          )}
        </div>
        <PlaylistSongNumberStyled>{index + 1}</PlaylistSongNumberStyled>
      </PlaylistRowStyled>
    )
  }
)

PlaylistRowWithRef.displayName = 'PlaylistRow'

// eslint-disable-next-line mobx/no-anonymous-observer
export const PlaylistRow = observer(PlaylistRowWithRef)

interface IPlaylistProps {
  className?: string
  enableDimmedPlayedSongs?: boolean
  enableRowAudioFeatures?: boolean
  enableScrollToSong?: boolean
  scrollContainer?: 'document' | 'element'
  scrollToSong?: 'current' | 'next' | 'previous'
  showFullPlaylist?: boolean
}

export const Playlist = observer(function Playlist({
  className,
  enableDimmedPlayedSongs = true,
  enableRowAudioFeatures = false,
  enableScrollToSong = true,
  scrollContainer = 'document',
  scrollToSong = 'current',
  showFullPlaylist = true,
}: IPlaylistProps): React.ReactElement | null {
  const { currentItemIndex, gameData, hasCalledBingo } = useGameContext()

  if (gameData.type !== 'music') throw new Error(`Game type music be 'music'`)

  const { drawnItems } = gameData

  const drawnItemsOrdered = addDrawnIndex(drawnItems)
  const playlist = showFullPlaylist
    ? drawnItemsOrdered
    : getPreviousSongs(drawnItemsOrdered, currentItemIndex, 3)

  const refs = playlist.reduce(
    (acc: { [key: number]: RefObject<HTMLLIElement> }, _, index) => {
      acc[index] = React.createRef<HTMLLIElement>()
      return acc
    },
    {}
  )

  const containerRef = useRef<HTMLDivElement | null>(null)

  const handleScrollToSong = useCallback(() => {
    if (!enableScrollToSong) return

    let scrollToSongRef

    switch (scrollToSong) {
      case 'current':
        scrollToSongRef = refs[currentItemIndex]?.current
        break
      case 'next':
        scrollToSongRef =
          refs[currentItemIndex < 73 ? currentItemIndex + 1 : currentItemIndex]
            ?.current
        break
      case 'previous':
        scrollToSongRef =
          refs[currentItemIndex > 0 ? currentItemIndex - 1 : currentItemIndex]
            ?.current
        break
    }

    if (!scrollToSongRef) return

    const currentItemPosition = scrollToSongRef?.offsetTop - 24
    const html = document.documentElement

    const elementToScroll =
      scrollContainer === 'element' ? containerRef.current : html

    if (elementToScroll && currentItemPosition) {
      requestAnimationFrame(() =>
        elementToScroll.scrollTo(0, currentItemPosition)
      )
    }
  }, [
    currentItemIndex,
    scrollToSong,
    enableScrollToSong,
    refs,
    scrollContainer,
  ])

  useLayoutEffect(() => {
    handleScrollToSong()
  }, [
    containerRef,
    currentItemIndex,
    enableScrollToSong,
    handleScrollToSong,
    refs,
    scrollContainer,
    scrollToSong,
  ])

  const debouncedScrollToSong = useMemo(
    () => debounce(handleScrollToSong, DEBOUNCE_TIME_MS),
    [handleScrollToSong]
  )

  useEffect(() => {
    window.addEventListener('resize', debouncedScrollToSong)

    return () => window.removeEventListener('resize', debouncedScrollToSong)
  }, [debouncedScrollToSong])

  return (
    <ScrollContainer
      ref={(ref) => {
        if (!ref) return

        containerRef.current = ref.innerRef.current
      }}
    >
      <Container size='medium'>
        <PlaylistStyled
          className={merge(
            'playlist',
            'is-scrollable',
            {
              'has-bingo': hasCalledBingo,
              'dim-played-songs': enableDimmedPlayedSongs,
            },
            className
          )}
        >
          <ul className='list-unstyled'>
            {playlist.map((item, index) => (
              <PlaylistRow
                {...item}
                currentItemIndex={currentItemIndex}
                enableRowAudioFeatures={enableRowAudioFeatures}
                index={item.drawnIndex}
                key={item.id}
                recentlyPlayed={item.song.recentlyPlayed}
                ref={refs[index]}
              />
            ))}
          </ul>
        </PlaylistStyled>
      </Container>
    </ScrollContainer>
  )
})

interface DrawnSongOrdered extends DrawnSong {
  drawnIndex: number
}

function addDrawnIndex(drawnItems: DrawnSong[]): DrawnSongOrdered[] {
  return drawnItems.map((item, index) => ({ ...item, drawnIndex: index }))
}

const getPreviousSongs = (
  drawnItems: DrawnSongOrdered[],
  currentItemIndex: number | null,
  limit: number
): DrawnSongOrdered[] => {
  if (currentItemIndex === null) return []

  const previousSongs = drawnItems.filter(
    (_, index) => currentItemIndex && index < currentItemIndex
  )

  return previousSongs.slice(Math.max(previousSongs.length - limit, 0))
}

export const ColumnLabelStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--bg-02);
  font-weight: 800;
  font-size: 1.25em;
  aspect-ratio: 1;
  border-radius: 8px;

  span {
    position: relative;
    top: -0.05em;
  }
`

const PlaylistSongNumberStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  top: -0.125em;
  position: relative;
`

const PlaylistRowStyled = styled.li`
  .light & {
    --playlist-active-track: var(--orange-200);
    --playlist-active-track-text: var(--text);
  }

  .dark & {
    --playlist-active-track: var(--orange-800);
    --playlist-active-track-text: var(--text);
  }

  display: grid;
  grid-template-columns: 2.5em 1fr 2.5em;
  align-items: center;
  padding: 0.625em;
  grid-gap: 12px;
  opacity: 0.8;
  transition: opacity 0.3s ease-in-out, background-color 0.3s ease-in-out,
    padding 0.3s ease-in-out;
  border-radius: 12px;

  .title {
    line-height: 1.2;
  }

  .artist {
    color: var(--text-muted);
    text-transform: uppercase;
    font-size: 0.667em;
    letter-spacing: 0.00125em;
    line-height: 1.2;
  }

  @media (min-width: 768px) {
    font-size: 1.33em;
  }

  &.active {
    margin: 1em 0;
    opacity: 1;
    background: var(--playlist-active-track);
    border: 4px solid var(--orange-500);
    color: var(--playlist-active-track-text);

    .artist {
      color: var(--text);
      opacity: 0.75;
    }

    &:not(.compact) {
      @media (min-width: 768px) {
        font-size: 1.5em;
      }
    }

    ${ColumnLabelStyled} {
      background: var(--orange-500);
      color: var(--text);
    }

    ${PlaylistSongNumberStyled} {
      opacity: 1;
    }
  }

  &.m-0 {
    margin: 0;
  }
`

const PlaylistStyled = styled.div`
  padding-bottom: 24px;

  &.has-bingo {
    ${PlaylistRowStyled} {
      &.active {
        background: var(--bg-04);
        border: 4px solid var(--bg-00);

        ${ColumnLabelStyled} {
          background: var(--bg-00);
          color: var(--text);
        }
      }
    }
  }

  &.dim-played-songs {
    ${PlaylistRowStyled} {
      &.played {
        opacity: 0.33;
      }
    }
  }
`
