import { Game, ToggleSwitch } from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React from 'react'

import { useGameContext } from '@/pages/game/context/game-context'
import { updateGameSetting } from '@/store/game/updateGameDocument'

import { SettingsRow } from './SettingsRow'

const SETTING_NAME = 'Hide Artist'
const SETTING_ID = 'disableArtist' as const

export const HideArtist = observer(
  function HideArtist(): React.ReactElement | null {
    const { gameId, gameData, tracker } = useGameContext()

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target

      const update: Pick<Game, typeof SETTING_ID> = {
        [SETTING_ID]: checked,
      }

      void updateGameSetting(gameId, update)
      tracker.changeToggle(SETTING_NAME, checked)
    }

    if (gameData.type !== 'music') return null

    return (
      <SettingsRow
        description='Increase difficulty by only displaying the song title on the card.'
        heading={SETTING_NAME}
      >
        <ToggleSwitch
          id={SETTING_ID}
          isChecked={gameData.disableArtist}
          isDisabled={!gameData}
          onChange={handleChange}
        />
      </SettingsRow>
    )
  }
)
