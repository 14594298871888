import {
  Badge,
  BadgeColours,
  BingoCard,
  ButtonGroup,
  CalledBingo,
  Container,
  Description,
  Heading,
  Panel,
  Player,
  PlayerCardGrid,
} from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'

import { Button } from '@/components/Button'
import { List } from '@/components/List'

import { TabGroup } from './shared/TabGroup'
import { ListItemActionsStyled } from './StyledComponents'
import { useGameContext } from '../pages/game/context/game-context'

interface ButtonProps {
  className?: string
  disabled: boolean
  loading: boolean
  loadingText: string
  onClick: (playerId: string) => void
  text: string
}

interface BulkButtonProps extends ButtonProps {
  onClick: () => void
}

interface PlayerListProps {
  badgeColor: BadgeColours
  bulkButtonProps?: BulkButtonProps
  buttonProps?: ButtonProps
  defaultTab?: PlayerListTab
  description: string
  enableTabs?: boolean
  noItemsText: string
  players: Player[]
  title: string
}

export enum PlayerListTab {
  CARDS = 'cards',
  LIST = 'list',
}

export const PlayerList = observer(function PlayerList({
  badgeColor,
  bulkButtonProps,
  buttonProps,
  defaultTab = PlayerListTab.LIST,
  description,
  enableTabs,
  noItemsText,
  players = [],
  title,
}: PlayerListProps): React.ReactElement {
  const { gameData, calledBingosList } = useGameContext()
  const [currentTab, setCurrentTab] = useState(defaultTab)

  const heading = (
    <Heading className='flex items-center gap-2 mb-2'>
      <Badge colour={badgeColor}>{players.length}</Badge>
      <span>{title}</span>
    </Heading>
  )

  const playerList = (
    <Container className='p-0 md:p-0' size='extra-large'>
      <List>
        {players.map(({ playerId, playerName }) => (
          <List.Item
            actions={
              buttonProps && (
                <ListItemActionsStyled>
                  <Button
                    className='min-w-[140px]'
                    {...buttonProps}
                    onClick={() => buttonProps?.onClick(playerId)}
                  />
                </ListItemActionsStyled>
              )
            }
            className='border-b last:border-b-0 border-shade-3'
            key={playerId}
          >
            {playerName}
          </List.Item>
        ))}
      </List>
    </Container>
  )

  if (!players.length) {
    return (
      <>
        {heading}

        <Container className='mt-4 text-muted p-0' size='large'>
          <Panel>
            <Description>{noItemsText}</Description>
          </Panel>
        </Container>
      </>
    )
  }

  return (
    <>
      {heading}
      <Description>{description}</Description>

      {bulkButtonProps && players.length > 1 && (
        <ButtonGroup className='my-6'>
          <Container size='medium'>
            <Button
              {...bulkButtonProps}
              onClick={() => bulkButtonProps?.onClick()}
            />
          </Container>
        </ButtonGroup>
      )}

      {!enableTabs && <div className='mt-4'>{playerList}</div>}

      {enableTabs && (
        <>
          <TabGroup
            className='mt-6'
            tabs={[
              {
                id: 'list',
                title: 'List',
                onClick: () => setCurrentTab(PlayerListTab.LIST),
                isActive: currentTab === PlayerListTab.LIST,
              },
              {
                id: 'cards',
                title: 'Cards',
                onClick: () => setCurrentTab(PlayerListTab.CARDS),
                isActive: currentTab === PlayerListTab.CARDS,
              },
            ]}
          />

          <div className='mt-6'>
            {currentTab === PlayerListTab.LIST && <>{playerList}</>}

            {currentTab === PlayerListTab.CARDS && (
              <PlayerCardGrid>
                {players.map((playerData) => (
                  <PlayerCardGrid.Item
                    key={playerData.playerId}
                    playerName={playerData.playerName}
                  >
                    <BingoCard
                      currentPlayerHasCalledBingo={calledBingosList.some(
                        (calledBingo: CalledBingo) =>
                          calledBingo.playerId === playerData.playerId
                      )}
                      gameData={gameData}
                      playerData={playerData}
                      onSquareClick={() => undefined}
                    />
                  </PlayerCardGrid.Item>
                ))}
              </PlayerCardGrid>
            )}
          </div>
        </>
      )}
    </>
  )
})
