import { Song } from '@matthewlongpre/music-bingo-common'
import { makeAutoObservable, when } from 'mobx'

import { apiClient } from '@/api/api-client'
import { queryClient } from '@/queryClient'
import {
  getNextIndex,
  getPreviousIndex,
  updateGameCurrentDrawnItem,
} from '@/store/game/updateGameDocument'
import StoreService from '@/store/StoreService'
import { applyProperties } from '@/store/utils'

import { IntegrationPlayback } from '../..'

export class SpotifyPlayback implements IntegrationPlayback {
  isLoading = false

  constructor() {
    makeAutoObservable(this)
  }

  get query() {
    return {
      key: ['spotify-playback'],
      fn: () => this.client.getPlaybackState(),
    }
  }

  async refetch() {
    await queryClient.invalidateQueries(this.query.key)
    return queryClient.refetchQueries(this.query.key)
  }

  private get apiClient() {
    return apiClient
  }

  private get store() {
    return StoreService.getStore()
  }

  private get spotify() {
    return this.store.spotify
  }

  private get client() {
    return this.spotify.client
  }

  private get activeDevice() {
    return this.spotify.devices.activeDevice
  }

  private get player() {
    return this.spotify.player
  }

  private get game() {
    if (!this.store.currentGame) throw new Error('Cannot access current game')
    return this.store.currentGame
  }

  private get tracker() {
    return this.game.tracker
  }

  get isPlaying() {
    return this.player.isPlaying
  }

  get selectedTrack() {
    const { currentItemIndex } = this.game

    return this.gameData.drawnItems[currentItemIndex]?.song
  }

  get gameData() {
    if (this.game.gameData.type !== 'music') {
      throw new Error('Game type music be music')
    }

    return this.game.gameData
  }

  apply(values: Partial<SpotifyPlayback>) {
    applyProperties(this, values)
  }

  async startPlayback(song?: Song) {
    try {
      song = song ?? this.selectedTrack

      if (!this.activeDevice) {
        throw new Error('No active device')
      }

      console.log('Starting playback...', song)
      await this.client.startPlayback(this.activeDevice.id, song)

      if (this.player.isActiveDevice) {
        // Some browsers require a user interaction before playback is allowed
        await this.player.activateElement()

        if (!this.isPlaying) {
          console.log('Toggling play in player element...')
          await this.player.playerElement.togglePlay()
        }

        await when(() => this.isPlaying)
      }
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async play(song?: Song) {
    this.apply({ isLoading: true })

    try {
      await this.startPlayback(song)
    } catch (error) {
      console.error(error)
      throw error
    } finally {
      this.apply({ isLoading: false })
    }
  }

  async pause() {
    return this.client.pausePlayback()
  }

  async next() {
    try {
      this.apply({ isLoading: true })
      this.player.resetTrack()

      const nextSongIndex = getNextIndex(this.game.currentItemIndex)

      const song = this.gameData.drawnItems[nextSongIndex]?.song

      if (!song) throw new Error('Could not find next song')

      await Promise.all([
        this.startPlayback(song),
        updateGameCurrentDrawnItem(this.game.gameId, nextSongIndex),
      ])

      this.tracker.startPlayback(song)
      this.tracker.nextSong()

      await when(() => this.gameData.currentItemIndex === nextSongIndex)

      if (this.player.isActiveDevice) {
        await when(() => this.isPlaying)
      }
    } catch (error) {
      console.error(error)
      throw error
    } finally {
      this.apply({ isLoading: false })
    }
  }

  async previous() {
    try {
      this.apply({ isLoading: true })
      this.player.resetTrack()

      const previousSongIndex = getPreviousIndex(this.game.currentItemIndex)

      if (previousSongIndex === null) throw new Error('Invalid previous index')

      const song = this.gameData.drawnItems[previousSongIndex]?.song

      if (!song) throw new Error('Could not find next song')

      await Promise.all([
        this.startPlayback(song),
        updateGameCurrentDrawnItem(this.game.gameId, previousSongIndex),
      ])

      this.tracker.startPlayback(song)
      this.tracker.prevSong()

      await when(() => this.gameData.currentItemIndex === previousSongIndex)

      if (this.player.isActiveDevice) {
        await when(() => this.isPlaying)
      }
    } catch (error) {
      console.error(error)
      throw error
    } finally {
      this.apply({ isLoading: false })
    }
  }
}
