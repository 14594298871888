import {
  Game,
  GameMusic,
  GameNumbers,
  GameStatus,
  PlaylistType,
} from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React from 'react'

interface GameInfoProps {
  game: Game
}

export const GameInfo = observer(function GameInfo({
  game,
}: GameInfoProps): React.ReactElement {
  const { bingoCount, playerCountFinal, timestamp, type } = game

  const dateTimestamp = timestamp.toDate()
  const date = new Date(dateTimestamp)
  const options = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  } as Intl.DateTimeFormatOptions

  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date)

  return (
    <div className='flex gap-8 text-sm'>
      <div>
        <div className='text-muted' style={{ flex: `1 0 100%` }}>
          {formattedDate}
        </div>
        <div>
          {playerCountFinal} {playerCountFinal === 1 ? `player` : `players`}
        </div>
        <div>Bingos: {bingoCount ? bingoCount : `0`}</div>
      </div>

      {type !== 'numbers' && <MusicGameDetails game={game} />}
      {type === 'numbers' && <NumbersGameDetails game={game} />}
    </div>
  )
})

interface MusicGameDetailsProps {
  game: GameMusic
}

function MusicGameDetails({ game }: MusicGameDetailsProps) {
  const { currentItemIndex, gameStatus, playbackSource, sourcePlaylistMeta } =
    game

  return (
    <div>
      <div>
        <span className='text-muted'>
          {gameStatus === GameStatus.GAME_COMPLETED
            ? `Songs played:`
            : `Current song:`}{' '}
        </span>
        {currentItemIndex ? `${currentItemIndex + 1}` : `0`}
      </div>

      <div>
        <span className='text-muted'>Playback Source:</span>{' '}
        {playbackSource
          ? `${
              playbackSource.charAt(0).toUpperCase() + playbackSource.slice(1)
            }`
          : ``}
      </div>

      {sourcePlaylistMeta && (
        <div>
          <span className='text-muted'>
            {formatPlaylistType(sourcePlaylistMeta.type)}:{' '}
          </span>
          {sourcePlaylistMeta.title}
        </div>
      )}
    </div>
  )
}

interface NumbersGameDetailsProps {
  game: GameNumbers
}

function NumbersGameDetails({ game }: NumbersGameDetailsProps) {
  const { currentItemIndex, gameStatus } = game

  return (
    <div>
      <div>
        <span className='text-muted'>
          {gameStatus === GameStatus.GAME_COMPLETED
            ? `Numbers drawn:`
            : `Current number:`}{' '}
        </span>
        {currentItemIndex ? `${currentItemIndex + 1}` : `0`}
      </div>
    </div>
  )
}

function formatPlaylistType(playlistType: PlaylistType) {
  switch (playlistType) {
    case PlaylistType.SYSTEM_PLAYLISTS:
      return 'System Playlist'
    case PlaylistType.USER_CUSTOM_PLAYLISTS:
      return 'Custom Playlist'
    case PlaylistType.USER_SPOTIFY_LIBRARY:
      return "User's Spotify Library"
    case PlaylistType.USER_SPOTIFY_URL:
      return 'From Spotify URL'
    default:
      return 'Unknown playlist type'
  }
}
