import { Device } from '@matthewlongpre/music-bingo-common'
import { useQuery } from 'react-query'

import { SpotifyDevicesStore } from '../SpotifyDevicesStore'
import { useSpotify } from '../useSpotify'

export function applyActiveDevice(
  devicesStore: SpotifyDevicesStore,
  queryResult: Device[]
) {
  console.log('Devices query result:', JSON.stringify(queryResult, null, 4))

  const activeDevice = queryResult.find((device) => device.isActive)

  if (!activeDevice) {
    console.log('No active device found. Resetting activeDevice...')
    devicesStore.apply({ activeDevice: undefined })
  } else {
    console.log('Applying activeDevice...', JSON.stringify(activeDevice))
    devicesStore.apply({ activeDevice })
  }
}

export function useQuerySpotifyDevices() {
  const { auth, devices, deviceService } = useSpotify()

  const { key, fn } = deviceService.query

  return useQuery(key, fn, {
    enabled: auth.isAuthenticated,
    refetchOnWindowFocus: true,
    onSuccess: (data) => {
      applyActiveDevice(devices, data)
    },
  })
}
