import * as Sentry from '@sentry/react'
import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useDialogContext } from '@/dialogs/DialogContext'
import { getErrorMessage } from '@/utils/get-error-message'

import { SpotifyTokenError } from './auth-client/types'
import { useSpotify } from '../useSpotify'

export function useSpotifyAuthCallback() {
  const hasMounted = useRef(false)
  const [hasHandledAuthCallback, setHasHandledAuthCallback] = useState(false)
  const [hasError, setHasError] = useState(false)

  const spotify = useSpotify()

  const { setErrorDialog } = useDialogContext()

  const history = useHistory()

  useEffect(() => {
    const handleAuthCallback = async () => {
      try {
        hasMounted.current = true

        await spotify.auth.handleAuthCallback()
      } catch (error) {
        console.error(error)
        Sentry.captureException(error)

        if (
          error instanceof SpotifyTokenError &&
          error.error === 'Error: Spotify Premium account required'
        ) {
          return history.push(`/spotify-premium-required?email=${error.email}`)
        }

        if (
          error instanceof SpotifyTokenError &&
          error.error ===
            'Error: There is no user record corresponding to the provided identifier.'
        ) {
          const email = (error as SpotifyTokenError).email
          return history.push(
            `/no-account${email ? `?email=${email}&provider=Spotify` : ``}`
          )
        }

        if (
          error instanceof SpotifyTokenError &&
          error.error ===
            'Error: The email address is already in use by another account.'
        ) {
          const email = (error as SpotifyTokenError).email
          alert(`There is already an account using the email: '${email ?? ''}'`)
        }

        setErrorDialog({ error: getErrorMessage(error) })
        setHasError(true)
      } finally {
        setHasHandledAuthCallback(true)
      }
    }

    if (!hasMounted.current) {
      void handleAuthCallback()
    }

    setHasHandledAuthCallback(true)
  }, [history, spotify, setErrorDialog, hasHandledAuthCallback])

  return {
    hasHandledAuthCallback,
    hasError,
  }
}
