import { Playlist } from '@matthewlongpre/music-bingo-common'
import { useMutation, useQueryClient } from 'react-query'

export function useUpdatePlaylist(
  mutationFn: (playlist: Playlist) => Promise<{ playlistId: string }>,
  itemQueryKey: string[],
  itemQueryFn: () => Promise<Playlist>,
  listQueryKey: string[],
  listQueryFn: () => Promise<Playlist[]>
) {
  const queryClient = useQueryClient()

  const mutation = useMutation(mutationFn, {
    onMutate: async (updatedPlaylist: Playlist) => {
      await queryClient.cancelQueries(itemQueryKey)

      const previousPlaylist = queryClient.getQueryData<Playlist>(itemQueryKey)

      // Optimistically update the cache
      queryClient.setQueryData(itemQueryKey, updatedPlaylist)

      return { previousPlaylist }
    },
    onError: (err, newPlaylist, context) => {
      // Rollback on error
      if (!context?.previousPlaylist) return

      queryClient.setQueryData(itemQueryKey, context.previousPlaylist)
    },
    onSettled: () => {
      queryClient.invalidateQueries(itemQueryKey)
      queryClient.fetchQuery(itemQueryKey, itemQueryFn)

      queryClient.invalidateQueries(listQueryKey)
      queryClient.fetchQuery(listQueryKey, listQueryFn)
    },
  })

  return mutation
}
