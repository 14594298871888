import { merge, Label } from '@matthewlongpre/music-bingo-common'
import { ErrorMessage, useField, UseFieldProps } from 'formik'
import { observer } from 'mobx-react-lite'
import React from 'react'

import { FieldError } from './FieldError'
import { useForwardedRef } from '../../hooks/useForwardedRef'

interface TextFieldProps extends UseFieldProps {
  autoFocus?: boolean
  isDisabled?: boolean
  label: React.ReactNode
  maxLength?: number
  min?: number
  onKeyPress?: (event: React.KeyboardEvent) => void
  placeholder?: string
  type?: 'text' | 'email' | 'password' | 'number'
}

const TextFieldWithRef = React.forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      autoFocus,
      isDisabled = false,
      label,
      maxLength,
      min = 0,
      placeholder,
      type = 'text',
      onKeyPress,
      ...props
    }: TextFieldProps,
    ref
  ) => {
    const [field, meta, helpers] = useField(props)
    const forwardedRef = useForwardedRef(ref)

    const handleFocus = () => {
      forwardedRef.current?.select()
    }

    const handleBlur = (event: React.FocusEvent) => {
      field.onBlur(event)
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (type === 'number') {
        const { value } = event.target
        const numberValue = value === '' ? undefined : parseInt(value, 10)
        helpers.setValue(numberValue)
      } else {
        field.onChange(event)
      }
    }

    return (
      <div>
        <div className='flex flex-col gap-3'>
          <Label>
            <span>{label}</span>
            {maxLength && (
              <MaxLength maxLength={maxLength} value={String(field.value)} />
            )}
          </Label>

          <input
            {...field}
            autoFocus={autoFocus}
            className={merge('input', {
              'has-error border-red-500 focus:ring-0': meta.error,
            })}
            disabled={isDisabled}
            min={min}
            placeholder={placeholder}
            ref={forwardedRef}
            type={type}
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
            onKeyPress={onKeyPress}
          />
        </div>

        <ErrorMessage {...field} component={FieldError} />
      </div>
    )
  }
)

TextFieldWithRef.displayName = 'TextField'

// eslint-disable-next-line mobx/no-anonymous-observer
export const TextField = observer(TextFieldWithRef)

interface MaxLengthProps {
  maxLength: number
  value: string
}

const MaxLength = observer(function MaxLength({
  maxLength,
  value,
}: MaxLengthProps) {
  const getTextColorStyle = (length: number, maxLength: number) => {
    switch (true) {
      case length > maxLength - 5: {
        return { color: `var(--red-500)` }
      }

      case length > maxLength - 10: {
        return { color: `var(--yellow-500)` }
      }

      default: {
        return {}
      }
    }
  }

  const getLength = (value: string) => {
    return value.length
  }

  return (
    <div className='inline-flex justify-end text-muted text-sm'>
      <span style={getTextColorStyle(getLength(value), maxLength)}>
        {getLength(value)}
      </span>
      <span>/</span>
      <span>{maxLength}</span>
    </div>
  )
})
