import { Playlist } from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React from 'react'

import { Select } from '@/components/shared/Select'

interface PlaylistSelectorProps {
  handleChange: (playlistId: string) => void
  playlists: readonly Playlist[]
  selectedPlaylistId: string
}

export const PlaylistSelector = observer(function PlaylistSelector({
  handleChange,
  playlists,
  selectedPlaylistId,
}: PlaylistSelectorProps): React.ReactElement {
  const mapOptions = ({ id, title }: Playlist) => {
    return {
      id,
      value: id,
      name: title,
      description: '',
    }
  }

  return (
    <Select
      label=''
      options={playlists.map(mapOptions)}
      placeholder='Choose a playlist'
      value={selectedPlaylistId}
      onChange={(value: string) => handleChange(value)}
    />
  )
})
