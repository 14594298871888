import {
  ButtonGroup,
  Description,
  Heading,
  UserBrandingSettings,
  UserCallToAction,
} from '@matthewlongpre/music-bingo-common'
import { useSuccessButton } from '@matthewlongpre/music-bingo-common/client'
import * as Sentry from '@sentry/react'
import { Form, Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import * as Yup from 'yup'

import { Button } from '@/components/Button'
import { FormError } from '@/components/forms/FormError'
import { TextField } from '@/components/forms/TextField'
import StoreService from '@/store/StoreService'

import { ImageUpload } from './ImageUpload'

const STORAGE_BUCKET_FOLDER = 'user_images'

const HEADING_MAX_CHARACTERS = 60
const BUTTON_MAX_CHARACTERS = 20

const CALL_TO_ACTION_DEFAULT: UserCallToAction = {
  heading: 'Want to host your own games?',
  button: {
    label: 'Learn More',
    url: 'https://rockstar.bingo/host',
  },
}

const isValidUrl = (url: string): boolean => {
  try {
    new URL(url)
  } catch (e) {
    return false
  }
  return true
}

const VALIDATION_SCHEMA = Yup.object({
  callToAction: Yup.object({
    heading: Yup.string()
      .max(
        HEADING_MAX_CHARACTERS,
        `Must be ${HEADING_MAX_CHARACTERS} characters or less`
      )
      .required('Required field'),
    button: Yup.object({
      label: Yup.string()
        .max(
          BUTTON_MAX_CHARACTERS,
          `Must be ${BUTTON_MAX_CHARACTERS} characters or less`
        )
        .required('Required field'),
      url: Yup.string()
        .test('is-url-valid', 'URL is not valid', (value?: string) => {
          return value ? isValidUrl(value) : false
        })
        .required('Required field'),
    }),
  }),
})

interface BrandingSettingsFormProps {
  initialValues?: UserBrandingSettings
  isDisabled?: boolean
  onCancel?: () => void
  onSubmit: (values: UserBrandingSettings) => Promise<void>
  onSuccess?: () => void
}

export const BrandingSettingsForm = observer(function BrandingSettingsForm({
  initialValues,
  isDisabled = false,
  onSubmit,
  onSuccess,
  onCancel,
}: BrandingSettingsFormProps): React.ReactElement {
  const [formError, setFormError] = useState<string | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const { showSuccess, showSuccessButtonState } = useSuccessButton()

  const { user } = StoreService.getStore()
  const storageFolder = `${STORAGE_BUCKET_FOLDER}/${user.userId}`

  const setupInitialValues = (
    initialValues?: UserBrandingSettings
  ): UserBrandingSettings => {
    return {
      ...initialValues,
      callToAction: {
        ...CALL_TO_ACTION_DEFAULT,
        ...initialValues?.callToAction,
      },
    }
  }

  const handleSubmit = async (values: UserBrandingSettings) => {
    try {
      setIsLoading(true)
      await onSubmit(values)
      setIsLoading(false)
      await showSuccessButtonState()
      onSuccess?.()
    } catch (error) {
      setIsLoading(false)
      console.error(error)
      setFormError('Sorry, something went wrong')
      Sentry.captureException(error)
    }
  }

  return (
    <Formik
      initialValues={setupInitialValues(initialValues)}
      validationSchema={VALIDATION_SCHEMA}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, isValid, setFieldValue }) => {
        return (
          <Form>
            <Heading>Image</Heading>
            <Description className='my-2'>
              Add a logo or other image to display on various game screens. For
              best results, use an image with a square aspect ratio.
            </Description>

            <ImageUpload
              initialValue={initialValues?.image}
              isDisabled={isDisabled}
              storageFolder={storageFolder}
              onRemoveImage={() => {
                setFieldValue('image.url', '')
                setFieldValue('image.filename', '')
              }}
              onUploadSuccess={({ filename, url }) => {
                setFieldValue('image.url', url)
                setFieldValue('image.filename', filename)
              }}
            />

            <div className='flex flex-col gap-2 my-6'>
              <Heading>Call to Action</Heading>
              <Description>
                Add a heading and button to direct guests to your website or
                other web address.
              </Description>
            </div>

            <div className='flex flex-col gap-6'>
              <TextField
                isDisabled={isDisabled}
                label='Heading'
                maxLength={HEADING_MAX_CHARACTERS}
                name='callToAction.heading'
              />

              <TextField
                isDisabled={isDisabled}
                label='Button Label'
                maxLength={BUTTON_MAX_CHARACTERS}
                name='callToAction.button.label'
              />

              <TextField
                isDisabled={isDisabled}
                label='Button URL'
                name='callToAction.button.url'
              />
            </div>

            <ButtonGroup className='mt-8'>
              {onCancel && (
                <Button text='Cancel' variant='outlined' onClick={onCancel} />
              )}

              <Button
                disabled={isDisabled || isSubmitting || !isValid}
                isLoading={isLoading}
                loadingText='Saving...'
                showSuccess={showSuccess}
                text='Save'
                type='submit'
              />
            </ButtonGroup>

            {formError && <FormError error={formError} />}
          </Form>
        )
      }}
    </Formik>
  )
})
