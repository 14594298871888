import { Heading, Playlist, merge } from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'

interface CardProps {
  className?: string
  colour?: Colours
  icon?: React.ReactElement
  image?: Playlist['image']
  onClick: () => void
  title: string
}

const BG_COLOUR_CLASSES = {
  blue: 'bg-blue-500 hover:bg-blue-600 dark:hover:bg-blue-700',
  green: 'bg-green-500 hover:bg-green-600 dark:hover:bg-green-700',
  indigo: 'bg-indigo-500 hover:bg-indigo-600 dark:hover:bg-indigo-700',
  orange: 'bg-orange-500 hover:bg-orange-600 dark:hover:bg-orange-700',
  pink: 'bg-pink-500 hover:bg-pink-600 dark:hover:bg-pink-700',
  purple: 'bg-purple-500 hover:bg-purple-600 dark:hover:bg-purple-700',
  teal: 'bg-teal-500 hover:bg-teal-600 dark:hover:bg-teal-700',
  yellow: 'bg-yellow-500 hover:bg-yellow-600 dark:hover:bg-yellow-700',
  red: 'bg-red-500 hover:bg-red-600 dark:hover:bg-red-700',
} as const

export type Colours = keyof typeof BG_COLOUR_CLASSES

export const PlaylistCard = observer(function PlaylistCard({
  className,
  colour,
  icon,
  image,
  title,
  onClick,
}: CardProps): React.ReactElement {
  const backgroundClass = colour ? BG_COLOUR_CLASSES[colour] : ''

  return (
    <div
      className={merge(
        'w-full h-40 p-6 relative rounded-lg overflow-hidden transition-all duration-300 ease-in-out group hover:cursor-pointer text-white',
        { 'bg-shade-3 hover:bg-shade-4': !backgroundClass },
        backgroundClass,
        className
      )}
      onClick={onClick}
    >
      {image && (
        <Image
          className={merge(
            { 'blur-2xl': !colour },
            'group-hover:opacity-20 group-hover:blur-none group-hover:scale-125'
          )}
          src={image.url}
          type='background'
        />
      )}

      {!colour && (
        <div className='bg-gradient-overlay absolute inset-0 m-auto w-full h-full z-10' />
      )}

      <div
        className={merge(
          'absolute inset-0 m-auto flex gap-4 items-center justify-between p-4',
          { 'flex-col justify-center': icon }
        )}
      >
        {icon && icon}

        <Heading
          className='drop-shadow-2xl text-center'
          size='medium'
          disableTruncation
        >
          {title}
        </Heading>

        {image && !colour && <Image src={image.url} type='thumbnail' />}
      </div>
    </div>
  )
})

interface ImageProps {
  className?: string
  src: string
  type: 'thumbnail' | 'background'
}

const IMAGE_CLASSES = {
  thumbnail: 'w-24 h-24 rounded-md',
  background: 'absolute inset-0 m-auto transition-all duration-300 ease-in-out',
}

const IMAGE_LOADED_CLASSES = {
  thumbnail: 'opacity-100',
  background: 'opacity-50',
}

const Image = observer(function Image({
  className,
  src,
  type,
}: ImageProps): React.ReactElement {
  const [isLoaded, setIsLoaded] = useState(false)

  const handleImageLoad = () => {
    setIsLoaded(true)
  }

  return (
    <img
      className={merge(
        'transition-opacity duration-300 ease-in-out opacity-0',
        IMAGE_CLASSES[type],
        { [IMAGE_LOADED_CLASSES[type]]: isLoaded },
        className
      )}
      src={src}
      onLoad={handleImageLoad}
    />
  )
})
