import {
  CommonProps,
  Container,
  merge,
} from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import { ReactElement } from 'react'
import Balancer from 'react-wrap-balancer'

import { ExternalLinkStyled } from './StyledComponents'

export const TermsOfService = observer(function TermsOfService({
  className,
}: CommonProps): ReactElement {
  return (
    <Container className='px-6 text-center mx-auto' size='small'>
      <p
        className={merge(
          'text-muted text-xs sm:text-sm m-auto mt-12 text-center',
          className
        )}
      >
        <Balancer>
          By continuing, I acknowledge and confirm that I have read the{' '}
          <ExternalLinkStyled
            href={`${
              import.meta.env.VITE_PRIMARY_URL || `https://rockstar.bingo`
            }/terms-of-service`}
            target='_blank'
          >
            terms of service
          </ExternalLinkStyled>{' '}
          and I agree to be bound by such terms.
        </Balancer>
      </p>
    </Container>
  )
})
