import {
  BingoResult,
  Message,
  MessageType,
} from '@matthewlongpre/music-bingo-common'
import { observer } from 'mobx-react-lite'
import React from 'react'

import { BingoCardDialog } from './BingoCardDialog'
import { BingoTargetDialog } from './BingoTargetDialog'
import { ScoreboardDialog } from './ScoreboardDialog'
import { getMessageType } from './utils'
import { useGameContext } from '../context/game-context'

export const MessageQueue = observer(
  function MessageQueue(): React.ReactElement | null {
    const { currentMessage, players, gameData } = useGameContext()
    if (!gameData || !currentMessage) return null

    const { isBingoResult, isContactRequest } = getMessageType(currentMessage)

    if (isContactRequest || isBingoResult) {
      return null
    }

    const getModal = (message: Message) => {
      switch (message.type) {
        case MessageType.DENIED_BINGO: {
          return (
            <BingoCardDialog
              gameData={gameData}
              messageData={message.data}
              players={players}
              result={BingoResult.DENIED}
            />
          )
        }

        case MessageType.CONFIRMED_BINGO: {
          return (
            <BingoCardDialog
              gameData={gameData}
              messageData={message.data}
              players={players}
              result={BingoResult.CONFIRMED}
            />
          )
        }

        case MessageType.SCOREBOARD: {
          return <ScoreboardDialog scores={message.data} />
        }

        case MessageType.BINGO_TARGET: {
          return <BingoTargetDialog currentTarget={gameData.currentTarget} />
        }

        default: {
          return null
        }
      }
    }

    return getModal(currentMessage.message)
  }
)
